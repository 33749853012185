import React from 'react';

const css = `
  .clinical_a{fill:#289d78;}.clinical_b{fill:#fff;}
`;

const Clinical = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='54.976'
      height='54.976'
      viewBox='0 0 54.976 54.976'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <circle
        class='clinical_a'
        cx='27.488'
        cy='27.488'
        r='27.488'
        transform='translate(0)'
      />
      <g transform='translate(13.623 14.141)'>
        <path
          class='clinical_b'
          d='M347.621,2167.628h-3.038v-.383a3.266,3.266,0,0,0-3.262-3.263h-4.656a3.266,3.266,0,0,0-3.262,3.263v.383h-3.038a5.481,5.481,0,0,0-5.475,5.475v13.692a.826.826,0,0,0,.827.826h26.554a.826.826,0,0,0,.826-.826V2173.1A5.481,5.481,0,0,0,347.621,2167.628Zm-12.566-.383a1.612,1.612,0,0,1,1.609-1.61h4.656a1.611,1.611,0,0,1,1.609,1.61v.383h-7.875Zm8.785,11.382a.826.826,0,0,1-.826.826h-2.193v2.193a.827.827,0,0,1-.827.826h-2a.826.826,0,0,1-.826-.826v-2.193h-2.193a.826.826,0,0,1-.826-.826v-2.005a.826.826,0,0,1,.826-.826h2.193V2173.6a.826.826,0,0,1,.826-.827h2a.826.826,0,0,1,.827.827v2.193h2.193a.826.826,0,0,1,.826.826Z'
          transform='translate(-324.89 -2163.982)'
        />
      </g>
    </svg>
  );
};

export default Clinical;
