import { React, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';

// api
import { createCheckoutSession, getConfig } from '../../../api/Stripe/client';

// style
import Styles from '../styles/Overview.styles';

const Banner = () => {
  const classes = Styles();
  const [price, setPrice] = useState();
  const [stripe, setStripe] = useState(null);
  const [first, setFirst] = useState();
  const [last, setLast] = useState();
  const [email, setEmail] = useState();
  const [company, setCompany] = useState();

  const formatPrice = async ({ amount, currency, quantity }) => {
    const numberFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
    });
    const parts = numberFormat.formatToParts(amount);
    let zeroDecimalCurrency = true;
    for (let part of parts) {
      if (part.type === 'decimal') {
        zeroDecimalCurrency = false;
      }
    }
    amount = zeroDecimalCurrency ? amount : amount / 100;
    const total = (quantity * amount).toFixed(2);
    return numberFormat.format(total);
  };

  const fetchCheckoutSession = async () => {
    const res = await createCheckoutSession();
    return res.data['Success'];
  };

  useEffect(() => {
    async function fetchConfig() {
      try {
        // Fetch config from our backend.
        const res = await getConfig();
        setPrice(
          await formatPrice({
            amount: res.data['Success'].unitAmount,
            currency: res.data['Success'].currency,
            quantity: 1,
          })
        );
        let str = await loadStripe(res.data['Success'].publicKey);
        setStripe(str);
      } catch (e) {
        // console.log(e);
      }
    }
    fetchConfig();
  }, []);

  const handleClick = async (event) => {
    try {
      event.preventDefault();
      // Call your backend to create the Checkout session.

      localStorage.setItem(
        'user',
        JSON.stringify({
          first_name: first,
          last_name: last,
          email: email,
          company: company,
        })
      );
      const res = fetchCheckoutSession()
      let sessionId = res.sessionId;
      // When the customer clicks on the button, redirect them to Checkout.
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });

      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
      if (error) {
        // console.log('There was an error');
      }
    } catch (error) {
    }
  };

  return (
    <Box className={classes.formRoot}>
      <Container>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          className={classes.form}
        >
          <Box
            display='flex'
            flexDirection='column'
            // alignItems='center'
            justifyContent='space-evenly'
            className={classes.formBox}
          >
            <form onSubmit={handleClick}>
              <Box display='flex' flexDirection='column' alignItems='center'>
                <Typography
                  align='center'
                  variant='h2'
                  className={classes.heading}
                >
                  Registration Information
                </Typography>
                <Box
                  display='flex'
                  flexDirection='row'
                  className={classes.rowBox}
                >
                  <InputBase
                    required
                    // margin='normal'
                    className={classes.InputBase}
                    fullWidth
                    onChange={(e) => setFirst(e.target.value)}
                    placeholder='First Name'
                    variant='outlined'
                    inputProps={{ disableunderline: "true" }}
                  />
                  <InputBase
                    required
                    // margin='normal'
                    onChange={(e) => setLast(e.target.value)}
                    className={classes.InputBase1}
                    fullWidth
                    placeholder='Last Name'
                    variant='outlined'
                    inputProps={{ disableunderline: "true" }}
                  />
                </Box>
                <InputBase
                  required
                  // margin='normal'
                  onChange={(e) => setEmail(e.target.value)}
                  className={classes.InputBase}
                  fullWidth
                  placeholder='Email'
                  variant='outlined'
                  inputProps={{ disableunderline: "true" }}
                />
                <InputBase
                  required
                  // margin='normal'
                  onChange={(e) => setCompany(e.target.value)}
                  className={classes.InputBase}
                  fullWidth
                  placeholder='Company name'
                  variant='outlined'
                  inputProps={{ disableunderline: "true" }}
                />
                <Button
                  type='submit'
                  variant='contained'
                  className={classes.btn1}
                >
                  Proceed with payment {price}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Banner;
