import React from 'react';

const css = `
.tick_a{fill:#06c66c;}
`;

const Tick = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100.066'
      height='13.642'
      viewBox='0 0 158.066 13.642'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <g transform='translate(-145.626 -86.27)'>
        <path
          class='tick_a'
          d='M834.476,328.363s66.069-23.414,156.46-8.893c2.726.819,2.215,8.15-3.334,6.77C982.78,325.04,962.8,312.39,834.476,328.363Z'
          transform='translate(-688.85 -228.451)'
        />
      </g>
    </svg>
  );
};

export default Tick;
