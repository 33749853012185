import { makeStyles } from '@material-ui/core';
import BannerBg from '../../../assets/pngs/Company/BannerBg.png';

export default makeStyles(theme => ({
  root: {
    height: '100vh',
    background: `url(${BannerBg}) no-repeat`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      height: '100%',
      backgroundSize: 'cover',
      // background: theme.palette.services.gradientMain,
    },
  },
  box: {
    height: '100vh',
  },
  heading: {
    marginTop: theme.spacing(-40),
    lineHeight: theme.spacing(.15),
    fontWeight: 'bold',
    color: theme.palette.home.textGrayDark
  },
  heading2: {
    width: '60%',
    lineHeight: theme.spacing(.15),
    // fontSize: theme.spacing(7.5),
    fontWeight: 'bold',
    color: theme.palette.secondary.main
  },
}))