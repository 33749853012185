// @ts-ignore
import MainHeader from './components/MainHeader';
import Experts from './components/Experts';
import Service from './components/Service';
import React from 'react';
import ContactPopup from '../Contacts/ContactPopup';

const Consultancy = () => {
  return (<>
    <MainHeader />
    <Experts />
    <Service />
    <ContactPopup />
  </>);
};
export default Consultancy;