import React, { Fragment, useEffect, useContext } from 'react';

// child components
import Banner from './components/Banner';
import Cards from './components/Cards';
import Form from './components/Form';

// store
import Store from '../../store';
import Actions from '../../store/actions';

const Raps = () => {
  const { dispatch } = useContext(Store);

  useEffect(() => {
    async function getData() {
      try {
        await dispatch({ type: Actions.SET_ACTIVE_PAGE, value: 'Raps' });
      } catch (e) {
        // console.log(e);
      }
    }
    getData();
  }, [dispatch]);

  return (
    <Fragment>
      <Banner />
      <Cards />
      <Form />
    </Fragment>
  );
};

export default Raps;
