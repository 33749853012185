import { makeStyles } from '@material-ui/core';
import BgHome from '../../../assets/svgs/HomePage/BgHome.svg';

export default makeStyles(theme => ({
  roots: {
    height: '100vh',
    background: `url(${BgHome}) !important`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      background: theme.palette.primary.main,
    },
  },
  textBox: {
    marginTop: theme.spacing(20),
    width: '100%',
  },
  superscript: {
    fontSize: theme.spacing(1.5),
  },
  ticker: {
    width: '50%',
    marginLeft: theme.spacing(30),
  },
  headingBox: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
  },
  headingDark: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  },
  headingLight: {
    marginLeft: theme.spacing(1),
    color: theme.palette.white,
    fontWeight: 'bold'
  },
  description: {
    marginTop: theme.spacing(5),
    width: '60%',
    color: theme.palette.navbar.textLight,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  btnGroup: {
    marginTop: theme.spacing(5),
  },
  btn: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textTransform: 'capitalize',
    color: theme.palette.white,
    fontSize: theme.typography.btn.fontSize,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  btn1: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginLeft: theme.spacing(2),
    textTransform: 'capitalize',
    background: theme.palette.primary.light,
    color: theme.palette.white,
    fontSize: theme.typography.btn.fontSize,
  },
  laptop: {
    position: 'relative',
    marginTop: theme.spacing(5),
    width: '60%',
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(20),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(15),
      width: '100%'
    },
  },
  center: {
    marginTop: theme.spacing(-4),
    width: '77%',
    height: '96%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(-2),
      // width: '100%'
    },
  }
}))