import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// assets
import raps_log from '../../../assets/pngs/Raps/raps_logo.png';

// style
import Styles from '../styles/Banner.styles';

const Banner = () => {
  const classes = Styles();

  return (
    <Box className={classes.root}>
      <Container>
        <Box
          display='flex'
          justifyContent='center'
          flexDirection='column'
          alignItems='center'
        >
          <Box display='flex' justifyContent='center' className={classes.raps}>
            <img src={raps_log} alt='logo_raps' />
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            flexDirection='column'
            className={classes.box}
          >
            <Typography align='center' variant='h1' className={classes.text}>
              Giveaways
            </Typography>
            <Typography align='center' variant='body2' className={classes.date}>
              12-15 September
            </Typography>
          </Box>
        </Box>
        <Typography align='center' variant='h2' className={classes.subtext}>
          Chances to win
        </Typography>
      </Container>
    </Box>
  );
};

export default Banner;
