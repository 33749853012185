import { makeStyles } from '@material-ui/core';
import Banner from '../../../assets/pngs/Raps/Banner.png';

export default makeStyles((theme) => ({
  root: {
    height: '60vh',
    background: `url(${Banner})`,
    backgroundSize: 'cover',
  },
  box: {
    width: '60%',
    padding: theme.spacing(5),
    borderRadius: theme.spacing(10),
    // height:'100%',
    marginTop: theme.spacing(2),
    background: theme.palette.white,
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  raps: {
    width: '60%',
    padding: theme.spacing(5),
    borderRadius: theme.spacing(10),
    // height:'100%',
    marginTop: theme.spacing(15),
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(10),
    },
  },
  text: {
    // color:theme.palette.secondary.main
    color: theme.palette.raps.text,
    fontWeight: 'bold',
    fontSize: '3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  date: {
    color: theme.palette.home.textGray,
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  subtext: {
    marginTop: theme.spacing(2),
    fontWeight: '500',
    fontSize: '2.2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
    color: theme.palette.white,
    opacity: 0.8,
  },
}));
