import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  parent: {
    background: theme.palette.resources.backgroundLight
  },
  roots: {
    height: '100vh',
    background: theme.palette.primary.main,
  },
  textBox: {
    width: '100%'
  },
  heading: {
    width: '75%',
    marginTop: theme.spacing(25),
    lineHeight: theme.spacing(.15),
    fontWeight: 'bold',
    color: theme.palette.white,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h2.fontSize
    },
  },
  description: {
    width: '75%',
    marginTop: theme.spacing(2),
    color: theme.palette.white,
    opacity: .8,
    [theme.breakpoints.down('md')]: {
      textAlign: 'justify',
      fontSize: theme.typography.body1.fontSize
    },
  },
  btn: {
    alignSelf: 'flex-start',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginLeft: theme.spacing(9.5),
    fontSize: theme.typography.btn.fontSize,
    marginTop: theme.spacing(4),
    textTransform: 'capitalize',
    background: theme.palette.secondary.main,
    color: theme.palette.white,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      marginLeft: theme.spacing(6)
    },
  },
  img: {
    paddingTop: theme.spacing(20),
    height: '80%',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      height: '68%',
      width: '72%',
    },
  },
  center: {
    width: '50%',
  }
}))