import React from 'react';

const css = `
.loc_a{fill:#959aa3;}.loc_b{fill:#fff;}.loc_c{clip-path:url(#loc_a);}
`;

const Location = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlink='http://www.w3.org/1999/xlink'
      width='32'
      height='32'
      viewBox='0 0 32 32'
    >
      <defs>
        <style>{css}</style>
        <clipPath id='loc_a'>
          <path
            class='loc_a'
            d='M9.334,26.666h0a60.6,60.6,0,0,1-4.666-6.04C2.538,17.47,0,12.949,0,9.334a9.336,9.336,0,0,1,15.935-6.6,9.274,9.274,0,0,1,2.732,6.6c0,3.616-2.538,8.136-4.667,11.291a60.6,60.6,0,0,1-4.666,6.04ZM9.334,6a3.334,3.334,0,1,0,3.334,3.334A3.337,3.337,0,0,0,9.334,6Z'
          />
        </clipPath>
      </defs>
      <g transform='translate(6.667 2.667)'>
        <path
          class='loc_b'
          d='M9.334,26.666h0a60.6,60.6,0,0,1-4.666-6.04C2.538,17.47,0,12.949,0,9.334a9.336,9.336,0,0,1,15.935-6.6,9.274,9.274,0,0,1,2.732,6.6c0,3.616-2.538,8.136-4.667,11.291a60.6,60.6,0,0,1-4.666,6.04ZM9.334,6a3.334,3.334,0,1,0,3.334,3.334A3.337,3.337,0,0,0,9.334,6Z'
        />
      </g>
    </svg>
  );
};

export default Location;
