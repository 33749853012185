import React, { createContext, useReducer } from 'react';
import reducers from './reducers';

const initialState = {
  activePage: 'Home',
  resourceCategory: 'All',
  isContactUsOpen: false,
}

const Store = createContext(initialState);
const { Provider } = Store;

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export default Store;