import { createSvgIcon } from '@mui/material';

const CheckIcon = createSvgIcon(<svg width='100%' height='100%' viewBox="0 0 18 18" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_620_461)">
    <path
      d="M0 9.00012C0 4.02903 4.02891 0.00012207 9 0.00012207C13.9711 0.00012207 18 4.02903 18 9.00012C18 13.9712 13.9711 18.0001 9 18.0001C4.02891 18.0001 0 13.9712 0 9.00012ZM13.0711 7.44622C13.4543 7.06301 13.4543 6.43723 13.0711 6.05403C12.6879 5.67083 12.0621 5.67083 11.6789 6.05403L7.875 9.85793L6.32109 8.30403C5.93789 7.92083 5.31211 7.92083 4.92891 8.30403C4.5457 8.68723 4.5457 9.31301 4.92891 9.69622L7.17891 11.9462C7.56211 12.3294 8.18789 12.3294 8.57109 11.9462L13.0711 7.44622Z"
      fill="#0076D6" />
  </g>
  <defs>
    <clipPath id="clip0_620_461">
      <rect width="18" height="18" fill="white" />
    </clipPath>
  </defs>
</svg>, 'CheckIcon');
export default CheckIcon;