import React, { useState } from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// assets
import Bm1 from '../../../assets/pngs/Company/Bm1.png';
import Bm2 from '../../../assets/pngs/Company/Bm2.png';
import Ac1 from '../../../assets/pngs/Company/Ac1.png';
import Ac2 from '../../../assets/pngs/Company/Ac2.png';
import Ac3 from '../../../assets/pngs/Company/Ac3.png';
import Ac4 from '../../../assets/pngs/Company/Ac4.png';
import Ac5 from '../../../assets/pngs/Company/Ac5.png';
import Tm1 from '../../../assets/pngs/Company/Tm1.png';
import Tm3 from '../../../assets/pngs/Company/Tm3.png';
import Tm4 from '../../../assets/pngs/Company/Tm4.png';
import Tm5 from '../../../assets/pngs/Company/Tm5.png';
import Holly from '../../../assets/pngs/Company/Holly.png';

import Arrow from '../../../assets/svgs/Company/Arrow';

// style
import Styles from '../styles/BoardMembers.styles';

const BoardMembers = () => {
  const classes = Styles();
  const [bm1, setBm1] = useState(false);
  const [bm2, setBm2] = useState(false);
  const [ac1, setAc1] = useState(false);
  const [ac2, setAc2] = useState(false);
  const [ac3, setAc3] = useState(false);
  const [ac4, setAc4] = useState(false);
  const [ac5, setAc5] = useState(false);

  return (
    <Box className={classes.root}>
      <Container>
        <Typography align='center' variant='h1' className={classes.heading}>
          Vivpro Team
        </Typography>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} lg={6}>
            <Box
              mt={2}
              display='flex'
              flexDirection='row'
              justifyContent='center'
            >
              <img src={Ac5} alt='member' />
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-end'
                alignItems='flex-start'
              >
                <Typography className={classes.names}>
                  Dr. Pravin Jadhav
                </Typography>
                <Typography className={classes.position}>CEO</Typography>
              </Box>
            </Box>
            <Box
              display='flex'
              justifyContent='center'
              onMouseLeave={() => setAc5(false)}
              onMouseOver={() => setAc5(true)}
            >
              <Arrow />
            </Box>
            {ac5 ? (
              <>
                <Box p={3} display='flex' justifyContent='center'>
                  <Typography
                    align='center'
                    className={classes.bio}
                    variant='body2'
                  >
                    An internationally recognized drug development and digital
                    transformation leader. He is a creative quantitative
                    scientist and leader with a focus to improve and protect
                    population health. He has held several leadership positions
                    in FDA, Merck, and Otsuka spanning over 18 years. He has
                    pioneered several innovative drug development pathways and
                    digital transformation of drug development. Dr. Jadhav
                    received his BPharm and MPharm from India, Ph.D. in
                    Pharmaceutical Sciences from the Medical College of Virginia
                    Commonwealth University (VCU), and an MPH from Johns Hopkins
                    University, Bloomberg School of Public Health.
                  </Typography>
                </Box>
              </>
            ) : null}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              mt={2}
              display='flex'
              flexDirection='row'
              justifyContent='center'
            >
              <img src={Bm1} alt='member' />
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-end'
                alignItems='flex-start'
              >
                <Typography className={classes.names}>Joga Gobburu</Typography>
                <Typography className={classes.position}>President</Typography>
              </Box>
            </Box>
            <Box
              display='flex'
              justifyContent='center'
              onMouseLeave={() => setBm1(false)}
              onMouseOver={() => setBm1(true)}
            >
              <Arrow />
            </Box>
            {bm1 ? (
              <>
                <Box p={3} display='flex' justifyContent='center'>
                  <Typography
                    align='center'
                    className={classes.bio}
                    variant='body2'
                  >
                    A renowned scientist in the area of quantitative disease
                    models and their application to decisions, Joga heads our
                    interdisciplinary team and is instrumental in shaping the
                    company’s vision and roadmap. He is a Professor with the
                    School of Pharmacy and the School of Medicine, University of
                    Maryland, Baltimore and has held various positions at the US
                    FDA between 1999 and 2011.
                  </Typography>
                </Box>
              </>
            ) : null}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              mt={2}
              display='flex'
              flexDirection='row'
              justifyContent='center'
            >
              <img src={Bm2} alt='member' />
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-end'
                alignItems='flex-start'
              >
                <Typography className={classes.names}>
                  Venugopal Balaji
                </Typography>
                <Typography className={classes.position}>
                  Lead Software Engineer
                </Typography>
              </Box>
            </Box>
            <Box
              display='flex'
              justifyContent='center'
              onMouseLeave={() => setBm2(false)}
              onMouseOver={() => setBm2(true)}
            >
              <Arrow />
            </Box>
            {bm2 ? (
              <>
                <Box p={3} display='flex' justifyContent='center'>
                  <Typography
                    align='center'
                    className={classes.bio}
                    variant='body2'
                  >
                    Venu is a software engineer who has experience working at
                    early-stage startups and is one of the founding engineers at
                    Vivpro. He leads the development team and is influential in
                    establishing a technical vision for the company. He has
                    recently graduated from Stevens Institute of Technology with
                    a Master’s in Computer Science.
                  </Typography>
                </Box>
              </>
            ) : null}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              mt={2}
              display='flex'
              flexDirection='row'
              justifyContent='center'
            >
              <img src={Tm3} alt='member' />
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-end'
                alignItems='flex-start'
              >
                <Typography className={classes.names}>Tushar Nitave</Typography>
                <Typography className={classes.position}>
                  Lead Software Engineer
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              mt={2}
              display='flex'
              flexDirection='row'
              justifyContent='center'
            >
              <img src={Tm4} alt='member' />
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-end'
                alignItems='flex-start'
              >
                <Typography className={classes.names}>Ashwath A N</Typography>
                <Typography className={classes.position}>
                  Staff Software Engineer
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              mt={2}
              display='flex'
              flexDirection='row'
              justifyContent='center'
            >
              <img src={Tm1} alt='member' />
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-end'
                alignItems='flex-start'
              >
                <Typography className={classes.names}>
                  Peter Anderson
                </Typography>
                <Typography className={classes.position}>
                  Product Manager
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              mt={2}
              display='flex'
              flexDirection='row'
              justifyContent='center'
            >
              <img src={Holly} alt='member' />
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-end'
                alignItems='flex-start'
              >
                <Typography className={classes.names}>Holly Machain</Typography>
                <Typography className={classes.position}>
                  Operations Lead
                </Typography>
              </Box>
            </Box>
          </Grid>


          <Grid item xs={12} lg={6}>
            <Box
              mt={2}
              display='flex'
              flexDirection='row'
              justifyContent='center'
            >
              <img src={Tm5} alt='member' />
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-end'
                alignItems='flex-start'
              >
                <Typography className={classes.names}>
                  Shaliesh Dahake
                </Typography>
                <Typography className={classes.position}>
                  HR Business Partner
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Typography align='center' variant='h1' className={classes.heading}>
          Advisory Board
        </Typography>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} lg={6}>
            <Box
              mt={2}
              display='flex'
              flexDirection='row'
              justifyContent='center'
            >
              <img src={Ac1} alt='member' />
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-end'
                alignItems='flex-start'
              >
                <Typography className={classes.names}>Bev Incledon</Typography>
                <Typography className={classes.position}>Advisor</Typography>
              </Box>
            </Box>
            <Box
              display='flex'
              justifyContent='center'
              onMouseLeave={() => setAc1(false)}
              onMouseOver={() => setAc1(true)}
            >
              <Arrow />
            </Box>
            {ac1 ? (
              <>
                <Box p={3} display='flex' justifyContent='center'>
                  <Typography
                    align='center'
                    className={classes.bio}
                    variant='body2'
                  >
                    Dr. Incledon serves as the Chief Scientific Officer for
                    Ironshore Pharmaceuticals and Development INC. Dr. Incledon
                    has over 28 years of pharmaceutical industry experience
                    encompassing large and small molecule drug discovery,
                    pre-clinical, clinical, product and manufacturing process
                    development roles as well as new R&D facility start-up, and
                    research on novel drug delivery technology. Dr. Incledon
                    gained these experiences through roles with increasing
                    responsibility at Syntex Inc., Glaxo Wellcome Inc., Eli
                    Lilly and Company and several start up biotech companies
                    before joining Ironshore. Over his career he has been a
                    contributor to 21 clinical development stage and 8 marketed
                    products.
                  </Typography>
                </Box>
              </>
            ) : null}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              mt={2}
              display='flex'
              flexDirection='row'
              justifyContent='center'
            >
              <img src={Ac2} alt='member' />
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-end'
                alignItems='flex-start'
              >
                <Typography className={classes.names}>
                  Dr. Katie Laessig
                </Typography>
                <Typography className={classes.position}>Advisor</Typography>
              </Box>
            </Box>
            <Box
              display='flex'
              justifyContent='center'
              onMouseLeave={() => setAc2(false)}
              onMouseOver={() => setAc2(true)}
            >
              <Arrow />
            </Box>
            {ac2 ? (
              <>
                <Box p={3} display='flex' justifyContent='center'>
                  <Typography
                    align='center'
                    className={classes.bio}
                    variant='body2'
                  >
                    Dr. Laessig has more than 20 years of experience in
                    pharmaceutical regulatory roles both in industry and for the
                    U.S. FDA. Before joining Antios, she served as Vice
                    President, Therapeutic Strategy, Strategic Drug Development
                    at IQVIA where she delivered strategic regulatory and
                    clinical development planning for pharmaceuticals and
                    biologics, including support for regulatory interactions,
                    across a range of therapeutic areas for the IQVIA’s
                    biotechnology and pharmaceutical customers. Before that, she
                    was SVP, Regulatory Affairs, Medical Safety and Quality
                    Assurance at RRD International, LLC, where she provided
                    strategic and tactical regulatory, clinical, and development
                    evaluation and direction to product development programs
                    undertaken by RRD for its client companies. From 1999 to
                    2015, Dr. Laessig served in multiple roles of increasing
                    responsibility at the U.S. FDA including Medical Review
                    Officer and MedicalTeam Leader, Division of Antiviral
                    Products, and Deputy Director, Division of Anti-Infective
                    Products. From 2000-2016, she volunteered once weekly at the
                    Whitman Walker Clinic in Washington, DC, the city’s largest
                    provider of HIV/AIDS care.
                  </Typography>
                </Box>
              </>
            ) : null}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              mt={2}
              display='flex'
              flexDirection='row'
              justifyContent='center'
            >
              <img src={Ac3} alt='member' />
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-end'
                alignItems='flex-start'
              >
                <Typography className={classes.names}>
                  Dr. Ajaz Hussain
                </Typography>
                <Typography className={classes.position}>Advisor</Typography>
              </Box>
            </Box>
            <Box
              display='flex'
              justifyContent='center'
              onMouseLeave={() => setAc3(false)}
              onMouseOver={() => setAc3(true)}
            >
              <Arrow />
            </Box>
            {ac3 ? (
              <>
                <Box p={3} display='flex' justifyContent='center'>
                  <Typography
                    align='center'
                    className={classes.bio}
                    variant='body2'
                  >
                    Dr. Ajaz Hussain leverages his remarkably unique experience
                    to advise several life-science corporations. His careers
                    span across academia, US FDA, and industry; serving as the
                    Presidentof the National Institute for Pharmaceutical
                    Technology and Education, the Deputy Director, Office of
                    Pharmaceutical Science, CDER, FDA, Global Head
                    Biopharmaceutical Development at Sandoz, Chief Scientific
                    Officer and VP at Philip Morris International, and President
                    Biotechnology at Wockhardt. He trained as a pharmacist in
                    Mumbai and received his doctoral degree from the University
                    of Cincinnati. He is a Fellow of AAPS and the Swiss Society
                    of Pharmaceutical Science.
                  </Typography>
                </Box>
              </>
            ) : null}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              mt={2}
              display='flex'
              flexDirection='row'
              justifyContent='center'
            >
              <img src={Ac4} alt='member' />
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-end'
                alignItems='flex-start'
              >
                <Typography className={classes.names}>
                  Mr. Amit Kohli
                </Typography>
                <Typography className={classes.position}>Advisor</Typography>
              </Box>
            </Box>
            <Box
              display='flex'
              justifyContent='center'
              onMouseLeave={() => setAc4(false)}
              onMouseOver={() => setAc4(true)}
            >
              <Arrow />
            </Box>
            {ac4 ? (
              <>
                <Box p={3} display='flex' justifyContent='center'>
                  <Typography
                    align='center'
                    className={classes.bio}
                    variant='body2'
                  >
                    Amit Kohli is Chief Executive Officer of Antev Ltd. Mr.
                    Kohli is responsible for leading Antev's corporate strategy
                    and operational and clinical development and will oversee
                    the Company's advance along the clinical pathway towards
                    commercialization for its lead indication, teverelix, anovel
                    gonadotrophin releasing hormone ("GnRH") antagonist for the
                    treatment of hormone sensitive advanced prostate cancer.
                  </Typography>
                </Box>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BoardMembers;
