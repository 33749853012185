import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(15),
  },
  title: {
    fontWeight: '600',
    color: theme.palette.home.textGrayDark
  },
  btn: {
    margin: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: theme.palette.white,
    fontSize: theme.typography.btn.fontSize
  },
  subtext: {
    color: theme.palette.home.textGray
  },
  subtitle: {
    fontWeight: '600',
    marginTop: theme.spacing(1)
  },
  description: {
    color: theme.palette.home.textGray
  }
}))