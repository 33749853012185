import http from '../index';

export function getConfig() {
    return http.get(
        `/clignosis/config`
    );
}

export function createCheckoutSession() {
    return http.get(
        `/clignosis/create-checkout-session`
    );
}

export function getPaymentConfirmation(sessionId) {
    return http.get(
        `/clignosis/order/success?session_id=${sessionId}`
    );
}

export function addInvoiceToDb(payload) {
    return http.post(
        `/clignosis/order?confirm=${true}`, payload
    );
}