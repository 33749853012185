import React from 'react';

const css = `
.cont_a{fill:none;stroke:#fff;stroke-linecap:square;stroke-miterlimit:10;stroke-width:2px;}
`;

const Contact = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='43.768'
      height='46.752'
      viewBox='0 0 43.768 46.752'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <g transform='translate(1 1)'>
        <rect
          class='cont_a'
          width='16.409'
          height='8.95'
          transform='translate(14.171 8.95)'
        />
        <path
          class='cont_a'
          d='M0,.5H16.409'
          transform='translate(14.171 24.113)'
        />
        <path class='cont_a' d='M.5,0V44.752' transform='translate(6.959)' />
        <path
          class='cont_a'
          d='M0,0H34.174a3.053,3.053,0,0,1,3.119,2.983V41.768a3.053,3.053,0,0,1-3.119,2.983H0Z'
        />
        <path
          class='cont_a'
          d='M.5,0V5.967'
          transform='translate(41.268 5.967)'
        />
        <path
          class='cont_a'
          d='M.5,0V5.967'
          transform='translate(41.268 17.901)'
        />
      </g>
    </svg>
  );
};

export default Contact;
