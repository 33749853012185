import React, { useState } from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

// api
import { register_participant } from '../../../api/Giveaways/client';

// style
import Styles from '../styles/Form.styles';

const Forms = () => {
  const classes = Styles();
  const [prefix, setPrefix] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [mailingAddress, setMailingAddress] = useState();
  const [title, setTitle] = useState();
  const [organization, setOrganiztion] = useState();
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState();
  const [message, setMessage] = useState();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let payload = {
        prefix,
        firstName,
        lastName,
        email,
        mailingAddress,
        title,
        organization,
      };
      const res = await register_participant(payload);
      if (res) {
        setOpen(true);
        setAlert('success');
        setMessage(res.data.Success);
        setPrefix('');
        setTitle('');
        setFirstName('');
        setLastName('');
        setOrganiztion('');
        setEmail('');
        setMailingAddress('');
      }
    } catch (e) {
      setOpen(true);
      setAlert('error');
      setMessage('Something went wrong!');
    }
  };
  return (
    <Box className={classes.root}>
      <Container>
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={alert && alert}>
            {message && message}
          </Alert>
        </Snackbar>
        <Paper elevation={3} className={classes.box}>
          <Typography
            align='center'
            variant='subtitle1'
            className={classes.title}
          >
            Enter your details
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box display='flex' className={classes.names}>
              <TextField
                required
                value={prefix}
                type='text'
                margin='normal'
                onChange={(e) => setPrefix(e.target.value)}
                className={classes.nameField}
                id='outlined-basic'
                label='Prefix'
                variant='outlined'
              />
              <TextField
                required
                type='text'
                value={firstName}
                margin='normal'
                fullWidth
                onChange={(e) => setFirstName(e.target.value)}
                className={classes.nameField}
                id='outlined-basic'
                label='First Name'
                variant='outlined'
              />
              <TextField
                fullWidth
                value={lastName}
                required
                margin='normal'
                onChange={(e) => setLastName(e.target.value)}
                className={classes.nameField}
                id='outlined-basic'
                label='Last Name'
                variant='outlined'
              />
            </Box>
            <Box display='flex' className={classes.names}>
              <TextField
                value={title}
                required
                margin='normal'
                fullWidth
                onChange={(e) => setTitle(e.target.value)}
                className={classes.nameField}
                id='outlined-basic'
                label='Title'
                variant='outlined'
              />
              <TextField
                required
                fullWidth
                value={organization}
                margin='normal'
                onChange={(e) => setOrganiztion(e.target.value)}
                className={classes.nameField}
                id='outlined-basic'
                label='Organization'
                variant='outlined'
              />
            </Box>
            <Box display='flex' className={classes.names}>
              <TextField
                required
                fullWidth
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin='normal'
                className={classes.nameField}
                id='outlined-basic'
                label='Email'
                variant='outlined'
              />
              <TextField
                required
                fullWidth
                value={mailingAddress}
                multiline
                onChange={(e) => setMailingAddress(e.target.value)}
                margin='normal'
                className={classes.nameField}
                id='outlined-basic'
                label='Mailing Address'
                variant='outlined'
              />
            </Box>
            <Box display='flex' justifyContent='center'>
              <Button type='submit' variant='contained' color='primary'>
                Submit
              </Button>
            </Box>
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default Forms;
