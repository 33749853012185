import themev5 from '../../themev5';

const consultancyStyles = {
  container: {
    height: '800px',
    backgroundImage: `url(https://d1fziiyze1kdfo.cloudfront.net/website/Background.jpg) !important`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '& >div': {
      pt: '40px', px: '0 !important'
    }
  }, bannerSection: {
    borderRadius: '16px',
    background: 'linear-gradient(90.16deg, rgba(21, 71, 140, 0.9) 0.12%, rgba(55, 102, 165, 0.9) 99.85%)',
    p: '48px',
    width: '30%',
    [themev5.breakpoints.up('laptop')]: {
      minWidth: '620px', ml: '10%'
    },
    [themev5.breakpoints.down('laptop')]: {
      mx: 'auto', width: '70%'
    }
  }, title: {
    fontFamily: 'Plus Jakarta Sans',
    fontSize:'48px',
    [themev5.breakpoints.down('laptop')]:{
      fontSize:'32px'
    },
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    letterSpacing: '-1.08px'
  }, blueTitle: {
    color: '#28BFFF'
  }, whiteTitle: {
    color: '#fff'
  }, secondaryTitle: {
    mt: '16px',
    color: '#FFF',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '160%',
    alignSelf: 'stretch'
  }, buttonBanner: {
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '24px',
    border: '1px solid #FFF',
    background: '#FFF',
    textTransform: 'none',
    color: '#1B4C89',
    textAlign: 'center',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    '&:hover': {
      color: '#FFF', background: '#1B4C89'
    }
  }, expertTitle: {
    alignSelf: 'stretch',
    color: '#212121',
    textAlign: 'center',
    fontFamily: 'Plus Jakarta Sans',
    [themev5.breakpoints.down('laptop')]:{
      fontSize:'32px'
    },
    fontSize:  '40px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '46px'
  }, expertSubTitle: {
    color: '#212121',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
    mt: '20px'
  }, expertPosition: {
    fontFamily: 'Plus Jakarta Sans',
    color: '#0088E9',
    textAlign: 'center',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    mt: '4px'
  }, expertName: {
    color: '#213430',
    textAlign: 'center',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '28px',
    mt: '17px'
  }, expertDescription: {
    color: '#fff',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
    textAlign: 'center',
    py:4
  }, imageContainer: {
    borderRadius: '50%', overflow: 'hidden', width: '300px', height: '300px', '& > img': {
      width: '300px', height: '300px'
    }
  }, serviceContainer: {
    backgroundImage: `url(https://d1fziiyze1kdfo.cloudfront.net/website/Background2.jpg)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
  }, title2: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Plus Jakarta Sans',
    [themev5.breakpoints.down('laptop')]:{
      fontSize:'32px'
    },
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '46px',
    mb: '24px',
    alignSelf: 'stretch'
  }, serviceDescription: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '150%',
    alignSelf: 'stretch'
  }, serviceTitle: {
    color: '#213430',
    fontFamily: 'Plus Jakarta Sans',
    [themev5.breakpoints.down('laptop')]:{
      fontSize:'32px'
    },
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '125%',
    alignSelf: 'stretch',
    width: '80%'

  }, serviceListItem: {
    color: '#424242',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
    maxWidth: '80%',
    p: 0,
    mb: '4px',
    alignItems: 'start',
    '& .MuiListItemIcon-root': {
      minWidth: '0', mr: '16px', mt: '7px', height: '18px', width: '18px', '& svg': {
        height: '18px', width: '18px'
      }
    }
  }, serviceImage: {
    height: '412px',
    [themev5.breakpoints.down('laptop')]: {
      height: '310px'
    }
    , mr: 1, '& img': {
      objectFit: 'cover', height: '100%',
      [themev5.breakpoints.down('laptop')]: {
        height: 'auto'
      }
    }
  }, serviceDivider: {
    my: '100px', backgroundColor: '#fff !important'
  }, title3: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Plus Jakarta Sans',
    [themev5.breakpoints.down('laptop')]:{
      fontSize:'32px'
    },
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    letterSpacing: '-0.88px'
  }, serviceDescription2: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%'
  }, whyUsTitle: {
    color: '#213430',
    textAlign: 'center',
    fontFamily: 'Plus Jakarta Sans',
    [themev5.breakpoints.down('laptop')]:{
      fontSize:'32px'
    },
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '46px'
  }, whyUsItemTitle: {
    color: '#213430', textAlign: 'center', fontSize: '22px', fontStyle: 'normal', fontWeight: '600', lineHeight: '28px'
  }, whyUsItemDescription: {
    mt: '4px',
    color: '#757575',
    textAlign: 'center',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '22px'
  }, whyUsIcon: {
    background: '#0076D6', mb: '24px', width: '90px', height: '90px', '& svg': {
      width: '40px', height: '40px'
    }
  }, blueTitle2: {
    color: '#2FDAFF'
  }, servicesContainer: {
    [themev5.breakpoints.up('laptop')]: {
      flexDirection: 'row'
    }, [themev5.breakpoints.down('laptop')]: {
      flexDirection: 'column-reverse', '& div#image-div': {
        mb: '10px',
        width: '100%',
        '& img': {
          width: '100%',
        }
      }
    }
  },
  expertContainer:{
    [themev5.breakpoints.up('tablet')]:{
      px:'160px'
    }
  },
  expertArrows:{
    p:1,
    '& > button':{
      background:'rgba(75,113,168,0.3)',

    }
  },

};
export default consultancyStyles;