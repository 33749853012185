/**
 * this object will contain all the hex values of colors being used in the application.
 * for each color we can have standardized variables to differentiate the shades of each color
 * variables - main, dark, light, darkVariant, lightVariant, background, border, contrastText
 */
const palette: { [key: string]: object } = {
  primary: {
    main: '#289D78',
    dark: '#12815E',
    medium: '#2ecc71',
    darkVariant: '#16A085',
    darkBluish: '#028b8b',
    lightBluish: '#3AB09E',
    light: '#48E0A6',
    lightVariant: '#30B5A8',
    background: '#F2F7F7',
    backgroundDark: '#DCEDE7',
    darkCyan: '#008b8b',
    backgroundPrimary: '#46B163',
    backgroundLight: '#D0EFE5',
    avatar: '#289D78',
    color: '#FFFFFF'
  },
  secondary: {
    main: '#5D5FEF'
  },
  black: {
    main: '#333333',
    dark: '#090909',
    darkVariant: '#1D262D',
    light: '#888888',
    lightVariant: '#828282',
    contrastText: '#99a3ad',
    shadow: '#00000008'
  },
  gray: {
    main: '#707070',
    dark: '#505050',
    darkVariant: '#5F5F5F',
    darkStorm: '#6D7D8A',
    light: '#B2B2B2',
    lightVariant: '#6E6E6E',
    lightVariant2: '#7E7E7E',
    dove: '#6A6A6A',
    background: '#EEEEEE',
    backgroundDark: '#F0F2FD',
    contrastText: '#8B8B8B',
    backgroundLight: '#C9D2F6',
    cardsBackground: '#F5F5F5',
    border: '#CDC9C9',
    lightText: '#CCCCCC',
    lightBorder: '#00000029',
    darkBluish: '#464255',
    darkText: '#646464'
  },
  blue: {
    main: '#6868f9',
    light: '#6C9BFD',
    royal: '#4A4EC6',
    berry: '#6868FA',
    alice: '#e3f1ff',
    lightVariant: '#defaff'
  },
  white: {
    main: '#FFFFFF',
    smoke: '#F7F7F7',
    dark: '#BDBDBD',
    background: '#FAFAFA',
    light: '#f1fbf7',
    aliceBlue: '#F1F5F8',
    contrastText: '#E6E6E6'
  },
  red: {
    main: '#e74c3c',
    light: '#ea5252'
  },
  orange: {
    main: '#f39c12',
    rose: '#FF4C4C'
  },
  green: {
    accept: '#3AB09E',
    jungle: '#289D78',
    emerald: '#119E8A'
  },
  gradient: {
    blueToGreen: 'linear-gradient(90deg, #29ABE2 0%, #39B54A 100%)'
  },
  gold: {
    main: '#ffd700',
    head: '#F2C94C'
  }
};

export default palette;
