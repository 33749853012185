import React from 'react';

const css = `
.link_a{fill:url(#link_a);}
`;

const Linkedin = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlink='http://www.w3.org/1999/xlink'
      width='30.673'
      height='30.672'
      viewBox='0 0 30.673 30.672'
    >
      <defs>
        <style>{css}</style>
        <linearGradient
          id='link_a'
          x1='0.146'
          y1='0.146'
          x2='0.854'
          y2='0.854'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#29abe2' />
          <stop offset='1' stopColor='#39b54a' />
        </linearGradient>
      </defs>
      <path
        className='link_a'
        d='M183.506,5211.128a15.336,15.336,0,1,0,15.337,15.336A15.337,15.337,0,0,0,183.506,5211.128Zm-4.456,23.184h-3.735v-11.237h3.735Zm-1.868-12.771h-.024a2.134,2.134,0,1,1,.024,0Zm15.335,12.771h-3.734V5228.3c0-1.511-.541-2.541-1.892-2.541a2.043,2.043,0,0,0-1.916,1.365,2.558,2.558,0,0,0-.123.912v6.275h-3.735s.049-10.183,0-11.237h3.735v1.591a3.709,3.709,0,0,1,3.366-1.854c2.458,0,4.3,1.606,4.3,5.057Z'
        transform='translate(-168.17 -5211.128)'
      />
    </svg>
  );
};

export default Linkedin;
