import { makeStyles } from '@material-ui/core';
import ResourceBG from '../../../assets/svgs/Resources/ResourceBG.svg';

export default makeStyles(theme => ({
  root: {
    height: '100vh',
    background: `url(${ResourceBG}) no-repeat`,
    backgroundSize: 'contain',
    [theme.breakpoints.down('md')]: {
      background: 'none',
    },
  },
  box: {
    // height: '100vh'
  },
  heading: {
    width: '40%',
    marginTop: theme.spacing(30),
    marginLeft: theme.spacing(15),
    fontWeight: 'bold',
    color: theme.palette.home.textGrayDark,
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(45),
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: theme.spacing(0)
    },
  },
  tabs: {
    width: '80%',
    // height:'100%',
    marginTop: theme.spacing(15),
    padding: theme.spacing(3),
    background: theme.palette.white,
    boxShadow: `0px 4px 40px ${theme.palette.resources.shadowLight}`,
    borderRadius: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  btn: {
    background: `rgb(73,224,166,0.1)`,
  },
  unselectedbtn: {
    background: 'none'
  },
  unselectedtext: {
    fontSize: theme.spacing(2),
    textTransform: 'capitalize',
    color: theme.palette.home.textBlackDark
  },
  btnText: {
    fontSize: theme.spacing(2),
    color: theme.palette.secondary.main,
    textTransform: 'capitalize'
  }
}))