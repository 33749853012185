export const testimonialPage = [
  {
    title: `I must say that it has been great for me to work with Vivpro, and that I really appreciate that you are bringing some fresh innovation in the Pharma industry.`,
    name: 'Filippo Frangioni',
    qualification: 'MSc',
    designation:
      'Senior Digital Innovation Partner, Digital Product & Project Office',
    company: 'Novo Nordisk',
    imgPath: 'https://vivpro-site.s3.amazonaws.com/Testimonials/Filippo_Frangioni.jpg',
  },
  {
    title: `Have recently been using Vivpro app to compare FDA vs. EMA Guidances on various issues related to regulatory path for drugs and biologics. The natural language processing (NLP) AI enables efficient retrieval and comparisons, without the worry of inadvertently omitting documents as can happen if same search-and-compare tasks are done the traditional, manual way. Additionally, the Vivpro document ingestion and curation process is quick and timely (for example, the newly-issued FDA Guidance on Statistical Approaches to Establishing Bioequivalence was in Vivpro document corpus just 24 hours after FDA posted it). Wish I'd had Vivpro tool when I was VP Reg Affrs years ago.`,
    name: 'Doug McNair',
    qualification: '',
    designation: 'Deputy Director',
    company: ' Bill & Melinda Gates Foundation',
    imgPath:
      'https://vivpro-site.s3.amazonaws.com/Testimonials/Doug_McNair.png',
  },
  {
    title: `I love how RIA helps me find information quickly. I'm working on an interaction model found in an FDA summary basis of approval. I can find the reference information I need in seconds by opening @Vivpro from my browser shortcut, and asking RIA, "what is the model of drug x and drug y". The first result is the model table I'm looking for with parameters and model structure. In just a few seconds I found access to the information I needed. Faster than remembering where I stored the document locally, accessing it, and looking through the multi-hundred page pdf for the table I need. Ria is a lot smarter than Siri.`,
    name: 'Joel Owen',
    qualification: 'PhD, BS, Pharmacy, Pharmaceutics, Pharmacokinetics',
    designation: 'Distinguished Scientist',
    company: 'Pumas-AI',
    imgPath: 'https://vivpro-site.s3.amazonaws.com/Testimonials/Joel_Owen_vivpro.png',
  },
  {
    title: `We have been utilizing in house over the past year a novel R&D Intelligence Assistant (RIA) from Vivpro (Vivpro Corp). Honestly, I cant imagine going back to the standard search and purge process. If your job/business is interested in reducing FTE hours spent on scanning through a stack of abstracts or searching through endless SBAs on regulatory authorities websites looking for the one specific item to support a study design, sample size, regulatory precedent, or to help define a more specific development program for a potential new client with just a few clicks, look no further than Vivpro Corp. The developers at Vivpro Corp have found a way to amp up the horsepower on this search engine. I get weekly updates on new items added to their software monthly and what's really great is the more I use it, the more intuitive the intelligence becomes.`,
    name: 'Jen DiGiacinto',
    qualification: 'Pharm.D, Pharmacy',
    designation:
      'Senior Vice President Clinical Pharmacology & Pharmacometrics',
    company: 'RRD International, LLC',
    imgPath: 'https://vivpro-site.s3.amazonaws.com/Testimonials/Jen.png',
  }
];
