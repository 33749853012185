import React from 'react';

const css = `
  .strategy_a{fill:#289d78;}.strategy_b{fill:#fff;}
`;

const Strategy = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='54.976'
      height='54.976'
      viewBox='0 0 54.976 54.976'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <circle
        class='strategy_a'
        cx='27.488'
        cy='27.488'
        r='27.488'
        transform='translate(0)'
      />
      <g transform='translate(17.833 14.031)'>
        <path
          class='strategy_b'
          d='M1170.94,2195.823a.956.956,0,0,0-.9-.635h-14.483a.956.956,0,0,0-.941.785l-.432,2.387a.616.616,0,0,0,.022.326.608.608,0,0,0,.571.4h16.452a.625.625,0,0,0,.349-.1.607.607,0,0,0,.235-.709Z'
          transform='translate(-1154.172 -2172.175)'
        />
        <path
          class='strategy_b'
          d='M1173.644,2172.58l-4.667-7,.9-2.429a.606.606,0,0,0-.733-.794l-4.4,1.245a9.255,9.255,0,0,0-3.837.846c-1.894.867-4.348,2.808-5.292,7.13a22.063,22.063,0,0,0,.752,12.557h12.463l1.113-1.112a.606.606,0,0,0,.081-.757,55.522,55.522,0,0,1-4.383-8.109,3.059,3.059,0,0,0,1.143.172,2.666,2.666,0,0,0,1.591-.637l1.708,1.139a2.213,2.213,0,0,0,3.207-.852l.4-.791A.605.605,0,0,0,1173.644,2172.58Zm-6.552-3.9h-.825a.606.606,0,0,1,0-1.212h.825a.606.606,0,0,1,0,1.212Z'
          transform='translate(-1154.436 -2162.333)'
        />
      </g>
    </svg>
  );
};

export default Strategy;
