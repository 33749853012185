import React from 'react';

const css = `
  .faster_a,.faster_c{fill:#04d7ab;}.faster_b,.faster_d{fill:#efefef;}.faster_c,.faster_d{stroke:#000;stroke-miterlimit:10;stroke-width:3px;}
`;

const FasterDecision = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='8vh'
      height='8vh'
      viewBox='0 0 88.966 79.144'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <g transform='translate(-450 -1625.999)'>
        <path
          class='faster_a'
          d='M482.822,1688.861v-30.673a2.273,2.273,0,0,1,2.272-2.272h-5.112a2.273,2.273,0,0,0-2.272,2.272v30.673a2.273,2.273,0,0,0,2.272,2.272h5.112A2.273,2.273,0,0,1,482.822,1688.861Z'
        />
        <path
          class='faster_b'
          d='M481.407,1697c15.716,0,26.508,8.141,34.081,8.141s23.478-4.353,23.478-39.191S517.569,1626,509.24,1626c-39.005,0-55.666,71-27.833,71Z'
        />
        <path
          class='faster_a'
          d='M475.025,1642.318h25.034a1.191,1.191,0,1,0,0-2.382H475.025a1.191,1.191,0,1,0,0,2.382Z'
        />
        <path
          class='faster_a'
          d='M452.637,1653.963H477.67a1.191,1.191,0,1,0,0-2.382H452.637a1.191,1.191,0,1,0,0,2.382Z'
        />
        <path
          class='faster_a'
          d='M459.357,1666.217a1.191,1.191,0,0,0,1.191,1.191h25.033a1.191,1.191,0,1,0,0-2.382H460.548A1.191,1.191,0,0,0,459.357,1666.217Z'
        />
        <path
          class='faster_a'
          d='M473.684,1680.06H451.191a1.191,1.191,0,1,0,0,2.382h22.493a1.191,1.191,0,1,0,0-2.382Z'
        />
        <path
          class='faster_c'
          d='M518.688,1649.24a6.505,6.505,0,1,0-6.511-6.5A6.507,6.507,0,0,0,518.688,1649.24Z'
        />
        <path
          class='faster_d'
          d='M529.388,1650.168c-3.732,5.235-8.734,5.845-14.119,2.653-.366-.217-1.992-1.111-2.352-1.325-8.663-5.134-17.31-3.112-23.1,5.014-2.462,3.457,3.241,6.727,5.675,3.311,2.994-4.2,6.8-5.416,10.972-4.089-2.131,3.7-3.994,7.388-6.623,12.62s-8.44,9.414-14.029,6.151c-4.032-2.353-7.663,3.44-3.643,5.786A16.485,16.485,0,0,0,503.3,1676.2a3.539,3.539,0,0,0,.51.225c3.73,1.3,8.621,4.753,10.111,5.978s4.053,7.468,5.575,10.605c1.846,3.806,7.8,1.029,5.944-2.794-1.725-3.559-4.621-10.662-6.877-12.459a56.583,56.583,0,0,0-8.3-5.641q3.018-5.858,6.314-11.563c7.041,2.152,13.731-.393,18.492-7.073C537.526,1650.022,531.824,1646.752,529.388,1650.168Z'
        />
      </g>
    </svg>
  );
};

export default FasterDecision;
