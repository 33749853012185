import React from 'react';

const css = `
.inv_a{fill:#289d78;}.inv_b{fill:#fff;}
`;

const Investment = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='54.976'
      height='54.976'
      viewBox='0 0 54.976 54.976'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <g transform='translate(-278.039 -2135.77)'>
        <g transform='translate(278.039 2135.77)'>
          <circle
            class='inv_a'
            cx='27.488'
            cy='27.488'
            r='27.488'
            transform='translate(0)'
          />
        </g>
        <g transform='translate(350.687 2189.27)'>
          <g transform='translate(-58 -41)'>
            <path
              class='inv_b'
              d='M1567.417,2177.094a7.547,7.547,0,1,0-7.544-7.55A7.545,7.545,0,0,0,1567.417,2177.094Zm-2.636-4.565c.069-.25.138-.493.212-.743.087-.287.169-.319.432-.181a4.206,4.206,0,0,0,1.423.424,1.759,1.759,0,0,0,.93-.125.727.727,0,0,0,.175-1.274,2.252,2.252,0,0,0-.531-.312,12.8,12.8,0,0,1-1.455-.65,2.055,2.055,0,0,1-1.161-1.967,2.161,2.161,0,0,1,1.58-2c.387-.143.387-.137.393-.543v-.412c.006-.306.062-.356.362-.368h.281c.649,0,.649,0,.649.649,0,.456,0,.462.456.531a4.024,4.024,0,0,1,1.005.3.3.3,0,0,1,.194.393c-.081.281-.156.562-.25.837s-.169.3-.418.18a3.226,3.226,0,0,0-1.6-.318,1.1,1.1,0,0,0-.424.088.6.6,0,0,0-.15,1.074,2.6,2.6,0,0,0,.694.387,12.925,12.925,0,0,1,1.268.575,2.27,2.27,0,0,1,.73,3.448,2.426,2.426,0,0,1-1.261.805.353.353,0,0,0-.306.4c.012.225,0,.443,0,.668a.276.276,0,0,1-.3.312c-.238.006-.48.006-.718,0a.291.291,0,0,1-.312-.331c0-.163,0-.324-.006-.487-.006-.356-.012-.375-.362-.431a4.376,4.376,0,0,1-1.281-.368C1564.726,2172.941,1564.694,2172.86,1564.782,2172.529Z'
              transform='translate(-1559.873 -2162)'
            />
            <path
              class='inv_b'
              d='M1584.684,2179.716h-.881a9.862,9.862,0,0,0-3.234-4.584.8.8,0,0,0-.026-.175,3.55,3.55,0,0,1,.531-2.866.717.717,0,0,0-.693-1.093,5.3,5.3,0,0,0-2.9,1.218,2.825,2.825,0,0,0-.887,1.462,9.08,9.08,0,0,1-14.351,5.077,8.822,8.822,0,0,0-.568,5.8h0a.111.111,0,0,0,.006.031,9.039,9.039,0,0,0,.75,1.992,10.442,10.442,0,0,0,1.917,2.9,5.526,5.526,0,0,1,1.486,3.553v.337h0a.654.654,0,0,0,.649.606h3.947a.649.649,0,0,0,.649-.649v-.974a13.621,13.621,0,0,0,1.755.118,12.649,12.649,0,0,0,1.38-.075v.93a.648.648,0,0,0,.649.649h3.959a.649.649,0,0,0,.649-.649v-.974a3.893,3.893,0,0,1,1.342-2.723l.132-.113c.012-.012.025-.019.037-.031h0a9.764,9.764,0,0,0,2.885-4.408h.831a1.2,1.2,0,0,0,1.2-1.2v-2.954A1.218,1.218,0,0,0,1584.684,2179.716Zm-4.94.212a.981.981,0,1,1,.98-.981A.978.978,0,0,1,1579.745,2179.928Z'
              transform='translate(-1560.216 -2163.99)'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Investment;
