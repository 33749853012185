import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    height: '100%',
    marginBottom: theme.spacing(10),

  },
  heading: {
    marginTop: theme.spacing(10),
    fontWeight: 'bold',
    color: theme.palette.home.textGrayDark
  },
  grid: {
    marginTop: theme.spacing(10),
  },
  imgContainer: {
    background: theme.palette.white,
    borderRadius: '50%',
  },
  names: {
    marginLeft: theme.spacing(-8),
    fontSize: theme.spacing(2.5),
    fontWeight: '600',
    color: theme.palette.home.textBlackDark
  },
  bio: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  bioAt: {
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  position: {
    marginLeft: theme.spacing(-8),
    fontSize: theme.spacing(2),
    color: theme.palette.product.textBlackLight
  }
}))