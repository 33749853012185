import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// assets
import DataFusion from '../../../assets/pngs/Company/DataFusion.png';
import Insights from '../../../assets/pngs/Company/Insights.png';
import Customize from '../../../assets/pngs/Company/Customize.png';

// style
import Styles from '../styles/Steps.styles';

const Steps = () => {
  const classes = Styles();

  return (
    <Box className={classes.root}>
      <Container>
        <Box display='flex' justifyContent='flex-end' className={classes.header}>
          <Typography  variant='h1' className={classes.heading}>
            Vivpro Method to solve the problem
          </Typography>
        </Box>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} lg={4}>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
              className={classes.box}
            >
              <img src={DataFusion} alt='icon' />
              <Typography variant='h2' className={classes.subheading}>
                Data fusion
              </Typography>
              <Typography variant='subtitle1' className={classes.description}>
                Curated public data assets and integration with internal data
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4} className={classes.second}>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
              className={classes.box}
            >
              <img src={Insights} alt='icon' />
              <Typography variant='h2' className={classes.subheading}>
                Insights at Scale
              </Typography>
              <Typography variant='subtitle1' className={classes.description}>
                Leverage information at scale to derive superior insights at
                scale
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4} className={classes.third}>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
              className={classes.box}
            >
              <img src={Customize} alt='icon' />
              <Typography variant='h2' className={classes.subheading}>
                Customize & On-demand Experience
              </Typography>
              <Typography variant='subtitle1' className={classes.description}>
                Customizable platform and collaborate with our experts on
                solving the most complex problems
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Steps;
