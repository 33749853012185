import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    height: '100vh',
    [theme.breakpoints.down('md')]: {
      height: '100%',
    },
  },
  quoteBox: {
    marginTop: theme.spacing(-10),
    width: theme.spacing(40),
    padding: theme.spacing(5),
    background: theme.palette.white,
    boxShadow: '0px 10px 20px #00000029',
    borderRadius: theme.spacing(1.5)
  },
  quote: {
    // width: '80%',
    color: theme.palette.home.textBlackDark,
    fontWeight: '600'
  },
  descriptionBox: {
    marginTop: theme.spacing(20),
    padding: theme.spacing(8),
    background: theme.palette.services.gradientMain,
    borderRadius: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(5),
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
  },
  description: {
    width: '40%',
    color: theme.palette.white,
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      width: '100%',
      fontSize: theme.spacing(2)
    },
  }
}))