import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  appbar: {
    paddingTop: theme.spacing(1),
  },
  links: {
    // marginLeft: theme.spacing(-8),
  },
  navLinks1: {
    // marginRight: theme.spacing(5),
    color: theme.palette.navbar.textLight,
    cursor: 'pointer'
  },
  navLinks2: {
    marginRight: theme.spacing(5),
    color: theme.palette.navbar.links,
    cursor: 'pointer'
  },
  navLinks: {
    marginRight: theme.spacing(5),
    color: theme.palette.navbar.textLight,
    cursor: 'pointer'
  },
  iconDark: {
    color: theme.palette.navbar.links,
  },
  iconLight: {
    color: theme.palette.navbar.textLight,
  },
  btnText: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textTransform: 'capitalize',
    color: theme.palette.white,
    fontSize: theme.typography.btn.fontSize
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    background: theme.palette.primary.light
  },
  paperText: {
    color: theme.palette.white,
    fontSize: theme.spacing(2),
    fontWeight: 'medium'
  },
  dropdown: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  mobileNavLink: {
    color: theme.palette.home.textGrayDark,
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: 'bold'
  },
  mobileNavLink1: {
    color: theme.palette.home.textGray,
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: 'bold'
  },

}))