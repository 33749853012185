import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(20),
  },
  header: {
    background: theme.palette.resources.greenLightBackground,
  },
  heading: {
    padding: theme.spacing(1),
    width: '30%',
    borderRadius: theme.spacing(0.5),
    letterSpacing: theme.spacing(0.5),
    background: theme.palette.resources.greenLightBackground,
    color: theme.palette.resources.greenDark,
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  title: {
    marginTop: theme.spacing(2),
    color: theme.palette.home.textGrayDark,
    fontWeight: '600',
    letterSpacing: theme.spacing(0.1),
  },
  img: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  btnText: {
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textTransform: 'capitalize',
    color: theme.palette.white,
    background: theme.palette.btn.red,
    fontSize: theme.typography.body2.fontSize,
    '&:hover': {
      color: theme.palette.black,
    },
  },
  videoIcon: {
    marginRight: theme.spacing(1),
  },
}));
