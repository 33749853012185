import React from 'react';

const css = `
  .better_a{fill:#04d7ab;}.better_b{fill:#efefef;}.better_c{fill:#f3f3f1;}.better_d{fill:#d5dbe1;}
`;

const BetterQuality = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='8vh'
      height='8vh'
      viewBox='0 0 71.389 79.144'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <g transform='translate(-734.813 -1625.999)'>
        <path
          class='better_a'
          d='M750.059,1688.861v-30.673a2.273,2.273,0,0,1,2.272-2.272h-5.112a2.273,2.273,0,0,0-2.272,2.272v30.673a2.273,2.273,0,0,0,2.272,2.272h5.112A2.273,2.273,0,0,1,750.059,1688.861Z'
        />
        <path
          class='better_b'
          d='M748.643,1697c15.716,0,26.509,8.141,34.082,8.141s23.477-4.353,23.477-39.191S784.806,1626,776.476,1626c-39,0-55.666,71-27.833,71Z'
        />
        <path
          class='better_a'
          d='M778.938,1631.807l3.213-3.212,1.606,1.606-3.213,3.213Z'
        />
        <path
          class='better_a'
          d='M769.7,1629.793l1.607-1.606,3.212,3.213-1.606,1.606Z'
        />
        <path
          class='better_a'
          d='M778.935,1639.035l1.606-1.607,3.213,3.213-1.606,1.606Z'
        />
        <path
          class='better_a'
          d='M761.806,1699.206a3.408,3.408,0,1,1,3.408-3.408A3.413,3.413,0,0,1,761.806,1699.206Zm0-4.544a1.136,1.136,0,1,0,1.136,1.136A1.137,1.137,0,0,0,761.806,1694.662Z'
        />
        <path
          class='better_a'
          d='M752.9,1691.133h-5.68a2.273,2.273,0,0,1-2.272-2.272v-30.673a2.273,2.273,0,0,1,2.272-2.272h5.68a2.273,2.273,0,0,1,2.272,2.272v30.673A2.278,2.278,0,0,1,752.9,1691.133Z'
        />
        <path
          class='better_c'
          d='M786.983,1658.188h-13a17.481,17.481,0,0,0,2.272-9.088c0-6.817-3.408-9.089-5.68-9.089s-3.408,1.136-3.408,6.817c0,7.952-11.36,13.632-11.36,13.632l-.637.318V1685l.637.454s7.952,5.68,24.992,5.68h3.636a9.074,9.074,0,0,0,8.952-7.543l2.545-14.769a9.084,9.084,0,0,0-8.952-10.633Z'
        />
        <path
          class='better_d'
          d='M760.922,1685.453l-.636-.454v-24.221l.636-.318s11.36-5.68,11.36-13.632c0-3.111.355-4.831,1.036-5.776a4.487,4.487,0,0,0-2.74-1.041c-2.272,0-3.408,1.136-3.408,6.817,0,7.952-11.36,13.632-11.36,13.632l-.637.318V1685l.637.454s7.952,5.68,24.992,5.68h3.636c.1,0,.184-.022.277-.025C768.517,1690.847,760.922,1685.453,760.922,1685.453Z'
        />
        <path d='M752.9,1692.837h-5.68a3.98,3.98,0,0,1-3.976-3.976v-30.673a3.98,3.98,0,0,1,3.976-3.976h5.68a3.98,3.98,0,0,1,3.976,3.976v30.673A3.98,3.98,0,0,1,752.9,1692.837Zm-5.68-35.217a.568.568,0,0,0-.568.568v30.673a.568.568,0,0,0,.568.568h5.68a.574.574,0,0,0,.568-.568v-30.673a.568.568,0,0,0-.568-.568Z' />
        <path d='M784.438,1692.837H780.8c-17.385,0-25.64-5.753-25.983-6l-.636-.454,1.981-2.775.636.455c.071.05,7.816,5.362,24,5.362h3.636a7.364,7.364,0,0,0,7.273-6.128l2.545-14.768a7.38,7.38,0,0,0-7.273-8.639h-13a1.7,1.7,0,0,1-1.418-2.649,16.129,16.129,0,0,0,1.986-8.143c0-5.417-2.376-7.385-3.976-7.385-.838,0-1.7,0-1.7,5.113,0,8.9-11.8,14.9-12.3,15.157l-.636.318-1.525-3.049.636-.318c.1-.05,10.418-5.31,10.418-12.108,0-4.122.4-8.521,5.112-8.521,2.554,0,7.384,2.256,7.384,10.793a21.244,21.244,0,0,1-1.286,7.384H786.98a10.788,10.788,0,0,1,10.631,12.628l-2.544,14.769a10.758,10.758,0,0,1-10.629,8.956Z' />
      </g>
    </svg>
  );
};

export default BetterQuality;
