import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

// assets
import ProductBanner from '../../../assets/pngs/Product/ProductBanner.png';

// styles
import Styles from '../styles/Banner.styles';

const Banner = () => {
  const classes = Styles();

  return (
    <Box display='flex' className={classes.roots}>
      <Container maxWidth='lg'>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              className={classes.textBox}
            >
              <Typography align='left' variant='h1' className={classes.heading}>
                Unified BioIntelligence Platform for Medical Products
              </Typography>
              <Typography
                align='left'
                variant='subtitle1'
                className={classes.description}
              >
                RIA empowers customers with automated clinical and regulatory
                intelligence so they can focus on what is most important for
                them – Strategy!
              </Typography>
              <Button
                variant='contained'
                href='/contact'
                className={classes.btn}
              >
                Request Demo
              </Button>
            </Box>
          </Grid>
          <Hidden mdDown>
            <Grid item lg={6}>
              <Box display='flex' justifyContent='center'>
                <img
                  src={ProductBanner}
                  alt='ProductBanner'
                  className={classes.img}
                />
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </Box>
  );
};

export default Banner;
