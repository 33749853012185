import React from 'react';

const css = `
.arrow_a{fill:url(#arrow_a);cursor:pointer}
`;

const Arrow = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlink='http://www.w3.org/1999/xlink'
      width='22'
      height='12'
      viewBox='0 0 22 12'
    >
      <defs>
        <style>{css}</style>
        <radialGradient
          id='arrow_a'
          cx='0.5'
          cy='0.5'
          r='0.738'
          gradientTransform='translate(0.227) scale(0.545 1)'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#fff' />
          <stop offset='0.003' stopColor='#a4a4a4' />
          <stop offset='1' stopColor='#fff' />
        </radialGradient>
      </defs>
      <path
        className='arrow_a'
        d='M755.766,2320.115a1,1,0,0,0-1.414,0l-9.293,9.293-9.293-9.293a1,1,0,0,0-1.414,1.414l10,10a1,1,0,0,0,1.414,0l10-10A1,1,0,0,0,755.766,2320.115Z'
        transform='translate(-734.059 -2319.822)'
      />
    </svg>
  );
};

export default Arrow;
