import React, { useContext, useEffect, useState } from 'react';

// material ui
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';

// material icons
import MenuIcon from '@material-ui/icons/Menu';

// styles
import Styles from './styles/Navbar.styles';

// store
import Store from '../store';

// assets
import VivproLogo from '../assets/svgs/VivproLogo';
import VivproLogoDark from '../assets/svgs/VivproLogoDark';
import VivproWhiteLogo from '../assets/svgs/VivproWhiteLogo';
import { useLocation } from 'react-router-dom';
import { AppBar } from '@mui/material';

const Navbar = () => {
  const classes = Styles();
  const [pages, setPages] = useState();
  const [drawer, setDrawer] = useState(false);
  const { state } = useContext(Store);
  const location = useLocation();

  useEffect(() => {
    async function getData() {
      try {
        setPages(['Services', 'Products', 'Company', 'Resources', 'Newsroom']);
      } catch (e) {
        // console.log(e);
      }
    }

    getData();
  }, [state.activePage]);

  return (<AppBar
    color="transparent"
    position="absolute"
    elevation={0}
    sx={location.pathname === '/services' && {
      backgroundColor: 'rgba(0, 0, 0, 0.80) !important'
    }}

  >
    <Container>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Toolbar>
          <Link href="/">
            {pages && pages.includes(state && state.activePage) ? (<VivproLogoDark />) : state.activePage === 'Raps' ? (
              <VivproWhiteLogo />) : (<VivproLogo />)}
          </Link>
        </Toolbar>
        <Hidden mdDown>
          <Toolbar className={classes.links}>
            <Link href="/" underline="none">
              <Typography
                className={pages && pages.includes(state && state.activePage) ? classes.navLinks2 : classes.navLinks}
              >
                Home
              </Typography>
            </Link>
            <Link href="/products" underline="none">
              <Typography
                className={pages && pages.includes(state && state.activePage) ? classes.navLinks2 : classes.navLinks}
              >
                Product
              </Typography>
            </Link>
            <Link href="/services" underline="none">
              <Typography
                className={pages && pages.includes(state && state.activePage) ? classes.navLinks2 : classes.navLinks}
              >
                Services
              </Typography>
            </Link>
            <Link href="/company" underline="none">
              <Typography
                className={pages && pages.includes(state && state.activePage) ? classes.navLinks2 : classes.navLinks}
              >
                Company
              </Typography>
            </Link>
            <Link href="/resources" underline="none">
              <Typography
                className={pages && pages.includes(state && state.activePage) ? classes.navLinks2 : classes.navLinks}
              >
                Resources
              </Typography>
            </Link>
            <Link href="/contact" underline="none">
              <Typography
                className={pages && pages.includes(state && state.activePage) ? classes.navLinks2 : classes.navLinks1}
              >
                Contact
              </Typography>
            </Link>
          </Toolbar>
          <Toolbar>
            <Button
              target="_blank"
              href="https://www.ria.vivpro.ai/"
              variant="contained"
              color="secondary"
              className={classes.btnText}
            >
              Get Started
            </Button>
          </Toolbar>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            aria-label="menu"
            disableRipple
            disableTouchRipple
            disableFocusRipple
            className={pages && pages.includes(state && state.activePage) ? classes.iconDark : classes.iconLight}
            onClick={() => setDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            variant="temporary"
            anchor="top"
            open={drawer}
            onClose={() => setDrawer(false)}
          >
            <List>
              <ListItem button disableTouchRipple>
                <Link href="/" underline="none">
                  <MenuItem disableTouchRipple>
                    <Typography className={classes.mobileNavLink}>
                      Home
                    </Typography>
                  </MenuItem>
                </Link>
              </ListItem>
              <ListItem button disableTouchRipple>
                <Link href="/products" underline="none">
                  <MenuItem disableTouchRipple>
                    <Typography className={classes.mobileNavLink}>
                      Product
                    </Typography>
                  </MenuItem>
                </Link>
              </ListItem>
              <ListItem button disableTouchRipple>
                <Link href="/services" underline="none">
                  <MenuItem disableTouchRipple>
                    <Typography className={classes.mobileNavLink}>
                      Services
                    </Typography>
                  </MenuItem>
                </Link>
              </ListItem>
              <ListItem button disableTouchRipple>
                <Link href="/company" underline="none">
                  <MenuItem disableTouchRipple>
                    <Typography className={classes.mobileNavLink}>
                      Company
                    </Typography>
                  </MenuItem>
                </Link>
              </ListItem>
              <ListItem button disableTouchRipple>
                <Link href="/resources" underline="none">
                  <MenuItem disableTouchRipple>
                    <Typography className={classes.mobileNavLink}>
                      Resources
                    </Typography>
                  </MenuItem>
                </Link>
              </ListItem>
              <ListItem button disableTouchRipple>
                <Link href="/contact" underline="none">
                  <MenuItem disableTouchRipple>
                    <Typography className={classes.mobileNavLink}>
                      Contact Us
                    </Typography>
                  </MenuItem>
                </Link>
              </ListItem>
              <ListItem button disableTouchRipple>
                <MenuItem disableTouchRipple>
                  <Button
                    target="_blank"
                    rel="noopener"
                    href="https://www.ria.vivpro.ai/"
                    variant="contained"
                    color="secondary"
                    className={classes.btnText}
                  >
                    Get Started
                  </Button>
                </MenuItem>
              </ListItem>
            </List>
            <Divider />
          </Drawer>
        </Hidden>
      </Box>
    </Container>
  </AppBar>);
};

export default Navbar;
