import React, { useState, useEffect, useContext } from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

// data
import CaseStudies from '../../../data/CaseStudies.json';
import Newsroom from '../../../data/Newsroom.json';
import Webinars from '../../../data/Webinars.json';

// other components
import Cards from './Card';

// context
import Store from '../../../store';

// api
import { getArticles } from '../../../api/Blogs/client';

// style
import Styles from '../styles/CardGrid.styles';

const CardGrid = () => {
  const classes = Styles();
  const { state } = useContext(Store);
  const [caseStudies, setCaseStudies] = useState();
  const [blogs, setBlogs] = useState([]);
  const [newsroom, setNewsroom] = useState([]);
  const [allCards, setAllCards] = useState();
  const [webinars, setWebinars] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        Newsroom.sort(
          (a, b) =>
            new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime()
        );
        CaseStudies.sort(
          (a, b) =>
            new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime()
        );
        Webinars.sort(
          (a, b) =>
            new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime()
        );
        setCaseStudies(CaseStudies);
        setNewsroom(Newsroom);
        setWebinars(Webinars);
        const blog_res = await getArticles();
        let all_blogs = blog_res.data.items;
        for (let i in all_blogs) {
          let dt = new Date(all_blogs[i].pubDate);
          let months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sept',
            'Oct',
            'Nov',
            'Dec',
          ];
          all_blogs[i].date =
            dt.getDate() + ' ' + months[dt.getMonth()] + ' ' + dt.getFullYear();
          all_blogs[i].type_initials = 'B';
          all_blogs[i].type = 'Blog';
        }
        all_blogs.sort(
          (a, b) =>
            new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime()
        );
        let temp = [];
        let allData = temp
          .concat(CaseStudies)
          .concat(Newsroom)
          .concat(Webinars)
          .concat(all_blogs);
        allData.sort(
          (a, b) =>
            new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime()
        );
        setBlogs(all_blogs);
        setAllCards(allData);
      } catch (e) {
        // console.log(e);
      }
    };
    fetchData();
  }, []);

  return (
    <Box display='flex' justifyContent='center' className={classes.root}>
      <Container>
        <Grid container spacing={1} className={classes.grid}>
          {state && state.resourceCategory === 'All' ? (
            <>
              {allCards &&
                allCards.map((item) => {
                  return (
                    <Grid item xs={12} lg={4}>
                      <Box padding={3}>
                        <Link
                          href={
                            item.type === 'Blog'
                              ? item.link
                              : `/resources/${item.urlPath}/${item.id}`
                          }
                          underline='none'
                        >
                          <Cards content={item} />
                        </Link>
                      </Box>
                    </Grid>
                  );
                })}
            </>
          ) : null}
          {state && state.resourceCategory === 'Newsroom' ? (
            <>
              {newsroom &&
                newsroom.map((item) => {
                  return (
                    <Grid item xs={12} lg={4}>
                      <Box padding={3}>
                        <Link
                          href={`/resources/newsroom/${item.id}`}
                          underline='none'
                        >
                          <Cards content={item} />
                        </Link>
                      </Box>
                    </Grid>
                  );
                })}
            </>
          ) : null}
          {state && state.resourceCategory === 'Webinars' ? (
            <>
              {webinars?.map((item) => {
                  return (
                    <Grid item xs={12} lg={4}>
                      <Box padding={3}>
                        <Link
                          href={`/resources/webinar/${item.id}`}
                          underline='none'
                        >
                          <Cards content={item} />
                        </Link>
                      </Box>
                    </Grid>
                  );
                })}
            </>
          ) : null}
          {state && state.resourceCategory === 'Blogs' ? (
            <>
              {blogs &&
                blogs.map((item) => {
                  return (
                    <Grid item xs={12} lg={4}>
                      <Box padding={3}>
                        <Link href={item.link} underline='none'>
                          <Cards content={item} />
                        </Link>
                      </Box>
                    </Grid>
                  );
                })}
            </>
          ) : null}
          {state && state.resourceCategory === 'Case Studies' ? (
            <>
              {caseStudies &&
                caseStudies.map((item) => {
                  return (
                    <Grid item xs={12} lg={4}>
                      <Box padding={3}>
                        <Link
                          href={`/resources/casestudies/${item.id}`}
                          underline='none'
                        >
                          <Cards content={item} />
                        </Link>
                      </Box>
                    </Grid>
                  );
                })}
            </>
          ) : null}
        </Grid>
      </Container>
    </Box>
  );
};

export default CardGrid;
