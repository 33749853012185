import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  gridItem: {
    padding: theme.spacing(5),
  },
  paper: {
    height: theme.spacing(35),
    borderRadius: theme.spacing(2),
  },
  cardBox: {
    height: '100%',
    width: '100%',
  },
  img: {
    marginTop: theme.spacing(-4),
    height: '70%',
    width: '80%',
  },
  imgStarbucks: {
    marginTop: theme.spacing(-4),
    height: '70%',
    width: '60%',
  },
  text: {
    marginTop: theme.spacing(3),
    color: theme.palette.navbar.links,
    fontWeight: '600',
  },
  text1: {
    color: theme.palette.navbar.links,
    fontWeight: '600',
  },
}));
