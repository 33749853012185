import React from 'react';

const css = `
.te_a{fill:none;stroke:#fff;stroke-linecap:square;stroke-miterlimit:10;stroke-width:2px;}
`;

const TechnologyEnabled = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='46.752'
      height='46.752'
      viewBox='0 0 46.752 46.752'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <g transform='translate(1 1)'>
        <ellipse
          class='te_a'
          cx='4.475'
          cy='4.475'
          rx='4.475'
          ry='4.475'
          transform='translate(10.442 10.442)'
        />
        <ellipse
          class='te_a'
          cx='2.238'
          cy='2.238'
          rx='2.238'
          ry='2.238'
          transform='translate(12.68)'
        />
        <ellipse
          class='te_a'
          cx='2.238'
          cy='2.238'
          rx='2.238'
          ry='2.238'
          transform='translate(0 12.68)'
        />
        <ellipse
          class='te_a'
          cx='2.238'
          cy='2.238'
          rx='2.238'
          ry='2.238'
          transform='translate(0 27.597)'
        />
        <ellipse
          class='te_a'
          cx='2.238'
          cy='2.238'
          rx='2.238'
          ry='2.238'
          transform='translate(40.276 12.68)'
        />
        <ellipse
          class='te_a'
          cx='2.238'
          cy='2.238'
          rx='2.238'
          ry='2.238'
          transform='translate(40.276 27.597)'
        />
        <ellipse
          class='te_a'
          cx='2.238'
          cy='2.238'
          rx='2.238'
          ry='2.238'
          transform='translate(27.597)'
        />
        <ellipse
          class='te_a'
          cx='2.238'
          cy='2.238'
          rx='2.238'
          ry='2.238'
          transform='translate(12.68 40.276)'
        />
        <ellipse
          class='te_a'
          cx='2.238'
          cy='2.238'
          rx='2.238'
          ry='2.238'
          transform='translate(27.597 40.276)'
        />
        <ellipse
          class='te_a'
          cx='4.475'
          cy='4.475'
          rx='4.475'
          ry='4.475'
          transform='translate(25.359 10.442)'
        />
        <ellipse
          class='te_a'
          cx='4.475'
          cy='4.475'
          rx='4.475'
          ry='4.475'
          transform='translate(10.442 25.359)'
        />
        <ellipse
          class='te_a'
          cx='4.475'
          cy='4.475'
          rx='4.475'
          ry='4.475'
          transform='translate(25.359 25.359)'
        />
      </g>
    </svg>
  );
};

export default TechnologyEnabled;
