const white = '#FFFFFF';
const black = '#000000';

const palette = {
  white,
  black,
  type: 'light',
  primary: {
    light: '#6868FA',
    main: '#4524CB',
  },
  secondary: {
    light: '#04D7AB',
    main: '#06C66C',
    dark: '#289D78',
  },
  gradient: {
    main: 'linear-gradient(120deg, #6868FA 0%, #4524CB 100%)',
    // main: 'linear-gradient(0deg,#4524CB 0%, #6868FA 100% )',
  },
  navbar: {
    textLight: '#C9D2F6',
    links: '#A4A4A4',
  },
  home: {
    textBlackDark: '#263035',
    textBlackLight: '#1A1A1A',
    textGrayDark: '#333333',
    textGray: '#7C7D8D',
    avatarYellow: '#F39C12',
    red: '#E74C3C',
    purple: '#7D3C98',
    blue: '#4172D9',
    shadow: '#0000001A',
  },
  footer: {
    textLight: '#E6E6E6',
    textDark: '#D6D6D6',
  },
  services: {
    gradientMain: 'linear-gradient(141deg, #06C66C 0%, #48E0A6 100%)',
  },
  resources: {
    greenDark: '#32B189',
    greenLight: '#48E0A6',
    shadow: '#00000029',
    shadowLight: '#00000014',
    backgroundLight: '#F5F9FF',
    green: '#3ab48e',
    purple: '#7D3C98',
    greenLightBackground: '#d4eee7',
  },
  product: {
    textBlackLight: '#666666',
    shadow: '#64646F',
  },
  contact: {
    blueLight: 'rgba(36, 74, 220, .06)',
    grayText: '#999999',
  },
  casestudy: {
    background: '#F5F9FF',
    grayLight: '#7A9C91',
    whiteSecondary: '#E2EBFA',
  },
  subscription: {
    blue: '#5441df',
    blueDark: '#3d32ab',
    white: '#FFFFFF86',
  },
  raps: {
    text: '#60eee2',
  },
  btn: {
    red: '#cd2653',
  },
};

export default palette;
