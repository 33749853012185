import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// assets
import FasterDecision from '../../../assets/svgs/HomePage/FasterDecision';
import BetterQuality from '../../../assets/svgs/HomePage/BetterQuality';
import SmarterTechnology from '../../../assets/svgs/HomePage/SmarterTechnology';
import SuperiorStrategy from '../../../assets/svgs/HomePage/SuperiorStrategy';

// styles
import Styles from '../styles/Advantages.styles';

const Advantages = () => {
  const classes = Styles();

  return (
    <Box display='flex' className={classes.root}>
      <Container>
        <Box display='flex' justifyContent='center'>
          <video
            className={classes.center}
            controls='controls'
            // width='500'
            // height='400'
            name='Video Name'
            src='https://vivpro-site.s3.amazonaws.com/Vivpro_video.mov'
          ></video>
        </Box>
        <Box mt={10} display='flex' flexDirection='column' alignItems='center'>
          <Typography align='center' variant='h2' className={classes.title}>
            A Revolutionary Software Platform
          </Typography>
          <Typography align='center' variant='body1' className={classes.subtitle}>
            to make evidence-based clinical, regulatory and business decisions
            at lightning speed for pharmaceutical, biotech and device
            developers, CROs and investors.
          </Typography>
          <Typography variant='subtitle2' className={classes.subtitle1}>
            MORE POWER TO YOU.
          </Typography>
        </Box>
        <Paper elevation={1} className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='flex-start'
                className={classes.box}
              >
                <FasterDecision />
                <Typography variant='subtitle1' className={classes.heading}>
                  Faster Decisions
                </Typography>
                <Typography className={classes.description}>
                  Unlocking knowledge and insights quickly to focus on strategy.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='flex-start'
                className={classes.box}
              >
                <BetterQuality />
                <Typography variant='subtitle1' className={classes.heading}>
                  Better Quality
                </Typography>
                <Typography className={classes.description}>
                  Digitalization in a contextual manner augmented by human
                  expertise.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='flex-start'
                className={classes.box}
              >
                <SmarterTechnology />
                <Typography variant='subtitle1' className={classes.heading}>
                  Smarter Technology
                </Typography>
                <Typography className={classes.description}>
                  Integrating scalable and cognitive platform for automated
                  knowledge.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='flex-start'
                className={classes.box}
              >
                <SuperiorStrategy />
                <Typography variant='subtitle1' className={classes.heading}>
                  Superior Strategy
                </Typography>
                <Typography className={classes.description}>
                  Enabling intelligence growth in day-to-day business for better
                  future.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
              >
                <Typography className={classes.number}>50K+</Typography>
                <Typography className={classes.helperText}>
                  Drugs, Biologics, Devices
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
              >
                <Typography className={classes.number}>80K+</Typography>
                <Typography className={classes.helperText}>
                  Regulatory Documents
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
              >
                <Typography className={classes.number}>25+</Typography>
                <Typography className={classes.helperText}>
                  Databases......
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default Advantages;
