import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

// assets
import Amazon from '../../../assets/pngs/Raps/Amazon.png';
import Google from '../../../assets/pngs/Raps/Google.jpg';
import Starbucks from '../../../assets/pngs/Raps/Starbucks.jpg';

// style
import Styles from '../styles/Cards.styles';

const Cards = () => {
  const classes = Styles();

  return (
    <Box className={classes.root}>
      <Container>
        <Grid container>
          <Grid item lg={4} xs={12} className={classes.gridItem}>
            <Paper elevation={3} className={classes.paper}>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                className={classes.cardBox}
              >
                <img src={Amazon} className={classes.img} alt='thumbnail' />
                <Typography variant='h3' className={classes.text}>
                  Amazon Gift Card
                </Typography>
                <Typography variant='h3' className={classes.text1}>
                  $100
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item lg={4} xs={12} className={classes.gridItem}>
            <Paper elevation={3} className={classes.paper}>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                className={classes.cardBox}
              >
                <img src={Google} className={classes.img} alt='thumbnail' />
                <Typography variant='h3' className={classes.text}>
                  Google Nest
                </Typography>
                <Typography variant='h3' className={classes.text1}>
                  Mini
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item lg={4} xs={12} className={classes.gridItem}>
            <Paper elevation={3} className={classes.paper}>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                className={classes.cardBox}
              >
                <img
                  src={Starbucks}
                  className={classes.imgStarbucks}
                  alt='thumbnail'
                />
                <Typography variant='h3' className={classes.text}>
                  Starbucks Gift
                </Typography>
                <Typography variant='h3' className={classes.text1}>
                  Card $25
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Cards;
