import { makeStyles } from '@material-ui/core';
import ResourceBG from '../../../assets/pngs/CaseStudies/BannerBg.png';

export default makeStyles(theme => ({
  mainRoot: {
    background: theme.palette.casestudy.background
  },
  root: {
    height: '160vh',
    background: `url(${ResourceBG}) no-repeat`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      background: theme.palette.primary.main,
      height: '100%',
    },
  },
  grid: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(20),
  },
  bg: {
    width: '100%'
  },
  btn: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    margin: theme.spacing(4),
    color: theme.palette.white,
    background: theme.palette.resources.greenDark,
    fontSize: theme.typography.body2.fontSize,
    letterSpacing: theme.spacing(.5),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0),
    },
  },
  textBox: {
    paddingLeft: theme.spacing(4),
    marginTop: theme.spacing(3),
    width: '70%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0),
      width: '100%'
    },
  },
  background: {
    // height:'100%',
    width: '100%',
    // objectFit:'fill',
    padding: theme.spacing(0),
    border: '1px solid red'
  },
  description: {
    fontWeight:'bold',
    // lineHeight:theme.spacing(.1),
    color: theme.palette.white
  },
  img: {
    marginTop: theme.spacing(6),
    height: '51.4vh',
    width: '27.5vw'
  }
}))