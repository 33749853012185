import React from "react";

// material ui
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

// style
import Styles from "../styles/Content.styles";

const Content = ({ newsroom }) => {
  const classes = Styles();

  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        {/* <Typography>{newsroom && newsroom.intro[0].text}</Typography> */}
        {newsroom &&
          newsroom.intro.map((item) => {
            return (
              <Typography
                variant="body1"
                className={classes.intro}
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
              //   {item.text}
              // </Typography>
            );
          })}
        {newsroom && newsroom.name === "RAPS" ? (
          <Typography variant="body1" className={classes.intro}>
            More information is available on{" "}
            <Link
              href="https://www.eventscribe.net/2021/RAPSConvergence2021/productName.asp?pfp=ProductShowcase:ByName"
              target="_blank"
            >
              RAPS Product Show Case{" "}
            </Link>{" "}
            and <Link href="/">vivpro.ai</Link>
          </Typography>
        ) : null}

        {newsroom?.name !== undefined && (
          <Typography variant="subtitle1" className={classes.heading}>
            About {newsroom && newsroom.name}
          </Typography>
        )}

        {newsroom?.about !== undefined &&
          newsroom?.about.map((item) => {
            return (
              <Typography variant="body1" className={classes.intro}>
                {item && item.text}
              </Typography>
            );
          })}
        {newsroom?.additionalCompany ? (
          <>
            <Typography variant="subtitle1" className={classes.heading}>
              {newsroom && newsroom?.additionalCompany.aboutText}
            </Typography>
            <Typography variant="body1" className={classes.intro}>
              {newsroom && newsroom?.additionalCompany.aboutDescription}
            </Typography>
          </>
        ) : null}
        <Typography variant="subtitle1" className={classes.heading}>
          About Vivpro
        </Typography>
        <Typography variant="body1" className={classes.intro}>
          {newsroom && newsroom.aboutVivpro}
        </Typography>
        <Box display="flex" flexDirection="row">
          <Typography variant="body1" className={classes.heading}>
            Brand Promise:
          </Typography>
          <Typography variant="body1" className={classes.intro}>
            &nbsp; “More Power To You”
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row">
          <Typography variant="body1" className={classes.heading}>
            Vision:
          </Typography>
          <Typography variant="body1" className={classes.intro}>
            &nbsp; “Re-imagining Drug Development”
          </Typography>
        </Box>
        {newsroom?.title === "Introducing Vivpro Varsity" ? (
          <Box mt={5} display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body1" className={classes.linkTag}>
              Registration Link:
            </Typography>
            <Button
              href={newsroom.link}
              target="_blank"
              variant="contained"
              color="secondary"
              className={classes.btn}
            >
              Register
            </Button>
          </Box>
        ) : null}
      </Container>
    </Box>
  );
};

export default Content;
