import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

// assets
import Bitmap from '../../../assets/pngs/CaseStudies/Bitmap.png';

// style
import Styles from '../styles/Banner.styles';

const Banner = (props) => {
  const classes = Styles();

  return (
    <Box className={classes.root}>
      <Container>
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={12} lg={6} className={classes.grid}>
            <Button
              disableTouchRipple
              variant='contained'
              color='secondary'
              className={classes.btn}
            >
              Case Study
            </Button>
            <Box
              display='flex'
              justifyContent='center'
              className={classes.textBox}
            >
              <Typography
                align='left'
                variant='h1'
                className={classes.description}
              >
                {props.caseStudy && props.caseStudy.description}
              </Typography>
            </Box>
          </Grid>
          <Hidden mdDown>
            <Grid item lg={6} className={classes.grid}>
              <img src={Bitmap} alt='banner' className={classes.img} />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </Box>
  );
};

export default Banner;
