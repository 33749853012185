import { makeStyles } from '@material-ui/core';
import AriaBg from '../../../assets/svgs/Product/AriaBg.svg';

export default makeStyles(theme => ({
  roots: {
    marginTop: theme.spacing(15),
    height: '150vh',
    paddingTop: theme.spacing(45),
    background: `url(${AriaBg})`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      height: '100%',
      paddingTop: theme.spacing(5),
      background: theme.palette.primary.main,
    },
  },
  selected: {
    width: theme.spacing(50),
    padding: theme.spacing(2),
    background: theme.palette.secondary.main,
    borderRadius: theme.spacing(1.5),
    boxShadow: `0px 3px 6px ${theme.palette.resources.shadow}`,
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  selectedText: {
    color: theme.palette.white,
    fontSize: theme.spacing(2),
    fontWeight: 'bold'
  },
  unselected: {
    marginLeft: theme.spacing(2.5),
    width: theme.spacing(45),
    padding: theme.spacing(2),
    background: theme.palette.white,
    cursor: 'pointer',
    boxShadow: `0px 20px 40px ${theme.palette.resources.shadow}`,
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },

  },
  unselectedText: {
    color: theme.palette.home.textBlackDark,
    fontSize: theme.spacing(2),
    fontWeight: 'bold'
  },
  box: {
    width: '70%',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(5),
      width: '100%',
    },
  },
  heading: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: 'bold',
    color: theme.palette.white,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    },
  },
  description: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.white,
    opacity: .8,
    [theme.breakpoints.down('md')]: {
      textAlign: 'justify',
    },
  }
}))