import React, { Fragment } from 'react';

import ThemeProvider from '@mui/material/styles/ThemeProvider';

// child components
import Banner from './components/Banner';
import Advantages from './components/Advantages';
import Resources from './components/Resources';
// import Clients from './components/Clients';
import Testimonials from './components/Testimonials';

import theme from '../../themev5';

const Home = () => {
  return (<Fragment>
      <Banner />
      <Advantages />
      <Resources />
      {/* <Clients /> */}
      <ThemeProvider theme={theme}>
        <Testimonials />
      </ThemeProvider>
    </Fragment>);
};

export default Home;
