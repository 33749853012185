import React, { useState, useEffect } from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';

// data
import CaseStudies from '../../../data/CaseStudies.json';
import Newsroom from '../../../data/Newsroom.json';
import Webinars from '../../../data/Webinars.json';

// api
import { getArticles } from '../../../api/Blogs/client';

// assets
import ArrowIcon from '../../../assets/svgs/HomePage/ArrowIcon';

// styles
import Styles from '../styles/Resources.styles';

const Resources = () => {
  const classes = Styles();
  const [recent, setRecent] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("HEllo")
        const blog_res = await getArticles();
        console.log("I am, here")
        const all_blogs = blog_res.data.items;
        for (let i in all_blogs) {
          all_blogs[i].type_initials = 'B';
          all_blogs[i].type = 'Blog';
        }
        console.log(all_blogs)
        let temp = [];
        let allData = temp
          .concat(CaseStudies)
          .concat(Newsroom)
          .concat(Webinars)
          .concat(all_blogs);
        allData.sort(
          (a, b) =>
            new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime()
        );
        console.log("DIsplaubg", allData);
        setRecent(allData.slice(0, 5));
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  return (
    <Box className={classes.root}>
      <Container>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography className={classes.heading}>Resources</Typography>
          <Typography variant='subtitle1' className={classes.description}>
            Check out Vivpro<sup className={classes.superscript}>AI</sup>{' '}
            Whitepapers, Case Studies, Blogs and Latest News
          </Typography>
        </Box>
        <Typography align='left' variant='subtitle1' className={classes.recent}>
          Recent
        </Typography>
        <Grid container spacing={4} className={classes.grid}>
          <Grid item xs={12} lg={4}>
            <Box display='flex' flexDirection='column'>
              {recent &&
                recent.map((item) => {
                  return (
                    <Link
                      href={
                        item.type === 'Blog'
                          ? item.link
                          : `/resources/${item.urlPath}/${item.id}`
                      }
                      underline='none'
                    >
                      <Box
                        mt={2}
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignItems='center'
                        className={classes.recentBox}
                      >
                        <Avatar
                          className={
                            item.type === 'Blog'
                              ? classes.avatarRed
                              : item.type === 'Newsroom'
                              ? classes.avatarBlue
                              : classes.avatarPurple
                          }
                        >
                          {item.type_initials}
                        </Avatar>
                        <Typography
                          align='left'
                          variant='body2'
                          className={classes.recentText}
                        >
                          {item.title}
                        </Typography>
                        <Typography className={classes.icon}>{'>'}</Typography>
                      </Box>
                    </Link>
                  );
                })}
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box display='flex' flexDirection='column'>
              <Link href='/resources' underline='none'>
                <Box
                  padding={5}
                  display='flex'
                  flexDirection='column'
                  className={classes.cards}
                >
                  <Box display='flex' flexDirection='row' alignItems='center'>
                    <Avatar className={classes.avatarYellow}>W</Avatar>
                    <Typography className={classes.yellowText}>
                      Whitepaper
                    </Typography>
                  </Box>

                  <Typography
                    variant='body2'
                    className={classes.cardDescription}
                  >
                    Access in-depth reports by Vivpro
                    <sup className={classes.superscript}>AI</sup> on latest
                    topics of interest for product development
                  </Typography>
                  <Typography align='right'>
                    <ArrowIcon />
                  </Typography>
                </Box>
              </Link>
              <Link href='/resources' underline='none'>
                <Box
                  padding={5}
                  display='flex'
                  flexDirection='column'
                  className={classes.cards}
                >
                  <Box display='flex' flexDirection='row' alignItems='center'>
                    <Avatar className={classes.avatarRed}>B</Avatar>
                    <Typography className={classes.redText}>Blog</Typography>
                  </Box>
                  <Typography
                    variant='body2'
                    className={classes.cardDescription}
                  >
                    Access short stories and lessons using Vivpro
                    <sup className={classes.superscript}>AI</sup> platform to
                    obtain latest insights and opinions
                  </Typography>
                  <Typography align='right'>
                    <ArrowIcon />
                  </Typography>
                </Box>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box display='flex' flexDirection='column'>
              <Link href='/resources' underline='none'>
                <Box
                  padding={5}
                  display='flex'
                  flexDirection='column'
                  className={classes.cards}
                >
                  <Box display='flex' flexDirection='row' alignItems='center'>
                    <Avatar className={classes.avatarPurple}>C</Avatar>
                    <Typography className={classes.purpleText}>
                      Case Studies
                    </Typography>
                  </Box>
                  <Typography
                    variant='body2'
                    className={classes.cardDescription}
                  >
                    Access anonymized case studies on how Vivpro
                    <sup className={classes.superscript}>AI</sup> is helping
                    pharmaceutical and biotech product development
                  </Typography>
                  <Typography align='right'>
                    <ArrowIcon />
                  </Typography>
                </Box>
              </Link>
              <Link href='/resources' underline='none'>
                <Box
                  padding={5}
                  display='flex'
                  flexDirection='column'
                  className={classes.cards}
                >
                  <Box display='flex' flexDirection='row' alignItems='center'>
                    <Avatar className={classes.avatarBlue}>N</Avatar>
                    <Typography className={classes.blueText}>
                      Newsroom
                    </Typography>
                  </Box>
                  <Typography
                    variant='body2'
                    className={classes.cardDescription}
                  >
                    View the latest news and top headlines of Vivpro
                    <sup className={classes.superscript}>AI</sup> and remain in
                    touch with ongoing development
                  </Typography>
                  <Typography align='right'>
                    <ArrowIcon />
                  </Typography>
                </Box>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Resources;
