import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  title: {
    fontWeight: '600',
  },
  box: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
  },
  names: {
    //   width:'10%',
    padding: theme.spacing(2),
  },
  nameField: {
    margin: theme.spacing(1),
  },
}));
