import { React } from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// style
import Styles from '../styles/Description.styles';

const Banner = () => {
  const classes = Styles();

  return (
    <Box className={classes.root}>
      <Container>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          justifyContent='space-evenly'
          className={classes.formContainerTop}
        >
          <Typography align='left' variant='h2' className={classes.heading}>
            About The Instructor
          </Typography>
          <Typography
            align='left'
            variant='body1'
            className={classes.description}
          >
            Dr.Gobburu is Professor with the School of Pharmacy and the School
            of Medicine, University of Maryland, Baltimore, MD, USA. He held
            various positions at the US FDA between 1998 and 2011.He has
            experience with over seeing the review of 1000s of Investigational
            New Drug Applications(INDs), over 250 New Drug and Biological
            Licensing Applications, numerous FDA Guidances and policies
            pertaining to drug approval and labeling. He received numerous FDA
            awards such as the Outstanding Achievement Award and recognized with
            the Senior Biomedical Research Scientist appointment. Dr. Gobburu
            continuous to pioneer numerous precedent-setting regulatory actions.
          </Typography>
          <Typography
            align='left'
            variant='body1'
            className={classes.descriptionBold}
          >
            2-day Course includes detailed case studies with a focus on overall
            approach and not on technical details.
          </Typography>
          <Typography
            align='left'
            variant='body1'
            className={classes.descriptionBold}
          >
            Target audience: Company executives, Regulatory professionals,
            Clinical Development Scientists.
          </Typography>
          <Typography
            align='left'
            variant='body1'
            className={classes.descriptionBold}
          >
            Electronic material will be provide via online training.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Banner;
