import React from 'react';

const css = `
.arr_a{fill:#f39c12;}.arr_b{fill:#7c7d8d;}
`;

const ArrowIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32.429'
      height='17.211'
      viewBox='0 0 32.429 17.211'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <g transform='translate(0 -19.081)'>
        <path class='arr_a' d='M0,22.7Z' />
        <g transform='translate(12.971 15.784)'>
          <path
            class='arr_b'
            d='M.225,8.543,7.682,16l1.086-1.086L2.623,8.768H23.046V7.232H2.623L8.768,1.086,7.682,0,.225,7.457A.767.767,0,0,0,.225,8.543Z'
            transform='translate(19.457 20.508) rotate(180)'
          />
        </g>
      </g>
    </svg>
  );
};

export default ArrowIcon;
