import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

// assets
import Laptop from '../../../assets/svgs/HomePage/Laptop';
import Tick from '../../../assets/svgs/HomePage/Tick';

// styles
import Styles from '../styles/Banner.styles';

const Banner = () => {
  const classes = Styles();

  return (
    <Box display='flex' className={classes.roots}>
      <Container>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          className={classes.textBox}
        >
          <Box className={classes.headingBox}>
            <Typography
              align='center'
              variant='h1'
              className={classes.headingDark}
            >
              Re-Imagining
            </Typography>
            <Typography variant='h1' className={classes.headingLight}>
              Drug Development
            </Typography>
          </Box>
          <Hidden mdDown>
            <Box
              padding={1}
              display='flex'
              justifyContent='flex-space-evenly'
              className={classes.ticker}
            >
              <Tick />
            </Box>
          </Hidden>
          <Typography
            align='center'
            variant='subtitle1'
            className={classes.description}
          >
            Vivpro
            <sup className={classes.superscript}>AI</sup> offers a revolutionary
            biointelligence software platform and innovative services to make
            evidence-based clinical, regulatory, and business decisions at
            lightning speed for pharmaceutical, biotech, device developers,
            CROs, and investors.
          </Typography>
          <Box display='flex' flexDirection='row' className={classes.btnGroup}>
            <Button
              target='_blank'
              rel='noreferrer'
              href='https://www.ria.vivpro.ai/'
              variant='contained'
              color='secondary'
              className={classes.btn}
            >
              Unlock R&D Intelligence
            </Button>
            <Button
              href='/contact'
              variant='contained'
              className={classes.btn1}
            >
              Request Demo
            </Button>
          </Box>
          <Box className={classes.laptop}>
            <Laptop />

            <video
              className={classes.center}
              controls='controls'
              name='Video Name'
              src='https://vivpro-site.s3.amazonaws.com/Vivpro_video.mov'
            ></video>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Banner;
