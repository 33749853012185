import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// styles
import Styles from './styles/Success.styles';

const Success = () => {
  const classes = Styles();

  return (
    <Box className={classes.root}>
      <Container>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography align='center' className={classes.heading} variant='h1'>
            Sorry, Your payment was not completed!
          </Typography>
          <Button
            className={classes.btn}
            color='secondary'
            variant='contained'
            href='/education'
          >
            Try Again
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Success;
