import React, { Fragment, useContext, useEffect } from 'react';

// child components
import Banner from './components/Banner';
import About from './components/About';
import BoardMembers from './components/BoardMembers';
import Steps from './components/Steps';
import Subscription from '../../components/Subscription';
import Jobs from './components/Jobs';

// store
import Store from '../../store';
import Actions from '../../store/actions';

const Company = () => {
  const { dispatch } = useContext(Store);

  useEffect(() => {
    async function getData() {
      try {
        await dispatch({ type: Actions.SET_ACTIVE_PAGE, value: 'Company' });
      } catch (e) {
        // console.log(e);
      }
    }
    getData();
  }, [dispatch]);

  return (
    <Fragment>
      <Banner />
      <About />
      <BoardMembers />
      <Steps />
      <Jobs />
      <Subscription />
    </Fragment>
  );
};

export default Company;
