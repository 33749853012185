import React, { Fragment, useContext, useEffect } from 'react';

// material ui
import Box from '@material-ui/core/Box';

// styles
import Styles from './styles/Banner.styles';

// child components
import Banner from './components/Banner';
import Advantages from './components/Advantages';
import Aria from './components/Aria';
import Subscription from '../../components/Subscription';
import Store from '../../store';
import Actions from '../../store/actions';

const Product = () => {
  const classes = Styles();
  const { dispatch } = useContext(Store);

  useEffect(() => {
    async function getData() {
      try {
        await dispatch({ type: Actions.SET_ACTIVE_PAGE, value: 'Services' });
      } catch (e) {
        // console.log(e);
      }
    }
    getData();
  }, [dispatch]);
  return (
    <Fragment>
      <Box className={classes.parent}>
        <Banner />
        <Advantages />
        <Aria />
        <Subscription />
      </Box>
    </Fragment>
  );
};

export default Product;
