import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    // marginTop: theme.spacing(10),
    background:'#f5f9ff'
  },
  center: {
    borderRadius: theme.spacing(1.5),
    marginTop: theme.spacing(-15),
    width: '50%',
    boxShadow: `0px 7px 29px 0px ${theme.palette.product.shadow}`,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  paper: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
  },
  box: {
    padding: theme.spacing(5),
    width: '70%',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center'
    },

  },
  title: {
    color: theme.palette.home.textBlackDark,
    fontWeight: 'bold'
  },
  subtitle: {
    marginTop: theme.spacing(1),
    color: theme.palette.home.textBlackLight,
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  subtitle1: {
    marginTop: theme.spacing(1),
    color: theme.palette.secondary.main,
    // width:'50%',
  },
  heading: {
    marginTop: theme.spacing(2),
    fontWeight: '600',
    color: theme.palette.home.textBlackDark,
  },
  description: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.product.textBlackLight,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    },
  },
  number: {
    fontSize: theme.typography.h1.fontSize,
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  },
  helperText: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.home.textGray
  }
}))