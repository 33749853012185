import { makeStyles } from '@material-ui/core';
import BgLight1 from '../../../assets/svgs/HomePage/BgLight1.svg';

export default makeStyles(theme => ({
  root: {
    // height:'100vh',
    border: `.1px solid ${theme.palette.secondary.main} `,
    marginTop: theme.spacing(15),
    background: `url(${BgLight1})`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(0),
      background: theme.palette.secondary.main,
    },
  },
  heading: {
    marginTop: theme.spacing(15),
    color: theme.palette.white,
    fontSize: theme.spacing(4),
    fontWeight: '600'
  },
  description: {
    marginTop: theme.spacing(3.5),
    color: theme.palette.white,
    opacity: .8
  },
  avatarYellow: {
    background: theme.palette.home.avatarYellow
  },
  avatarRed: {
    background: theme.palette.home.red
  },
  avatarPurple: {
    background: theme.palette.home.purple
  },
  avatarBlue: {
    background: theme.palette.home.blue
  },
  grid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(20),
  },
  recent: {
    marginTop: theme.spacing(15),
    color: theme.palette.resources.backgroundLight,
    fontWeight: 'medium'
  },
  recentBox: {
    background: theme.palette.secondary.dark,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5)
  },
  recentText: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    display: ' -webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical'
    // fontSize: theme.spacing(2)
  },
  superscript: {
    fontSize: theme.spacing(1.5),
  },
  cards: {

    marginBottom: theme.spacing(5),
    background: theme.palette.white,
    borderRadius: theme.spacing(1),
    boxShadow: `0px 12px 24px ${theme.palette.home.shadow}`
  },
  yellowText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.home.avatarYellow,
    fontSize: theme.spacing(2.5),
    fontWeight: 'bold'
  },
  purpleText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.home.purple,
    fontSize: theme.spacing(2.5),
    fontWeight: 'bold'
  },
  blueText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.home.blue,
    fontSize: theme.spacing(2.5),
    fontWeight: 'bold'
  },
  redText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.home.red,
    fontSize: theme.spacing(2.5),
    fontWeight: 'bold'
  },
  cardDescription: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    lineHeight: theme.spacing(.22),
    color: theme.palette.home.textGray,
    [theme.breakpoints.up('xl')]: {
      width: '100%'
    },
    [theme.breakpoints.down('lg')]: {
      width: '80%'
    },
  },
  icon: {
    color: theme.palette.navbar.links
  }

}))