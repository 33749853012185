import React from 'react';

const css = `
.send_btn_a{fill:#32b189;}.send_btn_b,.send_btn_c{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;}.send_btn_c{stroke-linecap:square;}
`;

const SendBtn = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='73'
      height='60'
      viewBox='0 0 73 60'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <rect class='send_btn_a' width='73' height='60' rx='5' />
      <g transform='translate(28 24)'>
        <path class='send_btn_b' d='M0,.415H18' transform='translate(0 5.4)' />
        <path
          class='send_btn_c'
          d='M0,0,6.3,5.815,0,11.631'
          transform='translate(11.7)'
        />
      </g>
    </svg>
  );
};

export default SendBtn;
