const typography = {
  fontFamily: ['Nunito Sans', 'sans-serif'].join(','),

  h1: {
    fontSize: '4.5vh',
  },
  subtitle1: {
    fontSize: '2.5vh',
    lineHeight: 1.5
  },
  subtitle2: {
    fontSize: '2vh',
  },
  h2: {
    fontSize: '3.4vh',
  },
  h3: {
    fontSize: '3.2vh',
  },
  body1: {
    fontSize: '2.2vh',
  },
  body2: {
    fontSize: '1.8vh',
  },
  btn: {
    fontSize: '2vh'
  }
};

export default typography;