import React, { useContext, useState } from 'react';
import Store from '../../store';
import Actions from '../../store/actions';
import { Box, Button, Dialog, DialogContent, IconButton, Stack, TextField, Typography } from '@mui/material';
import contactStyles from './styles/contact.styles';
import { Close } from '@material-ui/icons';
import { LoadingButton } from '@mui/lab';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';


const ContactPopup = () => {
  const { state, dispatch }: any = useContext(Store);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [formData, setFormData] = useState({
    name: '', email: '', company: '', message: ''
  });
  const [openSuccess, setOpenSuccess] = useState(false);
  const handleClose = () => {
    // @ts-ignore
    dispatch({ type: Actions.TOGGLE_CONTACT_US, value: false });

  };
  const handleFormChange = (event: any) => {
    if (event.target.name === 'email') {
      // do email validation
      const email = event.target.value;
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!emailRegex.test(email)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const Base64 = require('js-base64').Base64;
    const tok = 'developers:>;JQwa[zL!EX+f{?3{fjnK';
    const hash = Base64.encode(tok);
    const Basic = 'Basic ' + hash;

    fetch('https://ria-api-staging.vivpro.ai/v2/email_service/contact', {
      method: 'POST', headers: {
        'Content-type': 'application/json', Authorization: Basic
      }, body: JSON.stringify(formData)
    }).then((res: any) => {
      res.json();
      setFormData({
        name: '', email: '', company: '', message: ''
      });
      handleClose();
      setOpenSuccess(true);
    }).catch((error: any) => {
      console.error('Error:', error);
      setErrorMessage('Something went wrong!');
    });
    setLoading(false);
  };
  return (<><Dialog open={state?.isContactUsOpen} onClose={handleClose}>
    <DialogContent>
      <Stack justifyContent={'center'} alignItems={'center'}
             mb={'24px'}>
        <Typography sx={contactStyles.mainHeader}>
          Let's connect!
        </Typography>
        <svg xmlns="http://www.w3.org/2000/svg" width="68" height="4" viewBox="0 0 68 4" fill="none">
          <path d="M2 2H66" stroke="#BDBDBD" strokeWidth="4" strokeLinecap="round" />
        </svg>
        <IconButton sx={contactStyles.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </Stack>
      <Box>
        <form onSubmit={handleSubmit}>
          <TextField
            color="success"
            sx={contactStyles.textFields}
            value={formData.name}
            name="name"
            onChange={handleFormChange}
            fullWidth
            required
            label="Name"
          />
          <TextField
            required
            color="success"
            sx={contactStyles.textFields}
            value={formData.email}
            name="email"
            onChange={handleFormChange}
            fullWidth
            label="Email"
            error={emailError}
            helperText={emailError ? 'Please enter a valid email address' : ''}
          />
          <TextField
            required
            color="success"
            sx={contactStyles.textFields}
            value={formData.company}
            name="company"
            onChange={handleFormChange}
            fullWidth
            label="Company"
          />
          <TextField
            required
            color="success"
            sx={contactStyles.textFields}
            value={formData.message}
            name="message"
            onChange={handleFormChange}
            fullWidth
            multiline
            rows={5}
            label="Message"
            placeholder="How can we help you?"
          />
          <LoadingButton
            loading={loading}
            sx={contactStyles.button}
            type="submit"
            variant="contained"
          >
            Submit
          </LoadingButton>
        </form>
      </Box>
      <Box>
        <Box sx={contactStyles.emailButton}>
          or email us at <Button variant="text" onClick={() => {
          // @ts-ignore
          window.open('mailto:sales@vivpro.ai');
        }}>sales@vivpro.ai</Button>
        </Box>
      </Box>
    </DialogContent>

  </Dialog>
    {errorMessage && <Snackbar open={true} autoHideDuration={6000} onClose={() => {
      setErrorMessage('');
    }}>
      <Alert severity="error">{errorMessage}</Alert>
    </Snackbar>}
    {openSuccess && <Snackbar open={true} autoHideDuration={6000} onClose={() => {
      setOpenSuccess(false);
    }}>
      <Alert severity="success">Your message has been sent successfully</Alert>
    </Snackbar>}

  </>);

};

export default ContactPopup;