import { makeStyles } from '@material-ui/core';
import ResourceBG from '../../../assets/svgs/Resources/ResourceBG.svg';

export default makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    height: '100%',
    [theme.breakpoints.down('md')]: {
      background: 'none',
    },
  },
  heading: {
    width: '60%',
    padding: theme.spacing(2),
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formContainerTop: {
    background: theme.palette.white,
    boxShadow: '0px 20px 70px #33333314',
    borderRadius: theme.spacing(1.5),
    height: '100%',
    width: '100%',
    // padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0),
    },
  },
  formContainer: {
    padding: theme.spacing(2),
    background: theme.palette.white,
    boxShadow: '0px 20px 70px #33333314',
    borderRadius: theme.spacing(1.5),
    height: '100%',
  },
  description: {
    color: theme.palette.black,
    fontWeight: 600,
    padding: theme.spacing(2),
  },
  descriptionBold: {
    color: theme.palette.black,
    fontWeight: 'bold',
    padding: theme.spacing(2),
    fontSize: theme.spacing(1.7),
  },
  btn: {
    marginTop: theme.spacing(1),
    background: theme.palette.secondary.main,
    color: theme.palette.white,
    textTransform: 'capitalize',
    // width:'50%'
    margin: '16px auto',
    width: 'auto',
  },
  btn1: {
    marginTop: theme.spacing(1),
    background: theme.palette.secondary.main,
    color: theme.palette.white,
    textTransform: 'capitalize',
    // width:'50%'
  },
  overview: {
    display: 'flex',
    width: '100%',
    padding: '1px 16px',
    fontSize: theme.spacing(1.4),
    fontWeight: '600',
  },
  checkoutRoot: {
    // marginTop: theme.spacing(2),
    height: '100vh',
    background: `url(${ResourceBG}) no-repeat`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(0),
      background: theme.palette.secondary.main,
    },
  },
  form: {
    paddingTop: theme.spacing(15),

    width: '100%'
  },
  formBox: {
    margin: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(2),
    background: theme.palette.white,
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  overviewHeading: {
    display: 'flex',
    width: '100%',
    padding: '1px 16px',
    fontSize: theme.spacing(1.4),
    fontWeight: '600',
  },
  overviewDay: {
    margin:theme.spacing(1),
    textAlign: 'center',
    fontSize: theme.spacing(1.4),
    fontWeight: '700',
    color: theme.palette.white,
    background: theme.palette.secondary.main,
    width: '99%',
    borderRadius: theme.spacing(1),
  },
  overviewCase: {
    margin:theme.spacing(1),
    padding: theme.spacing(.3),
    marginTop: theme.spacing(1),
    display: 'flex',
    width: '98%',
    fontSize: theme.spacing(1.6),
    fontWeight: '600',
    background: 'whitesmoke',
  },
  rowBox: {
    width: '100%',
  },
  InputBase: {
    width: '100%',
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    background: theme.palette.contact.blueLight,
    borderRadius: theme.spacing(1.5),
    border: `.1px solid ${theme.palette.home.textGray}`
  },
  InputBase1: {
    width: '100%',
    background: theme.palette.contact.blueLight,
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    border: `.1px solid ${theme.palette.home.textGray}`
  },
  textFieldR: {
    marginTop: theme.spacing(1),
  },
  textFieldName: {
    marginTop: theme.spacing(1),
    display: 'flex',
    width: '100%',
  },
}));
