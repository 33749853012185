import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';


// style
import Styles from '../styles/Content.styles';

const Content = ({ newsroom }) => {
  const classes = Styles();

  return (
    <Box className={classes.root}>
      <Container maxWidth='md'>
        {/* <Typography>{newsroom && newsroom.intro[0].text}</Typography> */}
        {newsroom &&
          newsroom?.intro.map((item) => {
            return (
              <Typography variant='body1' className={classes.intro}>
                {item.text}
              </Typography>
            );
          })}

        {/* content with bullet points */}
        {newsroom &&
          Object.entries(newsroom?.listElements)?.map(([key, value]) => {
            return (
              <>
                <Typography variant='subtitle1' className={classes.heading}>
                  {key}
                </Typography>
                <ul>
                  {value?.map((val) => {
                    return (
                      <li>
                        <Typography variant='body1' className={classes.paraText}>{val.text}</Typography>
                      </li>
                    );
                  })}
                </ul>
              </>
            );
          })}
        {newsroom &&
          Object.entries(newsroom?.about)?.map(([key, value]) => {
            return (
              <>
                <Typography variant='subtitle1' className={classes.heading}>
                  {key}
                </Typography>
                {value?.map((val, index) => {
                  return <Typography key={val+index} variant='body1' className={classes.paraText}>{val.text}</Typography>;
                })}
              </>
            );
          })}
        <Typography variant='subtitle1' className={classes.heading}>
          About Vivpro
        </Typography>
        <Typography variant='body1' className={classes.intro}>
          {newsroom && newsroom.aboutVivpro}
        </Typography>
        <Box display='flex' flexDirection='row'>
          <Typography variant='body1' className={classes.heading}>
            Brand Promise:
          </Typography>
          <Typography variant='body1' className={classes.intro}>
            &nbsp; “More Power To You”
          </Typography>
        </Box>
        <Box display='flex' flexDirection='row'>
          <Typography variant='body1' className={classes.heading}>
            Vision:
          </Typography>
          <Typography variant='body1' className={classes.intro}>
            &nbsp; “Re-imagining Drug Development”
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Content;
