import { Avatar, Container, Stack, Typography } from '@mui/material';
import consultancyStyles from '../consultancy.styles';
import { DiamondIcon, HeadIcon, PortraitIcon, RocketIcon } from '../../../assets/Consultancy/WhyUsIcons';

const WhyUs = () => {
  return (<Stack justifyContent={'center'} mt="102px" sx={{
    background: '#F5F5F5'
  }}>
    <Container>
      <Stack
        sx={{ p: '96px 40px 128px 40px' }}
      >
        <Typography sx={consultancyStyles.whyUsTitle}>Why Choose us?</Typography>
        <Stack direction="row" mt="64px" gap="64px" justifyContent='space-evenly' flexWrap='wrap'>
          <Stack alignItems='center' width='220px'>
            <Avatar sx={consultancyStyles.whyUsIcon}>
              <RocketIcon />
            </Avatar>
            <Typography sx={consultancyStyles.whyUsItemTitle}>Track Record <br/>of Triumph</Typography>
            <Typography sx={consultancyStyles.whyUsItemDescription}>Proven track record of successful drug development and market access</Typography>
          </Stack>
          <Stack alignItems='center' width='220px'>
            <Avatar sx={consultancyStyles.whyUsIcon}>
              <PortraitIcon />
            </Avatar>
            <Typography sx={consultancyStyles.whyUsItemTitle}>Solutions Tailored <br/> for You</Typography>
            <Typography sx={consultancyStyles.whyUsItemDescription}>Custom solutions <br/> designed for  <br/>your
              unique needs</Typography>
          </Stack>
          <Stack alignItems='center' width='220px'>
            <Avatar sx={consultancyStyles.whyUsIcon}>
              <HeadIcon />
            </Avatar>
            <Typography sx={consultancyStyles.whyUsItemTitle}>Expertise at <br/> Your Service</Typography>
            <Typography sx={consultancyStyles.whyUsItemDescription}>A team of experts with a wealth of industry knowledge and experience</Typography>
          </Stack>
          <Stack alignItems='center' width='220px'>
            <Avatar sx={consultancyStyles.whyUsIcon}>
              <DiamondIcon />
            </Avatar>
            <Typography sx={consultancyStyles.whyUsItemTitle}>Committed to <br/>Superior Outcomes</Typography>
            <Typography sx={consultancyStyles.whyUsItemDescription}>Commitment to delivering results that exceed expectations</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  </Stack>);
};
export default WhyUs;