import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  roots: {
    marginTop: theme.spacing(40),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  },
  title: {
    fontSize: theme.spacing(4),
    fontWeight: 'bold',
    color: theme.palette.white
  },
  grid: {
    marginTop: theme.spacing(10),
  },
  card: {
    padding: theme.spacing(4),
    height: '100%',
    // width: '12vw',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
    borderRadius: theme.spacing(1),
    background: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      height: '50vh',
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(0),
      paddingTop: theme.spacing(0),
    },
    [theme.breakpoints.up('xl')]: {
      height: '38vh',
      width: '12vw',
    },
  },
  heading: {
    marginTop: theme.spacing(3.5),
    fontSize: theme.spacing(3),
    lineHeight: theme.spacing(.15),
    fontWeight: 'bold',
    color: theme.palette.white
  },
  heading2: {
    // marginTop: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: theme.spacing(3),
    color: theme.palette.white
  },
  description: {
    marginTop: theme.spacing(3.5),
    color: theme.palette.white,
    opacity: .8
  }
}))