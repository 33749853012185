import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  header: {
    background: theme.palette.resources.greenLightBackground,
  },
  heading: {
    marginTop: theme.spacing(20),
    padding: theme.spacing(1),
    width: '30%',
    borderRadius: theme.spacing(0.5),
    letterSpacing: theme.spacing(0.5),
    background: theme.palette.resources.greenLightBackground,
    color: theme.palette.resources.greenDark,
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  title: {
    marginTop: theme.spacing(2),
    color: theme.palette.home.textGrayDark,
    fontWeight: '600',
    letterSpacing: theme.spacing(0.1),
  },
  subtitle: {
    marginTop: theme.spacing(2),
    color: theme.palette.home.textGray,
    fontWeight: '600',
    letterSpacing: theme.spacing(0.1),
  },
  img: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  snackbar: {
    left: 'auto',
    width: 700,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '&.MuiSnackbar-root': {
      color: '#fff ',
    },
  },
}));
