import React, { useState } from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import EmailIcon from '@material-ui/icons/Email';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

// styles
import Styles from './styles/Subscription.styles';

const Subscription = () => {
  const classes = Styles();
  const [email, setEmail] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    try {
      var Base64 = require('js-base64').Base64;
      const tok = 'developers:>;JQwa[zL!EX+f{?3{fjnK';
      const hash = Base64.encode(tok);
      const Basic = 'Basic ' + hash;
      const payload = {
        name: '',
        company: '',
        message: '',
        email,
      };
      const response = await fetch(
        'https://ria-api-staging.vivpro.ai:8443/v2/clignosis/contactus',
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Authorization: Basic,
          },
          body: JSON.stringify(payload),
        }
      )
        .then((res) => res.json())
        .then((jsonresponse) => jsonresponse);

      if (response.Status === 200) {
        setEmail('');
        setSuccess(true);
      } else {
        setErrorMessage('Something went wrong!');
        setError(true);
      }
    } catch (e) {
      setErrorMessage(e.message);
      setError(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };
  return (
    <Box className={classes.root}>
      <Container>
        {error ? (
          <Snackbar open={error} autoHideDuration={2000} onClose={handleClose}>
            <Alert severity='error'>{errorMessage}</Alert>
          </Snackbar>
        ) : null}
        {success ? (
          <Snackbar
            open={success}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <Alert severity='success'>
              Message has been sent successfully!
            </Alert>
          </Snackbar>
        ) : null}
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          className={classes.box}
        >
          <Typography variant='h1' className={classes.heading}>
            Want to get started?
          </Typography>
          <Typography variant='subtitle1' className={classes.description}>
            If Ready to Make a great Product. We can send you the demo to email.
          </Typography>
          <Box mt={5} display='flex' flexDirection='row'>
            <TextField
              className={classes.textField}
              required
              onChange={(event) => setEmail(event.target.value)}
              id='input-with-icon-textfield'
              placeholder='Add your email'
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.input },
                startAdornment: (
                  <InputAdornment position='start'>
                    <EmailIcon className={classes.emailIcon} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant='contained'
              onClick={handleSubmit}
              color='secondary'
              className={classes.btn}
            >
              Get started
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Subscription;
