import React, { useEffect } from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// api
import { getPaymentConfirmation, addInvoiceToDb } from '../api/Stripe/client';

// styles
import Styles from './styles/Success.styles';

const Success = (props) => {
  const classes = Styles();

  useEffect(() => {
    async function fetchConfig() {
      try {
        const sessionId = props.match.params.sessionId;
        const res = await getPaymentConfirmation(sessionId);

        if (res.data['Success'].payment_status === 'paid') {
          let user = JSON.parse(localStorage.getItem('user'));
          let payload = {
            first_name: user.first_name,
            last_name: user.last_name,
            company: user.company,
            email: user.email,
            total_amount: parseInt(Success.amount_total) / 100,
            discount: parseInt(Success.total_details.amount_discount) / 100,
            payment_id: Success.id,
            payment_email: Success.customer_details.email,
            customer_id: Success.customer,
            status: Success.payment_status,
          };
          await addInvoiceToDb(payload);
        }
      } catch (e) {
        // console.log(e);
      }
    }
    fetchConfig();
  }, [props.match.params.sessionId]);
  return (
    <Box className={classes.root}>
      <Container>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Typography align='center' className={classes.heading} variant='h1'>
            Payment was done successfully!
          </Typography>
          <Button
            className={classes.btn}
            color='secondary'
            variant='contained'
            href='/'
          >
            Go Back
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Success;
