import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    paddingBottom:theme.spacing(5)
  },
  selected: {
    width: '50%',
    cursor: 'pointer',
    padding: theme.spacing(2),
    background: theme.palette.secondary.main,
    borderRadius: theme.spacing(1.5),
    boxShadow: `0px 3px 6px ${theme.palette.resources.shadow}`,
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  selectedText: {
    color: theme.palette.white,
    // fontSize: theme.spacing(2),
    fontWeight: '600'
  },
  unselected: {
    width: '50%',
    cursor: 'pointer',
    padding: theme.spacing(2),
    background: theme.palette.white,
    borderRadius: theme.spacing(1.5),
    boxShadow: `0px 20px 40px ${theme.palette.resources.shadow}`,
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  unselectedText: {
    color: theme.palette.home.textBlackDark,
    fontWeight: '600'
  },
  heading: {
    color: theme.palette.home.textBlackDark,
    fontWeight: 'bold',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(5)
    },
  },
  descriptionBox: {
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: `0px 1px 2px   ${theme.palette.resources.shadow}`,
  },
  description: {
    color: theme.palette.home.textBlackLight,
  }
}))