import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// assets
import Quote from '../../../assets/svgs/Company/Quote';

// style
import Styles from '../styles/About.styles';

const About = () => {
  const classes = Styles();

  return (
    <Box className={classes.root}>
      <Container>
        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          <Box
            px={5}
            display='flex'
            flexDirection='column'
            alignItems='flex-start'
            justifyContent='center'
            className={classes.quoteBox}
          >
            <Quote />
            <Typography
              align='left'
              variant='subtitle2'
              className={classes.quote}
            >
              We are Re-imagining Drug Development with increased PTRS and
              improved ROI through world class products and services
            </Typography>
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          className={classes.descriptionBox}
        >
          <Typography variant='subtitle1' className={classes.description}>
            We deliver power to data-driven pharmaceutical, biotech and medical
            product companies to tackle complex business problems.
          </Typography>
          <Typography variant='subtitle1' className={classes.description}>
            We enable faster, data-driven decisions using good quality data and
            state-of the technology along with unparalleled subject-matter
            expertise to achieve competitive advantage.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default About;
