import React, { Fragment, useState, useEffect, useContext } from 'react';

// data
import NewsroomData from '../../data/Newsroom.json';

// child components
import Header from './components/Header';
import Content from './components/Content';

// context
import Store from '../../store';
import Actions from '../../store/actions';

const Newsroom = ({ match }) => {
  const [newsroom, setNewsroom] = useState();
  const { dispatch } = useContext(Store);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch({ type: Actions.SET_ACTIVE_PAGE, value: 'Company' });
        NewsroomData.forEach((item) => {
          if (item.id === parseInt(match.params.id)) {
            setNewsroom(item);
          }
        });
      } catch (e) {
        // console.log(e);
      }
    };
    fetchData();
  }, [match.params.id, dispatch]);

  return (
    <Fragment>
      <Header
        heading={newsroom && newsroom.title}
        imgUrl={newsroom && newsroom.imgUrl}
        subtitle={newsroom && newsroom.subtitle}
      />
      <Content newsroom={newsroom && newsroom} />
    </Fragment>
  );
};

export default Newsroom;
