import React, { useContext } from 'react';
import { Box, Button, Container, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import consultancyStyles from '../consultancy.styles';
import CheckIcon from '../../../assets/Consultancy/CheckIcon';
import WhyUs from './WhyUs';
import Actions from '../../../store/actions';
import Store from '../../../store';

const Service = () => {
  const { dispatch }: any = useContext(Store);
  const services = [{
    title: 'Clinical Development Strategy',
    images: ['https://d1fziiyze1kdfo.cloudfront.net/website/Section1.jpg', 'https://d1fziiyze1kdfo.cloudfront.net/website/Section2.jpg'],
    list: ['Leverage nonclinical and CMC components to support development plans', 'Design and interpret of Phase I to Phase III clinical trials', 'Data analysis and interpretation to assess safety and efficacy', 'Regulatory strategy and submission planning', 'Identify target indication, dosage form']
  }, {
    title: 'Regulatory Strategy',
    images: ['https://d1fziiyze1kdfo.cloudfront.net/website/Section3.jpg', 'https://d1fziiyze1kdfo.cloudfront.net/website/Section4.jpg'],
    list: ['Assistance in regulatory submissions (Pre-IND, IND, EOP2, NDA, BLA)', 'Pediatric plans, exclusivity, study designs and efficacy extrapolation', 'Individual eCTD module development (2.7.1, 2.7.2, immunogenicity and pediatric summary)', 'Modeling analysis plans', 'Unprecedented regulatory decisions', 'MIDD strategy and execution', 'Project Optimus support', 'IB review and optimization']
  }];
  return (<>
    <Box sx={consultancyStyles.serviceContainer} my={10}>
      <Container sx={{ height: '320px' }}>
        <Stack justifyContent={'center'} alignItems={'center'} height="100%">
          <Typography sx={consultancyStyles.title2}>
            We offer Value-Centric Service
          </Typography>
          <Typography sx={{ ...consultancyStyles.serviceDescription, ...consultancyStyles.whiteTitle }}>
            Service excellence designed around user needs to maximize
          </Typography>
          <Typography sx={{ ...consultancyStyles.serviceDescription, ...consultancyStyles.blueTitle2 }}>
            Probability of Technical and Regulatory Success (PTRS)
          </Typography>
        </Stack>
      </Container>
    </Box>
    <Container sx={{ overflow: 'hidden' }}>
      {services.map((service, index) => (<>
          <Stack sx={consultancyStyles.servicesContainer} justifyContent={'space-between'}>
            <Stack gap={'24px'}>
              <Typography sx={consultancyStyles.serviceTitle}>
                {service.title}
              </Typography>
              <List>
                {service.list.map((item, index) => (<ListItem key={index} sx={consultancyStyles.serviceListItem}>
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary={item} />
                </ListItem>))}
              </List>
            </Stack>
            <Stack direction="row" width={'50%'} height="100%" alignItems={'center'} id={'image-div'}>
              {service.images.map((image, index) => (
                <Box key={`${index}-${service.title}`} sx={consultancyStyles.serviceImage}>
                  <img src={image} alt={service.title} />
                </Box>))}
            </Stack>
          </Stack>
          {index !== services.length - 1 && <Box sx={consultancyStyles.serviceDivider} />}
        </>

      ))}
    </Container>
    <WhyUs />
    <Box sx={consultancyStyles.serviceContainer}>
      <Container sx={{ height: '320px' }}>
        <Stack justifyContent={'center'} alignItems={'center'} height="100%">
          <Typography sx={consultancyStyles.title3} mb={'16px'}>
            Don’t Settle!
          </Typography>
          <Typography sx={{ ...consultancyStyles.serviceDescription2, maxWidth: { laptop: '40%', tablet: '98%' } }}>
            Contact us today to learn how our strategic consulting services can streamline your path to market.
          </Typography>
          <Box mt={'32px'}>
            <Button sx={consultancyStyles.buttonBanner} onClick={() => {
              dispatch({ type: Actions.TOGGLE_CONTACT_US, value: true });
            }}>
              Start a conversation
            </Button>
          </Box>
        </Stack>
      </Container>
    </Box>
  </>);
};
export default Service;