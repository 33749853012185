import React from 'react';

const css = `
.meet_a{fill:none;stroke:#fff;stroke-linecap:square;stroke-miterlimit:10;stroke-width:2px;}
`;

const Meeting = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='46.752'
      height='46.752'
      viewBox='0 0 46.752 46.752'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <g transform='translate(1 1)'>
        <ellipse
          class='meet_a'
          cx='4.475'
          cy='4.475'
          rx='4.475'
          ry='4.475'
          transform='translate(31.326 25.359)'
        />
        <ellipse
          class='meet_a'
          cx='4.475'
          cy='4.475'
          rx='4.475'
          ry='4.475'
          transform='translate(4.475 25.359)'
        />
        <path
          class='meet_a'
          d='M17.9,5.967V4.5a2.983,2.983,0,0,0-1.467-2.572A14.6,14.6,0,0,0,8.95,0a14.5,14.5,0,0,0-7.5,1.921A2.98,2.98,0,0,0,0,4.485V5.967Z'
          transform='translate(13.425 13.425)'
        />
        <path
          class='meet_a'
          d='M17.9,5.967V4.5a2.983,2.983,0,0,0-1.467-2.572A14.6,14.6,0,0,0,8.95,0a14.5,14.5,0,0,0-7.5,1.921A2.98,2.98,0,0,0,0,4.485V5.967Z'
          transform='translate(0 38.785)'
        />
        <path
          class='meet_a'
          d='M17.9,5.967V4.5a2.983,2.983,0,0,0-1.467-2.572A14.6,14.6,0,0,0,8.95,0a14.5,14.5,0,0,0-7.5,1.921A2.98,2.98,0,0,0,0,4.485V5.967Z'
          transform='translate(26.851 38.785)'
        />
        <ellipse
          class='meet_a'
          cx='4.475'
          cy='4.475'
          rx='4.475'
          ry='4.475'
          transform='translate(17.901)'
        />
      </g>
    </svg>
  );
};

export default Meeting;
