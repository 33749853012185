import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';

// style
import Styles from '../styles/CardGrid.styles';

const Cards = ({ content }) => {
  const classes = Styles();
  const avatarStyleMapping = {
    C: classes.caseStudyAvatarPurple,
    N: classes.caseStudyAvatarBlue,
    B: classes.caseStudyAvatarRed,
    W: classes.caseStudyAvatarYellow,
  };
  return (
    <Card className={classes.card} elevation={0}>
      <CardActionArea>
        <CardMedia
          component='img'
          alt='thumbnail'
          // height='140'
          className={classes.cardMedia}
          image={
            content.imgUrl ||
            content.thumbnail ||
            'https://vivpro-site.s3.amazonaws.com/Case_Studies/thumbnail.png'
          }
          title='resource grid'
        />
        <CardContent>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            // justifyContent='space-evenly'
            className={classes.cardContent}
          >
            <Avatar className={avatarStyleMapping[content.type_initials]}>
              {content.type_initials}
            </Avatar>
            <Typography align='center' className={classes.type}>
              {content.type}
            </Typography>
            <Typography
              align='center'
              variant='body2'
              className={classes.title}
            >
              {content.title || content.description}
            </Typography>
            <Typography align='center' className={classes.date}>
              {content.date}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default Cards;
