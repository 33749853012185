import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// style
import Styles from '../styles/Banner.styles';

const Banner = () => {
  const classes = Styles();

  return (
    <Box className={classes.root}>
      <Container>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          className={classes.box}
        >
          <Typography align='center' variant='h1' className={classes.heading}>
            We’d love to hear from you any time
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Banner;
