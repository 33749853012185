import React from 'react';

const css = `
.superior_a{fill:#efefef;}.superior_b{fill:#a4afc1;}.superior_c{fill:#f3f3f1;}.superior_d{fill:#2fdf84;}.superior_e{fill:#d5dbe1;}.superior_f{fill:#00b871;}
`;

const SuperiorStrategy = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='8vh'
      height='8vh'
      viewBox='0 0 82.948 74.821'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <g transform='translate(-0.583 -2.29)'>
        <path
          class='superior_a'
          d='M75,62.616c0-16.472,8.532-27.783,8.532-35.72S78.969,2.29,42.456,2.29.583,24.715.583,33.445C.583,74.325,75,91.787,75,62.616Z'
          transform='translate(0 0)'
        />
        <path
          class='superior_b'
          d='M19.572,12.144a3.572,3.572,0,1,1,3.572-3.572A3.576,3.576,0,0,1,19.572,12.144Zm0-4.763a1.191,1.191,0,1,0,1.191,1.191A1.193,1.193,0,0,0,19.572,7.381Z'
          transform='translate(21.296 3.743)'
        />
        <g transform='translate(15.269 12.911)'>
          <circle
            class='superior_c'
            cx='5.358'
            cy='5.358'
            r='5.358'
            transform='translate(0 38.101)'
          />
          <circle
            class='superior_c'
            cx='5.358'
            cy='5.358'
            r='5.358'
            transform='translate(23.813 19.051)'
          />
          <circle
            class='superior_c'
            cx='5.358'
            cy='5.358'
            r='5.358'
            transform='translate(42.864)'
          />
        </g>
        <circle
          class='superior_d'
          cx='5.358'
          cy='5.358'
          r='5.358'
          transform='translate(17.65 24.817)'
        />
        <circle
          class='superior_d'
          cx='5.358'
          cy='5.358'
          r='5.358'
          transform='translate(58.132 36.724)'
        />
        <circle
          class='superior_d'
          cx='5.358'
          cy='5.358'
          r='5.358'
          transform='translate(36.7 55.774)'
        />
        <path
          class='superior_e'
          d='M12.108,28.108a5.334,5.334,0,0,1,2.679-4.615,5.358,5.358,0,1,0,0,9.23A5.334,5.334,0,0,1,12.108,28.108Z'
          transform='translate(8.519 28.262)'
        />
        <path
          class='superior_e'
          d='M22.108,20.108a5.334,5.334,0,0,1,2.679-4.615,5.358,5.358,0,1,0,0,9.23A5.334,5.334,0,0,1,22.108,20.108Z'
          transform='translate(22.332 17.211)'
        />
        <path
          class='superior_e'
          d='M30.108,12.108a5.334,5.334,0,0,1,2.679-4.615,5.358,5.358,0,1,0,0,9.23A5.334,5.334,0,0,1,30.108,12.108Z'
          transform='translate(33.382 6.161)'
        />
        <path
          class='superior_f'
          d='M13.108,17.108a5.334,5.334,0,0,1,2.679-4.615,5.358,5.358,0,1,0,0,9.23A5.334,5.334,0,0,1,13.108,17.108Z'
          transform='translate(9.9 13.067)'
        />
        <path
          class='superior_f'
          d='M30.108,22.108a5.334,5.334,0,0,1,2.679-4.615,5.358,5.358,0,1,0,0,9.23A5.334,5.334,0,0,1,30.108,22.108Z'
          transform='translate(33.382 19.974)'
        />
        <path
          class='superior_f'
          d='M21.108,30.108a5.334,5.334,0,0,1,2.679-4.615,5.358,5.358,0,1,0,0,9.23A5.334,5.334,0,0,1,21.108,30.108Z'
          transform='translate(20.95 31.024)'
        />
        <path
          d='M13.144,36.288a7.144,7.144,0,1,1,7.144-7.144A7.153,7.153,0,0,1,13.144,36.288Zm0-10.716a3.572,3.572,0,1,0,3.572,3.572A3.576,3.576,0,0,0,13.144,25.572Z'
          transform='translate(7.483 27.226)'
        />
        <path
          d='M23.144,28.288a7.144,7.144,0,1,1,7.144-7.144A7.153,7.153,0,0,1,23.144,28.288Zm0-10.716a3.572,3.572,0,1,0,3.572,3.572A3.576,3.576,0,0,0,23.144,17.572Z'
          transform='translate(21.296 16.175)'
        />
        <path
          d='M31.144,20.288a7.144,7.144,0,1,1,7.144-7.144A7.153,7.153,0,0,1,31.144,20.288Zm0-10.716a3.572,3.572,0,1,0,3.572,3.572A3.576,3.576,0,0,0,31.144,9.572Z'
          transform='translate(32.346 5.125)'
        />
        <path
          d='M0,0H18.6V3.572H0Z'
          transform='matrix(0.781, -0.625, 0.625, 0.781, 24.164, 51.282)'
        />
        <path
          d='M0,0H15.021V3.572H0Z'
          transform='translate(47.383 31.844) rotate(-45)'
        />
        <path
          d='M14.144,25.288a7.144,7.144,0,1,1,7.144-7.144A7.153,7.153,0,0,1,14.144,25.288Zm0-10.716a3.572,3.572,0,1,0,3.572,3.572A3.576,3.576,0,0,0,14.144,14.572Z'
          transform='translate(8.864 12.031)'
        />
        <path
          d='M31.144,30.288a7.144,7.144,0,1,1,7.144-7.144A7.153,7.153,0,0,1,31.144,30.288Zm0-10.716a3.572,3.572,0,1,0,3.572,3.572A3.576,3.576,0,0,0,31.144,19.572Z'
          transform='translate(32.346 18.938)'
        />
        <path
          d='M22.144,38.288a7.144,7.144,0,1,1,7.144-7.144A7.153,7.153,0,0,1,22.144,38.288Zm0-10.716a3.572,3.572,0,1,0,3.572,3.572A3.576,3.576,0,0,0,22.144,27.572Z'
          transform='translate(19.914 29.988)'
        />
        <path
          d='M0,0H3.572V24.442H0Z'
          transform='matrix(0.852, -0.524, 0.524, 0.852, 24.61, 36.208)'
        />
        <path
          d='M0,0H16.764V3.572H0Z'
          transform='translate(45.33 55.873) rotate(-41.595)'
        />
      </g>
    </svg>
  );
};

export default SuperiorStrategy;
