import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(-40),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(5),
    },
  },
  header: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      alignItems: 'center',
    },
  },
  heading: {
    marginTop: theme.spacing(5),
    fontSize: theme.spacing(4),
    color: theme.palette.home.textGrayDark,
    fontWeight: 'bold',
  },
  description: {
    marginTop: theme.spacing(2),
    fontSize: theme.spacing(2.5),
    color: theme.palette.home.textBlackLight,
  },
  cards: {
    padding: theme.spacing(0),
    height: '100%'
  }
}))