import { makeStyles } from '@material-ui/core';
import CardBg from '../../../assets/pngs/Home/CardBg.png';

export default makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(38),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(30)
    },
  },
  heading: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      // marginBottom: theme.spacing(10)
    },
  },
  subHeading: {
    marginTop: theme.spacing(2),
    fontWeight:'600',
    color: theme.palette.home.textBlackDark,
    // fontSize: theme.spacing(2.5)
  },
  textBox: {
    width: '90%'
  },
  description: {
    marginTop: theme.spacing(9),
    color: theme.palette.home.textBlackLight,
  },
  cardsContainer: {
    marginTop: theme.spacing(10),
  },
  card: {
    // background: theme.palette.secondary.main,
    background:`url(${CardBg})`,
    backgroundSize: 'cover',
    padding: theme.spacing(3),
    margin: theme.spacing(3),
    borderRadius: theme.spacing(3)
  },
  cardHeading: {
    marginTop: theme.spacing(2),
    color: theme.palette.white,
    fontSize: theme.spacing(2.5),
    fontWeight: 'bold'
  },
  icon: {
    color: theme.palette.white,
    opacity: .8
  },
  expandedText: {
    fontSize: theme.spacing(2),
    color: theme.palette.white
  }
}))