import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

// assets
import SendBtn from '../../../assets/svgs/Resources/SendBtn';

// style
import Styles from '../styles/Subscription.styles';

const Subscription = () => {
  const classes = Styles();
  const [error, setError] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
  };

  return (
    <Box className={classes.root} zIndex={1}>
      <Container>
        {error ? (
          <Snackbar open={error} autoHideDuration={2000} onClose={handleClose}>
            <Alert severity='info'>This feature will come soon!</Alert>
          </Snackbar>
        ) : null}
        <Box>
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12} lg={6}>
              <Box display='flex' justifyContent='flex-start'>
                <Typography variant='h3' className={classes.text}>
                  Subscribe our newsletter and get useful information every week
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                className={classes.box}
              >
                <Paper component='form' className={classes.paper}>
                  <InputBase
                    className={classes.input}
                    placeholder='Enter your email'
                    inputProps={{ 'aria-label': 'enter email' }}
                  />
                  <Box onClick={() => setError(true)}>
                    <SendBtn />
                  </Box>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Subscription;
