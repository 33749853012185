import { makeStyles } from '@material-ui/core';
import BannerBg from '../../../assets/pngs/Contacts/BannerBg.png';

export default makeStyles(theme => ({
  root: {
    height: '110vh',
    background: `url(${BannerBg}) no-repeat !important`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      background: theme.palette.primary.main,
    },
  },
  box: {
    width: '100%',
    height: '100vh'
  },
  heading: {
    width: '50%',
    // fontSize: theme.spacing(7),
    fontWeight: 'bold',
    color: theme.palette.white,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}))