import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(20),
    height: theme.spacing(25),
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      height: '100%',
    },
  },
  grid: {
    padding: theme.spacing(4),
    background: theme.palette.resources.green,
    borderRadius: theme.spacing(1.5)
  },
  text: {
    padding: theme.spacing(5),
    textJustify: 'center',
    color: theme.palette.white,
    [theme.breakpoints.down('md')]: {
      textJustify: 'flex-end',
      padding: theme.spacing(0),
    },
  },
  box: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(6),
    width: theme.spacing(50),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2)
  },
  btn: {
    background: theme.palette.resources.green
  }
}))