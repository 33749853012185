import React, { useState } from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// other components
import Capabilities from './Capabilities';

// styles
import Styles from '../styles/Aria.styles';

const Aria = () => {
  const classes = Styles();
  const [selectInfo, setSelectInfo] = useState('Needle in a Haystack');

  return (
    <Box
      className={classes.roots}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Box display='flex' flexDirection='column'>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                onClick={() => setSelectInfo('Needle in a Haystack')}
                className={
                  selectInfo === 'Needle in a Haystack'
                    ? classes.selected
                    : classes.unselected
                }
              >
                <Typography
                  className={
                    selectInfo === 'Needle in a Haystack'
                      ? classes.selectedText
                      : classes.unselectedText
                  }
                >
                  Needle in a Haystack
                </Typography>
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                onClick={() => setSelectInfo('360 view of Product Approvals')}
                className={
                  selectInfo === '360 view of Product Approvals'
                    ? classes.selected
                    : classes.unselected
                }
              >
                <Typography
                  className={
                    selectInfo === '360 view of Product Approvals'
                      ? classes.selectedText
                      : classes.unselectedText
                  }
                >
                  360 view of Product Approvals
                </Typography>
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                onClick={() =>
                  setSelectInfo('Specialty Applications on Demand')
                }
                className={
                  selectInfo === 'Specialty Applications on Demand'
                    ? classes.selected
                    : classes.unselected
                }
              >
                <Typography
                  className={
                    selectInfo === 'Specialty Applications on Demand'
                      ? classes.selectedText
                      : classes.unselectedText
                  }
                >
                  Specialty Applications on Demand
                </Typography>
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                onClick={() => setSelectInfo('Connect disparate data sources')}
                className={
                  selectInfo === 'Connect disparate data sources'
                    ? classes.selected
                    : classes.unselected
                }
              >
                <Typography
                  className={
                    selectInfo === 'Connect disparate data sources'
                      ? classes.selectedText
                      : classes.unselectedText
                  }
                >
                  Connect disparate data sources
                </Typography>
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                onClick={() =>
                  setSelectInfo('Witness the Journey of Product Labels')
                }
                className={
                  selectInfo === 'Witness the Journey of Product Labels'
                    ? classes.selected
                    : classes.unselected
                }
              >
                <Typography
                  className={
                    selectInfo === 'Witness the Journey of Product Labels'
                      ? classes.selectedText
                      : classes.unselectedText
                  }
                >
                  Witness the Journey of Product Labels
                </Typography>
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                onClick={() =>
                  setSelectInfo('Pave the way for future products')
                }
                className={
                  selectInfo === 'Pave the way for future products'
                    ? classes.selected
                    : classes.unselected
                }
              >
                <Typography
                  className={
                    selectInfo === 'Pave the way for future products'
                      ? classes.selectedText
                      : classes.unselectedText
                  }
                >
                  Pave the way for future products
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
              className={classes.box}
            >
              {selectInfo === 'Needle in a Haystack' ? (
                <>
                  <Typography className={classes.heading}>
                    ARIA - Your Powerful Assistant
                  </Typography>
                  <Typography className={classes.description}>
                    Ask-RIA (ARIA) is an extension of your team. ARIA is powered
                    by AI search and uses text-analytics based on natural
                    language processing to understand the question. ARIA
                    discovers answers fast and pinpoints insights from complex
                    documents saving valuable time for you by more than 90%.
                  </Typography>
                </>
              ) : null}
              {selectInfo === '360 view of Product Approvals' ? (
                <>
                  <Typography className={classes.heading}>
                    360 view of Product Approvals
                  </Typography>
                  <Typography className={classes.description}>
                    Learn about the Summary Basis of Approvals (SBAs), European Public Assessment Report (EPAR) of a
                    particular product, receive important summaries from
                    submissions including key content reviewed by regulatory
                    agency. Derive precedence from complex documents at
                    lightening speed saving valuable time to focus on strategy.
                  </Typography>
                </>
              ) : null}
              {selectInfo === 'Specialty Applications on Demand' ? (
                <>
                  <Typography className={classes.heading}>
                    Specialty Applications on Demand
                  </Typography>
                  <Typography className={classes.description}>
                    Investigate the landscape of specific areas of interest
                    (biologics, pediatrics, 505b2, oncology etc.) to investigate
                    specific scope and guide drug development strategy. Idenfity
                    niche requirements for those areas and accelerate path to
                    regulatory approval.
                  </Typography>
                </>
              ) : null}
              {selectInfo === 'Connect disparate data sources' ? (
                <>
                  <Typography className={classes.heading}>
                    Connect disparate data sources
                  </Typography>
                  <Typography className={classes.description}>
                    Find connection in disparate sources (drug approval,
                    patents, exclusivity, clinical trials landscape, innovative
                    regulatory pathways etc.) with our proprietary data model to
                    accelerate strategic decisions throughout the drug
                    development lifecycle.
                  </Typography>
                </>
              ) : null}
              {selectInfo === 'Witness the Journey of Product Labels' ? (
                <>
                  <Typography className={classes.heading}>
                    Witness the Journey of Product Labels
                  </Typography>
                  <Typography className={classes.description}>
                    Explore the journey of a product by comparing labels for a
                    given product to understand the progression of labeling or
                    compare labels across products to identify key
                    differentiators between product labels for competitive
                    advantage.
                  </Typography>
                </>
              ) : null}
              {selectInfo === 'Pave the way for future products' ? (
                <>
                  <Typography className={classes.heading}>
                    Pave the way for future products
                  </Typography>
                  <Typography className={classes.description}>
                    Identify development opportunities by mining the disparate,
                    connected sources (drug approval, patents, exclusivity,
                    clinical trials landscape, innovative regulatory pathways
                    etc.) to improve ROI and increase the probability of
                    regualtory and technical success (PTRS)
                  </Typography>
                </>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Capabilities />
    </Box>
  );
};

export default Aria;
