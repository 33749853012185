const contactStyles = {
  mainHeader:{
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%',
    letterSpacing: '-0.528px',
    mb:'10px',
  },
  textFields: {
    my:'12px',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: '8px',
      '& input': {
        fontSize: '16px',
        color: 'black',
      },
    },
  },
  button: {
    borderRadius: '32px',
    background: '#0D917D',
    display: 'flex',
    padding: '8px 48px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    mx: 'auto',
    mt:'32px',
    mb:'24px',
    '&:hover': {
      background: '#0A806D',
    },
  },

  emailButton: {
    textAlign: 'center',
    color: '#000',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    // button
    '& button': {
      color: '#0A806D',
      fontFamily: 'Roboto',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '150%',
      textDecorationLine: 'underline',
      textTransform: 'none',
    }
  },
  closeButton:{
    position: 'absolute',
    right: '5px',
    top: '20px',
    color: '#000',
    '&:hover': {
      color: '#0D917D',
    },
  }
};
export default contactStyles;