import React, { useState, useEffect } from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';

// data
import Jobs from '../../../data/Jobs.json';

// other components
import JobDescription from './JobDescription';

// style
import Styles from '../styles/Jobs.styles';

const Banner = () => {
  const classes = Styles();
  const [open, setOpen] = useState(false);
  const [jobs, setJobs] = useState();
  const [jd, setJd] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let allJobs = [];
        Jobs.forEach((item) => {
          if (item.available) allJobs.push(item);
        });
        setJobs(allJobs);
      } catch (e) {
        // console.log(e);
      }
    };
    fetchData();
  }, []);

  return (
    <Box className={classes.root}>
      <Container>
        <Typography align='center' variant='h1' className={classes.heading}>
          View Openings
        </Typography>
        <Grid container spacing={0} className={classes.grid}>
          {jobs &&
            jobs.map((item) => {
              return (
                <Grid key={item.id} item xs={12} lg={4}>
                  <Box padding={2}>
                    <Card
                      className={classes.card}
                      elevation={0}
                      onClick={() => {
                        setJd(item);
                        setOpen(true);
                      }}
                    >
                      <CardActionArea>
                        <CardMedia
                          component='img'
                          alt='Jobs thumbnail'
                          className={classes.cardMedia}
                          image={item.thumbnail}
                          title='Jobs'
                        />
                        <CardContent>
                          <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                            // justifyContent='space-evenly'
                            className={classes.cardContent}
                          >
                            <Avatar className={classes.caseStudyAvatarPurple}>
                              J
                            </Avatar>
                            <Typography align='center' className={classes.type}>
                              {item.type}
                            </Typography>
                            <Typography
                              align='center'
                              variant='body2'
                              className={classes.title}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              align='center'
                              variant='body2'
                              className={classes.date}
                            >
                              {item.position}
                            </Typography>
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Box>
                </Grid>
              );
            })}
        </Grid>
        <Dialog
          onClose={() => setOpen(false)}
          aria-labelledby='customized-dialog-title'
          open={open}
          fullWidth={true}
          maxWidth='lg'
          className={classes.dialog}
        >
          {/* <Box
            className={classes.close}
            display="flex"
            onClick={() => setOpen(false)}
            justifyContent="flex-end"
          >
            <CloseIcon />
          </Box> */}
          <JobDescription jobDetails={jd && jd} />
        </Dialog>
      </Container>
    </Box>
  );
};

export default Banner;
