import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

// style
import Styles from '../styles/Header.styles';

const Header = ({ heading, imgUrl, videoLink }) => {
  const classes = Styles();

  return (
    <Box className={classes.root}>
      <Container maxWidth='md'>
        <Box display='flex' justifyContent='space-between'>
          <Typography
            align='center'
            variant='subtitle1'
            className={classes.heading}
          >
            Webinar
          </Typography>
        </Box>
        <Typography variant='h1' className={classes.title}>
          {heading}{' '}
          {videoLink && (
            <Button
              target='_blank'
              href={videoLink}
              variant='contained'
              // color='primary'
              className={classes.btnText}
            >
              <PlayCircleOutlineIcon
                fontSize='small'
                className={classes.videoIcon}
              />{' '}
              Watch now
            </Button>
          )}
        </Typography>
        <img src={imgUrl} alt='thumbnail' className={classes.img}></img>
      </Container>
    </Box>
  );
};

export default Header;
