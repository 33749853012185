import React, { useState } from 'react';

// material ui
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// material icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

// styles
import Styles from '../styles/Advantages.styles';

//assets
import FasterDecision from '../../../assets/svgs/HomePage/FasterDecision';
import SmarterTechnology from '../../../assets/svgs/HomePage/SmarterTechnology';
import SuperiorStrategy from '../../../assets/svgs/HomePage/SuperiorStrategy';
import BetterQuality from '../../../assets/svgs/HomePage/BetterQuality';
import Clinical from '../../../assets/svgs/HomePage/Clinical';
import Regulatory from '../../../assets/svgs/HomePage/Regulatory';
import Strategy from '../../../assets/svgs/HomePage/Strategy';
import Investment from '../../../assets/svgs/HomePage/Investment';
import Tick from '../../../assets/svgs/HomePage/Tick';

const Advantages = () => {
  const classes = Styles();
  const [clinical, setClinical] = useState(false);
  const [strategy, setStrategy] = useState(false);
  const [regulatory, setRegulatory] = useState(false);
  const [investment, setInvestment] = useState(false);

  return (
    <Container>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        className={classes.root}
      >
        <Box mb={10} display='flex' flexDirection='column' alignItems='center'>
          <Typography variant='h1' className={classes.heading}>
            More Power To You.
          </Typography>
          <Box ml={-7} className={classes.tick}>
            <Tick />
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <FasterDecision />
              <Typography align='center' variant='subtitle1' className={classes.subHeading}>
                Faster Decisions
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <BetterQuality />
              <Typography align='center' variant='subtitle1' className={classes.subHeading}>
                Better Quality
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <SmarterTechnology />
              <Typography align='center' variant='subtitle1' className={classes.subHeading}>
                Smarter Technology
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <SuperiorStrategy />
              <Typography align='center' variant='subtitle1' className={classes.subHeading}>
                Superior Strategy
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box display='flex' className={classes.textBox}>
          <Typography align='center' variant='body1' className={classes.description}>
            Accelerate your decisions across the enterprise converting
            information to knowledge with our platform. We are the Knowledge
            Layer on your data assets alongside our proprietary curated data for
            all critical business decisions across the value chain of
            Pharmaceutical and Medical product development and
            commercialization.
          </Typography>
        </Box>
        <Grid container spacing={2} className={classes.cardsContainer}>
          <Grid item xs={12} lg={3}>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              className={classes.card}
              onMouseLeave={() => setClinical(false)}
            >
              <Clinical />
              <Typography align='center' className={classes.cardHeading}>
                Clinical
              </Typography>
              <Typography onMouseOver={() => setClinical(true)}>
                {clinical ? (
                  <ExpandLessIcon className={classes.icon} />
                ) : (
                  <ExpandMoreIcon className={classes.icon} />
                )}
              </Typography>
              {clinical ? (
                <Typography align='center' className={classes.expandedText}>
                  Clinical sciences platform for product developers and CROs.
                </Typography>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              className={classes.card}
              onMouseLeave={() => setRegulatory(false)}
            >
              <Regulatory />
              <Typography align='center' className={classes.cardHeading}>
                Regulatory
              </Typography>
              <Typography onMouseOver={() => setRegulatory(true)}>
                {regulatory ? (
                  <ExpandLessIcon className={classes.icon} />
                ) : (
                  <ExpandMoreIcon className={classes.icon} />
                )}
              </Typography>
              {regulatory ? (
                <Typography align='center' className={classes.expandedText}>
                  Regulatory Intelligence platform for product developers,
                  investors and CROs.
                </Typography>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              className={classes.card}
              onMouseLeave={() => setStrategy(false)}
            >
              <Strategy />
              <Typography align='center' className={classes.cardHeading}>
                Strategy
              </Typography>
              <Typography onMouseOver={() => setStrategy(true)}>
                {strategy ? (
                  <ExpandLessIcon className={classes.icon} />
                ) : (
                  <ExpandMoreIcon className={classes.icon} />
                )}
              </Typography>
              {strategy ? (
                <Typography align='center' className={classes.expandedText}>
                  Competitive Intelligence and decision platform for strategy
                  groups.
                </Typography>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              className={classes.card}
              onMouseLeave={() => setInvestment(false)}
            >
              <Investment />
              <Typography align='center' className={classes.cardHeading}>
                Investment
              </Typography>
              <Typography onMouseOver={() => setInvestment(true)}>
                {investment ? (
                  <ExpandLessIcon className={classes.icon} />
                ) : (
                  <ExpandMoreIcon className={classes.icon} />
                )}
              </Typography>
              {investment ? (
                <Typography align='center' className={classes.expandedText}>
                  Competitive Intelligence and decision platform for investors.
                </Typography>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Advantages;
