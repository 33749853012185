import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// assets
import Architecture from '../../../assets/svgs/Product/Architecture';
import Contact from '../../../assets/svgs/Product/Contact';
import Meeting from '../../../assets/svgs/Product/Meeting';
import TechnologyEnabled from '../../../assets/svgs/Product/TechnologyEnabled';

// styles
import Styles from '../styles/Capabilities.styles';

const Aria = () => {
  const classes = Styles();

  return (
    <Box className={classes.roots}>
      <Container>
        <Typography align='center' className={classes.title}>
          Capabilities
        </Typography>
        <Grid container spacing={2} className={classes.grid}>
          <Grid item xs={12} lg={3}>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='flex-start'
              className={classes.card}
            >
              <TechnologyEnabled />
              <Typography className={classes.heading}>Technology</Typography>
              <Typography className={classes.heading2}>Enabled</Typography>
              <Typography variant='subtitle2' className={classes.description}>
                Platform utilizes state-of-the-art technology to build
                knowledge-base converting information to knowledge.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='flex-start'
              className={classes.card}
            >
              <Architecture />
              <Typography className={classes.heading}>Intelligent</Typography>
              <Typography className={classes.heading2}>
                Data Architecture
              </Typography>
              <Typography variant='subtitle2' className={classes.description}>
                Platform delivers graphs, spreadsheets, summaries that are ready
                for business use and strategy discussions.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='flex-start'
              className={classes.card}
            >
              <Contact />
              <Typography className={classes.heading}>
                Business Ready
              </Typography>
              <Typography className={classes.heading2}>Reports</Typography>
              <Typography variant='subtitle2' className={classes.description}>
                Platform removes barriers such as data silos, inertia due to
                avoidable data gaps and information asymmetry.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='flex-start'
              className={classes.card}
            >
              <Meeting />
              <Typography className={classes.heading}>People</Typography>
              <Typography className={classes.heading2}>Powered</Typography>
              <Typography variant='subtitle2' className={classes.description}>
                Platform uses Centaur approach (Human + Machine) and built by
                drug development experts.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Aria;
