import React from 'react';

const css = `
.arc_a{fill:none;stroke:#fff;stroke-linecap:square;stroke-miterlimit:10;stroke-width:2px;}
`;

const Architecture = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='44.393'
      height='44.394'
      viewBox='0 0 44.393 44.394'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <g transform='translate(1 1.414)'>
        <rect
          class='arc_a'
          width='15.663'
          height='15.663'
          transform='translate(0 1.703)'
        />
        <rect
          class='arc_a'
          width='13.484'
          height='13.484'
          transform='translate(32.445 0) rotate(45)'
        />
        <rect
          class='arc_a'
          width='15.663'
          height='15.663'
          transform='translate(24.613 26.316)'
        />
        <rect
          class='arc_a'
          width='15.663'
          height='15.663'
          transform='translate(0 26.316)'
        />
      </g>
    </svg>
  );
};

export default Architecture;
