import React, { useContext } from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// context
import Store from '../../../store';
import Actions from '../../../store/actions';

// style
import Styles from '../styles/Banner.styles';

const Banner = () => {
  const classes = Styles();
  const categories = ['All', 'Case Studies', 'Blogs', 'Newsroom', 'Webinars'];

  const { state, dispatch } = useContext(Store);

  const handleSelect = async (event, category) => {
    event.preventDefault();

    await dispatch({ type: Actions.SET_RESOURCE_CATEGORY, value: category });
  };
  return (
    <Box className={classes.root}>
      <Container>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          className={classes.box}
        >
          <Typography variant='h1' align='center' className={classes.heading}>
            Read our news to help you growth anytime.
          </Typography>
          <Grid container xs={12} lg={12} className={classes.tabs}>
            {/* <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-evenly'
              alignItems='center'
              className={classes.tabs}
            > */}
            {categories.map((item) => {
              return (
                <Grid item xs={4} lg={2}>
                  <Box display='flex' justifyContent='flex-end'>
                    <Button
                      className={
                        state.resourceCategory === item
                          ? classes.btn
                          : classes.unselectedbtn
                      }
                      onClick={(event) => handleSelect(event, item)}
                    >
                      <Typography
                        className={
                          state.resourceCategory === item
                            ? classes.btnText
                            : classes.unselectedtext
                        }
                      >
                        {item}
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
              );
            })}
            {/* </Box> */}
          </Grid>

        </Box>
        {/* </Grid> */}
      </Container>
    </Box>
  );
};

export default Banner;
