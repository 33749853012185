import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// assets
import Competition from '../../../assets/svgs/CaseStudies/Competition';
import Ria from '../../../assets/svgs/CaseStudies/Ria';
import Manual from '../../../assets/svgs/CaseStudies/Manual';

// style
import Styles from '../styles/WhyVivpro.styles';

const WhyVivpro = () => {
  const classes = Styles();
  return (
    <Box className={classes.root}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Box display='flex' flexDirection='column'>
              <Box className={classes.header}>
                <Typography variant='h2' className={classes.heading}>
                  Why Vivpro?
                </Typography>
                <Typography className={classes.description}>
                  RIA saves time and delivers intelligence at high quality
                  EQUALS TO more time for strategy
                </Typography>
              </Box>
              <Box display='flex' flexDirection='column' alignItems='flex-end'>
                <Competition />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box>
              <Ria />
              <Manual />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default WhyVivpro;
