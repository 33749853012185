import { Box, Container, Grow, MobileStepper, Slide, Stack, Typography, useMediaQuery } from '@mui/material';
import consultancyStyles from '../consultancy.styles';
import React, { useState } from 'react';

import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { IconButton } from '@material-ui/core';
import themev5 from '../../../themev5';

const ExpertCard = ({ expert, hoverTitle, setHoverTitle }: any) => {
  return <Grow in={true} unmountOnExit={true}><Stack
    sx={{
      flex: ' 0 0 auto', '&:hover': {
        cursor: 'pointer'
      }
    }}
    alignItems={'center'}
    width="300px"
    position={'relative'}
    overflow={'hidden'}
    onMouseEnter={() => setHoverTitle(expert.name)}
    onMouseLeave={() => setHoverTitle('')}
  >
    <Stack sx={consultancyStyles.imageContainer}>
      <img alt={expert.name} src={expert.image} />
    </Stack>
    <Typography sx={consultancyStyles.expertName}>
      {expert.name}
    </Typography>
    <Typography sx={consultancyStyles.expertPosition}>
      {expert.title}
    </Typography>
    <Slide
      in={hoverTitle === expert.name}
      timeout={{ enter: 500, exit: 500 }}
      direction={'up'}
    >
      <Box
        sx={{
          position: 'absolute',
          height: '100%',
          background: 'rgba(22,71,129,0.8)',
          backdropFilter: 'blur(20px)',
          p: 4,
          borderRadius: '8px',
          overflow: 'auto', ...consultancyStyles.expertDescription
        }}>
        {expert.description}
        <Box height={60} width={'100%'} />
      </Box>
    </Slide>

  </Stack></Grow>;

};
const Experts = () => {
  const experts = [{
    name: 'Dr. Joga Gobburu',
    title: 'Regulatory Strategist',
    description: 'Dr. Joga Gobburu, a Drug Development and Regulatory Strategist, is a Professor at the University of Maryland. Before that, his extensive career includes significant roles at the US FDA from 1999 to 2011. His FDA tenure included overseeing thousands of INDs and over 250 licensing applications, forming the Division of Pharmacometrics. Joga\'s work has earned him numerous awards, including from the American Conference on Pharmacometrics and the American Society of Clinical Pharmacology. He has published over 120 papers and chapters.',
    image: 'https://d1fziiyze1kdfo.cloudfront.net/website/Joga_Gobburu.png'
  }, {
    name: 'Dr. Joan Korth-Bradley',
    title: 'Distinguished Scientist',
    description: 'Joan Korth-Bradley, PharmD, PhD, FCP, FAAPS, a Distinguished Scientist at Vivpro Corp. with over 30 years in drug development, specializing in clinical pharmacology. She has developed medicines in oncology, cardiology, anti-infectives, rheumatology, and rare diseases, working with small molecules, therapeutic proteins, and antibody-drug conjugates. Joan has published over 90 papers and serves on the editorial boards of key pharmacology journals. Her focus at Vivpro AI is using AI to address unmet medical needs, especially in underserved populations. Joan earned her PhD from the University of Texas at Austin and her PharmD from the University of Minnesota, Twin Cities.',
    image: 'https://d1fziiyze1kdfo.cloudfront.net/website/Joan_Korth_Bradley.png'
  }, {
    name: 'Dr. Pravin Jadhav',
    title: 'Drug Development Strategist',
    description: 'Dr. Pravin Jadhav, CEO of Vivpro Corp and expert in R&D Intelligence, has 19 years of experience in drug development and regulatory science, holding pivotal roles at the FDA, Merck, and Otsuka. He led significant projects involving over 100 NDAs and 150 INDs. Dr. Jadhav holds a PhD in Pharmaceutical Sciences from VCU and an MPH from Johns Hopkins University. His expertise ensures innovative, science-driven solutions for clinical and economic utility in pharmaceutical development.',
    image: 'https://d1fziiyze1kdfo.cloudfront.net/website/Pravin_Jadhav.png'
  }];
  const [hoverTitle, setHoverTitle] = React.useState('');
  const [currentExpert, setCurrentExpert] = useState(0);
  const divRef = React.useRef<HTMLDivElement>(null);
  const isHidden = useMediaQuery(themev5.breakpoints.down('laptop'));
  const scrollLeft = () => {
    if (divRef.current && currentExpert > 0) {
      // @ts-ignore
      divRef.current.scrollLeft -= 300;
      setCurrentExpert(currentExpert - 1);
    }
  };
  const scrollRight = () => {
    if (divRef.current && currentExpert < experts.length - 1) {
      // @ts-ignore
      divRef.current.scrollLeft += 300;
      setCurrentExpert(currentExpert + 1);
    }
  };


  return <Container sx={{
    my: 10
  }}>
    <Stack sx={consultancyStyles.expertContainer}>
      <Typography sx={consultancyStyles.expertTitle}>
        Our Experts
      </Typography>
      <Typography sx={consultancyStyles.expertSubTitle}>
        With over 80 years of collective experience, our team of experts specializes in navigating the complexities of
        drug development. We provide tailored strategies that align with your business goals.
      </Typography>
    </Stack>
    <Stack
      direction="row"
      justifyContent="space-around"
      gap="96px"
      width={'100%'}
      mt="48px"
      ref={divRef}
      sx={{
        overflowX: 'auto !important', position: 'relative'
      }}>
      {isHidden ? <ExpertCard expert={experts[currentExpert]} hoverTitle={hoverTitle}
                              setHoverTitle={setHoverTitle} /> : <>
        {experts.map((expert, index) => (<ExpertCard
          expert={expert} key={index} hoverTitle={hoverTitle}
          setHoverTitle={setHoverTitle} />))}
      </>}
    </Stack>
    {isHidden && <MobileStepper
      steps={experts.length}
      position="static"
      variant="text"
      activeStep={currentExpert}
      backButton={<IconButton disabled={currentExpert === 0} onClick={scrollLeft}>
        <ArrowBackIos />
      </IconButton>}
      nextButton={<IconButton disabled={currentExpert >= experts.length - 1} onClick={scrollRight}>
        <ArrowForwardIos />
      </IconButton>}
    />}

  </Container>;
};

export default Experts;