import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(5),
  },
  intro: {
    marginTop: theme.spacing(5),
    color: theme.palette.product.textBlackLight,
    letterSpacing: theme.spacing(.2),
  },
  paraText: {
    color: theme.palette.product.textBlackLight,
    letterSpacing: theme.spacing(.2),
  },
  heading: {
    marginTop: theme.spacing(5),
    color: theme.palette.home.textBlackDark,
    letterSpacing: theme.spacing(.2),
    fontWeight: '600'
  }
}))