import React from 'react';

const css = `
.quote_a{fill:#244adc;opacity:0.196;}
`;

const Quote = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='31'
      height='31'
      viewBox='0 0 61 51'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <path
        className='quote_a'
        d='M35,51V33.874A45.7,45.7,0,0,1,38.343,16.09q3.343-8,11.328-16.09l9.472,7.528a54.908,54.908,0,0,0-6.871,9.033,25.238,25.238,0,0,0-3.157,8.469H61V51ZM0,51V33.874A45.7,45.7,0,0,1,3.343,16.09Q6.685,8.093,14.672,0l9.471,7.528a54.908,54.908,0,0,0-6.872,9.033,25.217,25.217,0,0,0-3.156,8.469H26V51Z'
      />
    </svg>
  );
};

export default Quote;
