import React from 'react';

const css = `
@import url('https://fonts.googleapis.com/css2?family=Nunito Sans:wght@800&display=swap');
.a{fill:#fff;}.b{clip-path:url(#e);}.c{fill:none;}.d,.e{fill:#06c66c;}.e{font-size:14px;font-family:Nunito Sans;font-weight:600;}.f{fill:#eec309;}.g{fill:#333;font-size:22px;}.g,.j{font-family:Nunito Sans;font-weight:700;}.h{font-size:18px;font-family:Nunito Sans;font-weight:400;}.i{fill:#264eee;}.j{fill:#f5f9ff;font-size:36px;}.k{filter:url(#f);}.l{filter:url(#c);}.m{filter:url(#a);}
`;

const Competition = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlink='http://www.w3.org/1999/xlink'
      width='100%'
      height='100%'
      viewBox='0 0 570.799 490.172'
    >
      <defs>
        <style>{css} </style>
        <filter
          id='a'
          x='0'
          y='-30'
          width='570.799'
          height='520.172'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dy='30' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='20' result='b' />
          <feFlood flood-color='#333' flood-opacity='0.059' />
          <feComposite operator='in' in2='b' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter
          id='c'
          x='0'
          y='42.198'
          width='570.799'
          height='447.974'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dy='30' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='20' result='d' />
          <feFlood flood-color='#333' flood-opacity='0.059' />
          <feComposite operator='in' in2='d' />
          <feComposite in='SourceGraphic' />
        </filter>
        <clipPath id='e'>
          <g class='m' transform='matrix(1, 0, 0, 1, 0, 0)'>
            <rect class='a' width='450.799' height='400.172' rx='4' />
          </g>
        </clipPath>
        <filter
          id='f'
          x='0'
          y='35.155'
          width='570.799'
          height='205.405'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dy='30' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='20' result='g' />
          <feFlood flood-color='#333' flood-opacity='0.059' />
          <feComposite operator='in' in2='g' />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>
      <g transform='translate(-9901 -1008.5)'>
        <g transform='translate(9961 1008.5)'>
          <g class='l' transform='matrix(1, 0, 0, 1, -60, 0)'>
            <rect
              class='a'
              width='450.799'
              height='327.974'
              rx='10'
              transform='translate(60 72.2)'
            />
          </g>
          <g class='b'>
            <g transform='translate(-443.755 678.719)'>
              <g transform='translate(0 -11)'>
                <g transform='translate(81.003 4.5)'>
                  <rect class='c' width='21.131' transform='translate(0 6.5)' />
                  <path
                    class='d'
                    d='M7.3,0h0Z'
                    transform='translate(3.522 0)'
                  />
                </g>
                <text class='e' transform='translate(0 14)'>
                  <tspan x='0' y='0'>
                    Learn More
                  </tspan>
                </text>
              </g>
            </g>
          </g>
          <g class='k' transform='matrix(1, 0, 0, 1, -60, 0)'>
            <path
              class='f'
              d='M10,0H440.8a10,10,0,0,1,10,10V85.405a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z'
              transform='translate(60 65.15)'
            />
          </g>
        </g>
        <g transform='translate(10029.677 1196.172)'>
          <text class='g' transform='translate(83.415 4.136)'>
            <tspan x='0' y='21'>
              Less Time Efficient
            </tspan>
            <tspan class='h'>
              <tspan x='0' y='49'>
                Result in 1 day
              </tspan>
            </tspan>
          </text>
          <text class='g' transform='translate(83.415 104.468)'>
            <tspan x='0' y='21'>
              Normal Quality Result
            </tspan>
            <tspan class='h'>
              <tspan x='0' y='49'>
                Machine Assisted
              </tspan>
            </tspan>
          </text>
          <g transform='translate(0 0)'>
            <path
              class='a'
              d='M100.077,126.562a30.124,30.124,0,1,1,21.267-8.823,30,30,0,0,1-21.267,8.823Z'
              transform='translate(-69.987 -66.383)'
            />
            <path
              class='i'
              d='M151.3,122.825a24.873,24.873,0,1,1-7.285-17.588A24.8,24.8,0,0,1,151.3,122.825Z'
              transform='translate(-96.338 -92.735)'
            />
            <g transform='translate(26.965 16.174)'>
              <path
                class='d'
                d='M238.613,234.02a2.718,2.718,0,1,1-.8-1.922A2.709,2.709,0,0,1,238.613,234.02Z'
                transform='translate(-233.177 -220.225)'
              />
              <path
                class='d'
                d='M245.153,171.4a34.79,34.79,0,0,1-.323,5.045c-.2,1.291-.476,2.09-.781,2.09s-.581-.8-.781-2.09a34.777,34.777,0,0,1-.323-5.045,24.494,24.494,0,0,1,.552-5.045,19.623,19.623,0,0,1,.552-2.09,19.561,19.561,0,0,1,.552,2.09A24.5,24.5,0,0,1,245.153,171.4Z'
                transform='translate(-241.331 -164.266)'
              />
              <path
                class='d'
                d='M255.069,203a22.422,22.422,0,0,1-3,2.729c-.853.618-1.492.9-1.714.688s.013-.864.575-1.755a22.414,22.414,0,0,1,2.527-3.171,15.912,15.912,0,0,1,3.166-2.573,13.324,13.324,0,0,1,1.547-.845,13.294,13.294,0,0,1-.741,1.6A15.916,15.916,0,0,1,255.069,203Z'
                transform='translate(-247.448 -192.484)'
              />
            </g>
            <g transform='translate(10.659 10.809)'>
              <path
                class='a'
                d='M245.628,132.945a1.146,1.146,0,1,1-.336-.811A1.144,1.144,0,0,1,245.628,132.945Z'
                transform='translate(-225.351 -131.798)'
              />
              <path
                class='a'
                d='M245.628,350.625a1.146,1.146,0,1,1-.336-.811A1.144,1.144,0,0,1,245.628,350.625Z'
                transform='translate(-225.351 -313.509)'
              />
              <path
                class='a'
                d='M135.642,240.638a1.146,1.146,0,1,1-.811.336A1.144,1.144,0,0,1,135.642,240.638Z'
                transform='translate(-134.495 -222.654)'
              />
              <path
                class='a'
                d='M353.322,240.638a1.146,1.146,0,1,1-.811.336A1.143,1.143,0,0,1,353.322,240.638Z'
                transform='translate(-316.206 -222.654)'
              />
              <g transform='translate(26.719 27.193)'>
                <path
                  class='f'
                  d='M0,10.4A10.4,10.4,0,1,1,10.4,20.8,10.4,10.4,0,0,1,0,10.4Z'
                />
                <path
                  class='a'
                  d='M117.069,206.286H105.715a.3.3,0,0,0-.3.3v2.9a.3.3,0,0,0,.3.3h11.354a.3.3,0,0,0,.3-.3v-2.9A.3.3,0,0,0,117.069,206.286Z'
                  transform='translate(-100.995 -197.638)'
                />
              </g>
            </g>
          </g>
          <path
            class='f'
            d='M48.079,22.385a3.525,3.525,0,0,0-1.954-6.012l-11.9-1.729a1.554,1.554,0,0,1-1.171-.851L27.734,3.011a3.525,3.525,0,0,0-6.321,0L16.092,13.794a1.556,1.556,0,0,1-1.172.851L3.02,16.374a3.525,3.525,0,0,0-1.954,6.012l8.61,8.392a1.558,1.558,0,0,1,.449,1.378L8.093,44.007a3.451,3.451,0,0,0,.769,2.858,3.561,3.561,0,0,0,4.345.857l10.642-5.6a1.593,1.593,0,0,1,1.449,0l10.643,5.6a3.488,3.488,0,0,0,1.639.41,3.534,3.534,0,0,0,2.7-1.268,3.449,3.449,0,0,0,.769-2.858L39.02,32.156a1.557,1.557,0,0,1,.449-1.378Z'
            transform='translate(7.925 106.943)'
          />
        </g>
        <text class='j' transform='translate(10186.399 1130.836)'>
          <tspan x='-103.438' y='0'>
            Competition
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default Competition;
