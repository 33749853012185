import { makeStyles } from '@material-ui/core';
import WaveBg from '../../assets/pngs/Common/WaveBg.png';

export default makeStyles(theme => ({
  root: {
    height: '100vh',
    background: `url(${WaveBg})`,
    [theme.breakpoints.down('md')]: {
      // marginTop:theme.spacing(5),
      background: theme.palette.subscription.blue,
    },
  },
  box: {
    height: '100vh',
    paddingTop: theme.spacing(30),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(0),
    },
  },
  heading: {
    color: theme.palette.white,
    // fontSize: theme.spacing(7),
    fontWeight: 'bold'
  },
  description: {
    marginTop: theme.spacing(2),
    color: theme.palette.white,
    // fontSize: theme.spacing(3),
    fontWeight: 'light'
  },
  textField: {
    padding: theme.spacing(1),
    background: theme.palette.subscription.blueDark
  },
  input: {
    color: theme.palette.subscription.white
  },
  btn: {
    margin: theme.spacing(.1),
    textTransform: 'capitalize',
    color: theme.palette.white
  },
  emailIcon: {
    color: theme.palette.subscription.white
  }
}))