import React, { Fragment } from 'react';

import Description from './components/Description';
import Heading from './components/Heading';
import Overview from './components/Overview';

const Education = () => {
  return (
    <Fragment>
      <Heading />
      <Description />
      <Overview />
    </Fragment>
  );
};

export default Education;
