import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// style
import Styles from '../styles/Heading.styles';

const Banner = () => {
  const classes = Styles();

  return (
    <Box className={classes.root}>
      <Container>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          className={classes.box}
        >
          <Typography align='center' variant='h1' className={classes.heading}>
            Setting The
          </Typography>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='center'
            className={classes.highlighted}
          >
            <Typography
              align='center'
              variant='h1'
              className={classes.heading1}
            >
              Drug Development
            </Typography>
            <Typography
              align='center'
              variant='h1'
              className={classes.heading3}
            >
              Strategy
            </Typography>
          </Box>
          <Typography
            align='center'
            variant='subtitle1'
            className={classes.heading2}
          >
            This course offers a conceptual framework in setting the drug
            development strategy. The principal approach for the course will be
            case-study based, and team-based exercises. The focus and the
            case-studies can be tailored based on the organizational needs.
          </Typography>
          <Typography
            align='center'
            variant='subtitle1'
            className={classes.date}
          >
            Date: June 14 & 16, 2021
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Banner;
