import React from 'react';

const css = `
.email_a{fill:#959aa3;}.email_b{fill:#fff;}.email_c{clip-path:url(#email_a);}
`;

const Location = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      sxlink='http://www.w3.org/1999/xlink'
      width='32'
      height='32'
      viewBox='0 0 32 32'
    >
      <defs>
        <style>{css}</style>
        <clipPath id='email_a'>
          <path
            class='email_a'
            d='M24,21.334H2.666A2.669,2.669,0,0,1,0,18.667l.013-16A2.663,2.663,0,0,1,2.666,0H24a2.669,2.669,0,0,1,2.666,2.666v16A2.669,2.669,0,0,1,24,21.334ZM2.666,2.666V5.333L13.333,12,24,5.333V2.666L13.333,9.334Z'
            transform='translate(0 0)'
          />
        </clipPath>
      </defs>
      <g transform='translate(2.667 5.333)'>
        <path
          class='email_b'
          d='M24,21.334H2.666A2.669,2.669,0,0,1,0,18.667l.013-16A2.663,2.663,0,0,1,2.666,0H24a2.669,2.669,0,0,1,2.666,2.666v16A2.669,2.669,0,0,1,24,21.334ZM2.666,2.666V5.333L13.333,12,24,5.333V2.666L13.333,9.334Z'
          transform='translate(0 0)'
        />
      </g>
    </svg>
  );
};

export default Location;
