// @ts-ignore

declare module '@mui/material/styles' {
  interface TypographyVariants {
    reconh1: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    reconh1: React.CSSProperties;
  }
}

// @ts-ignore Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    reconh1: true;
  }
}
const typography: any = {
  fontFamily: ['Nunito Sans', 'Plus Jakarta Sans', 'sans-serif', 'Roboto'].join(','),
  h1: {
    fontWeight: 700,
    letterSpacing: '-0.05em',
    fontSize: 80,
    fontStyle: 'normal'
  },
  h2: { fontWeight: 500, fontSize: 48, letterSpacing: '-0.05em' },
  h3: { fontWeight: 500, fontSize: 36 },
  h4: {
    fontWeight: 400,
    fontSize: 25,
    fontStyle: 'normal'
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: 18,
    fontStyle: 'normal',
    // letterSpacing: '0.1em'
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: 16,
    fontStyle: 'normal'
  },
  body1: {
    fontWeight: 400,
    fontSize: 16
  },
  reconh1: {
    fontSize: 64
  }
};

export default typography;
