import { makeStyles } from '@material-ui/core';
import BannerBg from '../../../assets/pngs/Contacts/BannerBg.png';

export default makeStyles((theme) => ({
  root: {
    height: '100%',
    background: `url(${BannerBg}) no-repeat`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      background: theme.palette.primary.main,
    },
  },
  box: {
    height: '100vh',
    // height: '100%',
  },
  heading: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: theme.palette.white,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  heading1: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  heading3: {
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.white,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  highlighted: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  heading2: {
    width: '60%',
    marginTop: theme.spacing(2),
    opacity: .7,
    color: theme.palette.white,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  date: {
    width: '60%',
    marginTop: theme.spacing(2),
    opacity: .7,
    fontWeight:'bold',
    color: theme.palette.white,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formContainer: {
    marginTop: theme.spacing(10),
    // height: theme.spacing(100),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    background: theme.palette.white,
    boxShadow: '0px 20px 70px #33333314',
    borderRadius: theme.spacing(1.5),
  },
}));
