import axios from 'axios';

var Base = require('js-base64').Base64;
const tok = 'developers:>;JQwa[zL!EX+f{?3{fjnK';
const hash = Base.encode(tok);
const Basic = 'Basic ' + hash;

export default axios.create({
  // baseURL: 'http://localhost:8443/v2',

  baseURL: 'https://ria-api.vivpro.ai:8443/v2',

//   baseURL: 'https://ria-api-staging.vivpro.ai:8443/v2',

  headers: {
    'Content-type': 'application/json',
    Authorization: Basic,
  },
});