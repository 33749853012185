import React from 'react';

const css = `
    @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@800&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Raleway:ital@1&display=swap');
    .a{fill:#fff;font-size:52px;font-family:Raleway-ExtraBold, Raleway;font-weight:800;}
    .logo_b{font-family:Raleway-Italic, Raleway;font-weight:400;font-style:italic;}
    .c{fill:url(#a);}
    .d{fill:#4a2fd2;}
    .e{fill:#492cd0;}
`;

const VivproLogo = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlink='http://www.w3.org/1999/xlink'
      width='140.803'
      height='67.418'
      viewBox='0 0 237.803 67.418'
    >
      <defs>
        <style>{css}</style>
        <linearGradient
          id='a'
          y1='0.5'
          x2='1'
          y2='0.5'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#fff' />
          <stop offset='1' stopColor='#fff' />
        </linearGradient>
      </defs>
      <g transform='translate(-171.942 -21.119)'>
        <text className='a' transform='translate(260.746 70.119)'>
          <tspan x='0' y='0'>
            viv
          </tspan>
          <tspan className='logo_b' y='0'>
            pro
          </tspan>
        </text>
        <g transform='translate(171.942 23.033)'>
          <path
            className='c'
            d='M228.2,133.654a2.633,2.633,0,0,1-1.741-.971c-2.646-2.494-5.315-4.963-7.96-7.457a4.6,4.6,0,0,0-1.932-1.087c-5.2-1.544-10.892-3.43-14.35-7.851-1.213-1.717-1.063-2.774.443-4.283q5.091-5.093,10.182-10.187a5.1,5.1,0,0,0,1.589-3.272,4.75,4.75,0,0,1,4.322-3.918,5.116,5.116,0,0,1,4.552,4.147c.167,1.571-.795,4.254-3.336,4.73a8.159,8.159,0,0,0-4.591,2.584c-2.112,2.251-4.289,4.441-6.392,6.7-1.257,1.35-1.279,1.641.291,2.531,5.934,3.58,13.9,6.984,20.046,1.932a22.928,22.928,0,0,0,7.824-8.855c3.49-8.8-.884-19.563-11.573-18.993-2.1.185-3.329-3.12-1.565-4.085,1.679-.788,1-1.894.551-2.794a15.371,15.371,0,0,0-5.663-6.718c-5.89-3.51-14.173-2.811-18.844,2.345-2.606,2.609-5.113,7.128-2.8,10.684A4.46,4.46,0,0,1,194.4,95.41c-3.593.6-6.654-3.238-5.066-6.146.932-1.979,1.506-1.282,2.253-5.634.171-1-.357-1.684-1.316-1.564-14.529,1.7-18.385,21.621-5.056,28.04a2.288,2.288,0,0,0,2.671-.653c7.367-7.29,14.726-14.592,21.888-22.084a4.025,4.025,0,0,0,.939-2.336,4.489,4.489,0,0,1,4.3-3.991c1.833-.2,4.3,2.071,4.631,4.03.376,2.253-1.676,4.693-4.378,5.079a4.156,4.156,0,0,0-2.375,1.149c-7.8,7.347-15.259,15.034-22.717,22.721-2.626,2.708-2.848,2.558-6.181,1.072a19.463,19.463,0,0,1-11.763-15.338c-2.162-11.637,8.1-23.087,19.8-22.632a4.49,4.49,0,0,0,3.749-1.55A22.091,22.091,0,0,1,205.909,69.2a14.716,14.716,0,0,1,8.6-.682c7.737,1.192,13.556,7.165,15.713,14.479a2.662,2.662,0,0,0,1.875,2.046c7.487,2.256,11.63,9.7,11.259,17.152.652,10.117-6.355,17.539-14.266,21.193-1.185.471-1.988.89-1.337,1.653,1.465,1.715,3,3.375,4.566,4.993l1.264,1.3a1.365,1.365,0,0,1-.98,2.316Z'
            transform='translate(-171.942 -68.151)'
          />
          <path
            className='d'
            d='M217.928,88.167a1.7,1.7,0,1,1,.141-3.4,1.638,1.638,0,0,1,1.618,1.717A1.613,1.613,0,0,1,217.928,88.167Z'
            transform='translate(-174.626 -69.156)'
          />
          <path
            className='d'
            d='M194.858,90.817a1.694,1.694,0,0,1,1.5,1.739,1.548,1.548,0,0,1-1.762,1.644,1.522,1.522,0,0,1-1.6-1.681A1.655,1.655,0,0,1,194.858,90.817Z'
            transform='translate(-173.217 -69.523)'
          />
          <path
            className='e'
            d='M221.957,99.28a1.647,1.647,0,0,1,1.712,1.706,1.515,1.515,0,0,1-1.617,1.641,1.47,1.47,0,0,1-1.679-1.549A1.555,1.555,0,0,1,221.957,99.28Z'
            transform='translate(-174.874 -70.035)'
          />
        </g>
      </g>
    </svg>
  );
};

export default VivproLogo;
