import { React, useState } from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Form from './Form';

// style
import Styles from '../styles/Overview.styles';

const Banner = () => {
  const classes = Styles();
  const [insightsExpanded, setInsightsExpanded] = useState(false);

  return (
    <Box className={classes.root}>
      <Container>
        <Grid container spacing={5}>
          <Grid item xs={12} lg={12}>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
              justifyContent='space-evenly'
              className={classes.formContainerTop}
            >
              <Typography align='left' variant='h2' className={classes.heading}>
                COURSE OVERVIEW
              </Typography>
              <Grid display='flex' className={classes.overviewHeading}>
                <Grid item xs={4} lg={4}>
                  Topic
                </Grid>
                <Grid item xs={4} lg={4}>
                  Description
                </Grid>
                <Grid item xs={4} lg={4}>
                  Comments
                </Grid>
              </Grid>
              <Grid className={classes.overviewDay}> Day 1</Grid>
              <Grid className={classes.overviewCase}>
                <Grid item xs={4} lg={4}>
                  Case Study 1
                </Grid>
                <Grid item xs={4} lg={4}>
                  Develop a registration pathway for a specific product
                </Grid>
                <Grid item xs={4} lg={4}>
                  Hands-on team-based exercise
                </Grid>
              </Grid>

              <Grid className={classes.overview}>
                <Grid item xs={4} lg={4}>
                  Evidence of Effectiveness and Confirmation
                </Grid>
                <Grid item xs={4} lg={4}>
                  <div>-Brief history</div>
                  <div>-Statuary Standar</div>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <div>-Slides</div>
                  <div>-Reading Materia</div>
                </Grid>
              </Grid>

              <Grid className={classes.overview}>
                <Grid item xs={4} lg={4}>
                  Precedent Setting Regulatory Action #1
                </Grid>
                <Grid item xs={4} lg={4}>
                  <div>-Background</div>
                  <div>-Registration pathway options</div>
                  <div>-Group Discussion</div>
                  <div>-Dissecting the winning strategy</div>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <div>-Slides</div>
                  <div>-Reading Material</div>
                </Grid>
              </Grid>
              <Grid className={classes.overview}>
                <Grid item xs={4} lg={4}>
                  Decision-Information-Analysis (DIA) Paradigm
                </Grid>
                <Grid item xs={4} lg={4}>
                  -Disciplined way to developing a winning strategy
                </Grid>
                <Grid item xs={4} lg={4}>
                  <div>-Slides</div>
                  <div>-Reading Material</div>
                </Grid>
              </Grid>

              <Grid className={classes.overviewCase}>
                <Grid item xs={4} lg={4}>
                  Case Study 2
                </Grid>
                <Grid item xs={4} lg={4}>
                  Develop a DIA worksheet
                </Grid>
                <Grid item xs={4} lg={4}>
                  Hands-on team-based exercise
                </Grid>
              </Grid>

              <Grid className={classes.overview}>
                <Grid item xs={4} lg={4}>
                  Precedent Seting Regulatory Action #2
                </Grid>
                <Grid item xs={4} lg={4}>
                  <div>-Background</div>
                  <div>-Registration pathway options</div>
                  <div>-Group Discussion</div>
                  <div>-Dissecting the winning strategy</div>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <div>-Slides</div>
                  <div>-Reading Material</div>
                </Grid>
              </Grid>
              <Grid className={classes.overviewDay}>Day 2</Grid>

              <Grid className={classes.overview}>
                <Grid item xs={4} lg={4}>
                  Evidence of Effectiveness and Confirmation
                </Grid>
                <Grid item xs={4} lg={4}>
                  -New indications, new populations, new dosage forms,new dosing
                  regimen
                </Grid>
                <Grid item xs={4} lg={4}>
                  <div>-Slides</div>
                  <div>-Reading Material</div>
                </Grid>
              </Grid>

              <Grid className={classes.overview}>
                <Grid item xs={4} lg={4}>
                  Evidence of Effectiveness and Confirmation
                </Grid>
                <Grid item xs={4} lg={4}>
                  -New indications, new populations, new dosage forms,new dosing
                  regimen
                </Grid>
                <Grid item xs={4} lg={4}>
                  <div>-Slides</div>
                  <div>-Reading Material</div>
                </Grid>
              </Grid>

              <Grid className={classes.overview}>
                <Grid item xs={4} lg={4}>
                  Precedent Seting Regulatory Action #1
                </Grid>
                <Grid item xs={4} lg={4}>
                  <div>-Background</div>
                  <div>-Registration pathway options</div>
                  <div>-Group Discussion</div>
                  <div>-Dissecting the winning strategy</div>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <div>-Slides</div>
                  <div>-Reading Material</div>
                </Grid>
              </Grid>

              <Grid className={classes.overviewCase}>
                <Grid item xs={4} lg={4}>
                  Case Study 3
                </Grid>
                <Grid item xs={4} lg={4}>
                  Specific Product Registration Pathway
                </Grid>
                <Grid item xs={4} lg={4}>
                  Hands-onteam-based exercise
                </Grid>
              </Grid>

              <Grid className={classes.overview}>
                <Grid item xs={4} lg={4}>
                  Precedent Seting Regulatory Action #2
                </Grid>
                <Grid item xs={4} lg={4}>
                  <div>-Background</div>
                  <div>-Registration pathway options</div>
                  <div>-Group Discussion</div>
                  <div>-Dissecting the winning strategy</div>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <div>-Slides</div>
                  <div>-Reading Material</div>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Box
              p={2}
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
              justifyContent='space-evenly'
              className={classes.formContainer}
            >
              <Button
                variant='contained'
                fullWidth
                className={classes.btn}
                // href='#checkout'
                onClick={() => {
                  setInsightsExpanded(true);
                }}
              >
                Register
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {insightsExpanded ? (
        <Box className={classes.checkoutRoot}>
          <Form />
        </Box>
      ) : null}
    </Box>
  );
};

export default Banner;
