import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// material ui imports
import ThemeProvider from '@material-ui/styles/ThemeProvider';
// base components
import { StateProvider } from './store';
import theme from './theme';

// general components
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Resources from './pages/Resources';
import Product from './pages/Product';
import Contacts from './pages/Contacts';
import Company from './pages/Company';
import CaseStudies from './pages/CaseStudies';
import Education from './pages/Education';
import Success from './components/Success';
import Cancel from './components/Cancel';
import Newsroom from './pages/Newsroom';
import Raps from './pages/Raps';
import Webinar from './pages/Webinar';
import { Close } from '@material-ui/icons';
import Consultancy from './pages/Consultancy';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { Box, Button, IconButton, Snackbar, Stack, Typography } from '@mui/material';

const App = () => {

  const [alertMessage, setAlertMessage] = useState(false);

  const handleClose = () => {
    localStorage.setItem('vivproRecon2054CookieMessage', 'false');
    setAlertMessage(false);
  };

  useEffect(() => {
    const localAlertMessage = localStorage.getItem('vivproRecon2025CookieMessage');
    // delete vivproRecon2024CookieMessage
    localStorage.removeItem('vivproRecon2024CookieMessage');
    if (localAlertMessage === 'false') {
      setAlertMessage(false);
    } else {
      setAlertMessage(true);
      localStorage.setItem('vivproRecon2025CookieMessage', 'true');
    }
    const firebaseConfig = {
      apiKey: 'AIzaSyAphgFKlW79TAVuw2SHdswRABf-f0ggpzk',
      authDomain: 'vivpro-web.firebaseapp.com',
      projectId: 'vivpro-web',
      storageBucket: 'vivpro-web.appspot.com',
      messagingSenderId: '49101756362',
      appId: '1:49101756362:web:9ae880e12bd794e8b46133',
      measurementId: 'G-BNWNBBCREF'
    };
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);

  }, []);
  return (<ThemeProvider theme={theme}>
    <StateProvider>
      <Router>
        <Navbar />
        <Box>
          <Switch>
            {/* add routes here */}
            <Route key={1} path="/" exact={true} component={Home}></Route>
            <Route
              key={2}
              path="/services"
              exact={true}
              component={Consultancy}
            ></Route>
            <Route
              key={3}
              path="/resources"
              exact={true}
              component={Resources}
            ></Route>
            <Route
              key={4}
              path="/products"
              exact={true}
              component={Product}
            ></Route>
            <Route
              key={4}
              path="/contact"
              exact={true}
              component={Contacts}
            ></Route>
            <Route
              key={5}
              path="/company"
              exact={true}
              component={Company}
            ></Route>
            <Route
              key={6}
              path="/resources/casestudies/:id"
              exact={true}
              component={CaseStudies}
            ></Route>
            <Route
              key={7}
              path="/resources/newsroom/:id"
              exact={true}
              component={Newsroom}
            ></Route>
            <Route
              key={8}
              path="/education"
              exact={true}
              component={Education}
            ></Route>
            <Route
              key={9}
              path="/success/:sessionId"
              exact={true}
              component={Success}
            ></Route>
            <Route
              key={10}
              path="/cancel"
              exact={true}
              component={Cancel}
            ></Route>
            <Route
              key={11}
              path="/raps2021"
              exact={true}
              component={Raps}
            ></Route>
            <Route
              key={7}
              path="/resources/webinar/:id"
              exact={true}
              component={Webinar}
            ></Route>
          </Switch>
        </Box>
        <Footer />
        <Snackbar
          open={alertMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClose={handleClose}
          sx={{
            left: 'auto', width: '665px', maxWidth: '665px',  '& > .MuiSnackbarContent-root': {
              backgroundColor: 'white !important',
              borderRadius: '12px',
              p:0
            }, '& .MuiSnackbarContent-action': {
              position: 'absolute', top: 5, right: 15, p: 0
            },
            '& .MuiSnackbarContent-message': {
              p:0
            }
          }}
          message={<Stack direction="row" justifyContent="center" alignItems="center" padding="20px" gap="16px">
            <Stack
              width={'180px'}
              height={'180px'}
              borderRadius={'16px'}
              sx={{
                backgroundSize: 'cover',
                backgroundImage: 'url("https://d1fziiyze1kdfo.cloudfront.net/Recon/2025/reconAlert.jpeg"), linear-gradient(lightgray, lightgray)', // Use linear gradient as fallback
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#FFF',
                backgroundPosition:'right',
              }}
            >
            </Stack>
            <Stack width={'70%'} flex={1}>
              <Typography sx={{
                background: 'linear-gradient(180deg, #FF7300 0%, #DB5800 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '24px',
                fontStyle: 'normal',
                lineHeight: 'normal',
                letterSpacing: '0.72px',
                fontWeight: '600'
              }}>
                RECON 2025
              </Typography>
              <Typography gutterBottom sx={{
                background: 'linear-gradient(180deg, #FF7300 0%, #DB5800 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                letterSpacing: '0.72px'
              }}>
                Setting R&D Strategy
              </Typography>
              <Typography gutterBottom sx={{
                color: '#000', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: '150%', mb: 2
              }}>
                A Conference uniting professionals from pharmaceuticals, academia, and regulatory bodies to strategize
                on R&D
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  height: '32px',
                  minWidth: '100px',
                  width: 'max-content',
                  padding: '8px 16px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '14px',
                  textDecoration: 'none',
                  borderRadius: '24px',
                  border: '1px solid #DB5800',
                  color: '#DB5800',
                  fontFamily: 'Plus Jakarta Sans',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '140%',
                  letterSpacing: '0.2px',
                  '&:hover': {
                    backgroundColor: '#DB5800',
                    color: '#FFF',
                    textDecoration: 'none'
                  }
                }}
                onClick={() => {
                  handleClose();
                  window.open('https://varsity.vivpro.ai/recon', '_blank');
                }}
              >
                Learn More
              </Button>
            </Stack>
          </Stack>}
          action={<IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            sx={{
              color: '#000 !important'
            }}
          >
            <Close fontSize="small" />
          </IconButton>}
        />
      </Router>
    </StateProvider>
  </ThemeProvider>);
};

export default App;
