import React from 'react';

const css = `
.laptop_a{fill:url(#laptop_a);}.laptop_b{fill:#ededf7;}.laptop_c,.laptop_g{fill:none;}.laptop_c{stroke:#707070;stroke-miterlimit:10;}.laptop_d,.laptop_h{fill:#fff;}.laptop_e{fill:url(#laptop_b);}.laptop_f{fill:url(#laptop_c);}.laptop_g{stroke:#bdbdc6;stroke-linecap:round;stroke-linejoin:round;stroke-width:0.5px;}.laptop_h{opacity:0.27;}.laptop_i{mix-blend-mode:screen;isolation:isolate;fill:url(#laptop_d);}.laptop_j{fill:url(#laptop_e);}.laptop_k{opacity:0.15;}.laptop_l{fill:#9bdaad;}
`;

const Laptop = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlink='http://www.w3.org/1999/xlink'
      width='100%'
      height='100%'
      viewBox='0 0 964.705 551.98'
    >
      <defs>
        <style>{css}</style>
        <linearGradient
          id='laptop_a'
          x1='0.58'
          y1='1.436'
          x2='0.52'
          y2='0.738'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#414042' />
          <stop offset='1' stopColor='#231f20' />
        </linearGradient>
        <linearGradient
          id='laptop_b'
          y1='0.5'
          x2='1'
          y2='0.5'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0.048' stop-color='#a7a9ac' />
          <stop offset='0.218' stop-color='#bdbfc1' />
          <stop offset='0.398' stop-color='#d1d3d4' />
          <stop offset='0.535' stop-color='#caccce' />
          <stop offset='0.721' stop-color='#b9bbbd' />
          <stop offset='0.935' stop-color='#9d9fa1' />
          <stop offset='1' stop-color='#939598' />
        </linearGradient>
        <linearGradient
          id='laptop_c'
          y1='0.5'
          x2='1'
          y2='0.5'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stop-color='#d1d3d4' />
          <stop offset='1' stop-color='#d6d6d6' />
        </linearGradient>
        <linearGradient
          id='laptop_d'
          x1='1.194'
          y1='-0.255'
          x2='0.2'
          y2='0.827'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stop-color='#d1d3d4' stop-opacity='0' />
          <stop offset='0.373' stop-color='#d1d3d4' stop-opacity='0.008' />
          <stop offset='0.507' stop-color='#d2d4d5' stop-opacity='0.035' />
          <stop offset='0.603' stop-color='#d4d6d7' stop-opacity='0.078' />
          <stop offset='0.68' stop-color='#d7d9da' stop-opacity='0.145' />
          <stop offset='0.746' stop-color='#dbddde' stop-opacity='0.231' />
          <stop offset='0.805' stop-color='#e0e1e2' stop-opacity='0.341' />
          <stop offset='0.858' stop-color='#e6e7e8' stop-opacity='0.467' />
          <stop offset='0.907' stop-color='#edeeee' stop-opacity='0.616' />
          <stop offset='0.952' stop-color='#f5f5f5' stop-opacity='0.784' />
          <stop offset='0.993' stop-color='#fdfdfd' stop-opacity='0.965' />
          <stop offset='1' stop-color='#fff' />
        </linearGradient>
        <linearGradient
          id='laptop_e'
          y1='0.5'
          x2='1'
          y2='0.5'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stop-color='#29abe2' />
          <stop offset='1' stop-color='#39b54a' />
        </linearGradient>
      </defs>
      <g transform='translate(-479.441 -692.439)'>
        <path
          class='laptop_a'
          d='M6.381,0H792.106a6.381,6.381,0,0,1,6.381,6.381V535.589a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V6.381A6.381,6.381,0,0,1,6.381,0Z'
          transform='translate(562.55 692.939)'
        />
        <rect
          class='laptop_b'
          width='468.916'
          height='731.816'
          transform='translate(1327.702 726.275) rotate(90)'
        />
        <path
          class='laptop_c'
          d='M6.381,0H792.106a6.381,6.381,0,0,1,6.381,6.381V535.589a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V6.381A6.381,6.381,0,0,1,6.381,0Z'
          transform='translate(562.55 692.939)'
        />
        <path
          class='laptop_d'
          d='M.361,0H964.344a.361.361,0,0,1,.361.361v9.668a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V.361A.361.361,0,0,1,.361,0Z'
          transform='translate(479.441 1223.898)'
        />
        <path
          class='laptop_e'
          d='M0,0H111.566a0,0,0,0,1,0,0V8.073a2.418,2.418,0,0,1-2.418,2.418H2.418A2.418,2.418,0,0,1,0,8.073V0A0,0,0,0,1,0,0Z'
          transform='translate(906.011 1223.437)'
        />
        <path
          class='laptop_f'
          d='M1402.948,1244.419H520.639a83.356,83.356,0,0,1-34.242-7.358l-6.956-3.133h964.7l-6.956,3.133A83.355,83.355,0,0,1,1402.948,1244.419Z'
        />
        <rect
          class='laptop_g'
          width='468.916'
          height='731.816'
          transform='translate(1327.702 726.275) rotate(90)'
        />
        <circle
          class='laptop_h'
          cx='4.466'
          cy='4.466'
          r='4.466'
          transform='translate(957.328 706.216)'
        />
        <path
          class='laptop_i'
          d='M1361.037,715.229v508.208H1175.489c-152.414-153.65-393.776-396.94-526.28-530.5h689.508A22.3,22.3,0,0,1,1361.037,715.229Z'
        />
        <rect
          class='laptop_j'
          width='737'
          height='469'
          transform='translate(594 726)'
        />
        <g class='laptop_k'>
          <path
            class='laptop_d'
            d='M1260.145,872.383a4.827,4.827,0,0,1-3.192-1.781c-4.85-4.574-9.744-9.1-14.6-13.672a8.417,8.417,0,0,0-3.543-1.994c-9.525-2.831-19.971-6.289-26.313-14.4-2.223-3.148-1.95-5.087.815-7.853q9.333-9.339,18.668-18.678a9.355,9.355,0,0,0,2.913-6c.314-3.649,4.391-7.221,7.926-7.184,3.75.04,7.94,3.8,8.346,7.6.307,2.882-1.458,7.8-6.118,8.674a14.961,14.961,0,0,0-8.418,4.739c-3.872,4.127-7.863,8.142-11.72,12.283-2.3,2.476-2.345,3.01.534,4.642,10.88,6.564,25.48,12.806,36.757,3.541,3.887-2.777,10.449-7.85,14.346-16.236,6.4-16.141-1.62-35.873-21.22-34.827-3.847.34-6.1-5.721-2.871-7.49,3.078-1.445,1.827-3.473,1.009-5.124-2.438-4.921-5.569-9.441-10.383-12.317-10.8-6.436-25.987-5.154-34.552,4.3-4.779,4.783-9.375,13.07-5.135,19.589,2.8,4.6.02,11.074-5.229,12.056-6.587,1.1-12.2-5.939-9.287-11.27,1.708-3.629,2.76-2.351,4.129-10.33.314-1.828-.654-3.088-2.413-2.868-26.64,3.118-33.712,39.644-9.27,51.414a4.2,4.2,0,0,0,4.9-1.2c13.508-13.368,27-26.756,40.132-40.494a7.382,7.382,0,0,0,1.723-4.281c.914-5.134,4.281-6.916,7.881-7.319,3.36-.375,7.892,3.8,8.491,7.391.689,4.131-3.074,8.6-8.027,9.312a7.615,7.615,0,0,0-4.355,2.107c-14.3,13.471-27.98,27.566-41.655,41.661-4.814,4.966-5.223,4.69-11.333,1.965-12.126-5.408-19.325-14.958-21.568-28.123-3.964-21.338,14.847-42.332,36.312-41.5a8.233,8.233,0,0,0,6.875-2.842,40.509,40.509,0,0,1,18.562-11.682c5.355-1.773,10.029-2.617,15.765-1.252,14.187,2.187,24.856,13.139,28.811,26.55a4.886,4.886,0,0,0,3.439,3.752c13.728,4.136,21.323,17.778,20.644,31.449,1.2,18.551-11.653,32.16-26.158,38.861-2.172.862-3.646,1.632-2.452,3.03,2.686,3.144,5.492,6.187,8.372,9.155l2.317,2.388a2.5,2.5,0,0,1-1.8,4.246Z'
          />
          <path
            class='laptop_l'
            d='M1236.38,787.135a3.123,3.123,0,1,1,.259-6.24,3.006,3.006,0,0,1,2.967,3.15A2.958,2.958,0,0,1,1236.38,787.135Z'
          />
          <path
            class='laptop_l'
            d='M1196.665,791.321c1.718.186,2.585,1.459,2.744,3.188.168,1.819-1.286,3.071-3.23,3.015-2-.058-2.848-1.257-2.923-3.081C1193.179,792.6,1194.579,791.288,1196.665,791.321Z'
          />
          <path
            class='laptop_l'
            d='M1243.314,805.9a3.018,3.018,0,0,1,3.138,3.127,2.777,2.777,0,0,1-2.964,3.009,2.693,2.693,0,0,1-3.078-2.839A2.851,2.851,0,0,1,1243.314,805.9Z'
          />
        </g>
      </g>
    </svg>
  );
};

export default Laptop;
