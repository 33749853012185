import * as React from 'react';
import { useState } from 'react';
import { Stack, Typography, Container, Avatar, Grid } from '@mui/material';
import { testimonialPage } from '../constants';

const Testimonials = () => {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <Container>
      <Stack sx={{ height: { mobile: '100%', laptop: '100vh' }, m: 2 }}>
        <Typography
          sx={{
            color: 'black.main',
            mt: 6,
            fontSize: '3.4vh',
            fontWeight: 'bold',
          }}
        >
          Testimonials
        </Typography>
        <Grid mt={2} container spacing={2}>
          <Grid
            item
            mobile={12}
            laptop={6}
            sx={{
              maxHeight: '80vh',
              overflowY: 'auto',
              order: { mobile: 2, laptop: 1 },
            }}
          >
            <Stack spacing={2}>
              {testimonialPage?.map((item, index) => {
                return (
                  <Stack
                    direction='row'
                    spacing={2}
                    key={`${index}-${item.name}}`}
                    sx={{
                      borderRadius: '30px',
                      p: 2,
                      cursor: 'pointer',
                      boxShadow:
                        activeStep === index
                          ? 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                          : 'none',
                    }}
                    onClick={() => setActiveStep(index)}
                  >
                    <Avatar
                      alt={item.name}
                      variant='circular'
                      sx={{
                        height: { mobile: 40, laptop: 100 },
                        width: { mobile: 40, laptop: 100 },
                        border: '1px solid white',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      src={item.imgPath}
                    />
                    <Stack
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                    >
                      <Typography
                        variant='body1'
                        textAlign='left'
                        // mt={5}
                        color={'black.main'}
                      >
                        <b>
                          {item.name}, {item.qualification}
                        </b>{' '}
                        <br />
                        {item.designation} <br />
                        {item.company}
                      </Typography>
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
          <Grid
            item
            mobile={12}
            laptop={6}
            sx={{ order: { mobile: 1, laptop: 2 } }}
          >
            <Stack
              justifyContent='flex-start'
              alignItems={'flex-end'}
              // sx={{ height: '100%' }}
            >
              <Typography
                variant='subtitle1'
                sx={{
                  p: 2,
                  fontWeight: 500,
                  boxShadow: '#2ecc71 0px 3px 8px',
                  maxHeight: '70vh',
                  overflowY: 'auto',
                  borderRadius: '30px',
                }}
                color={'gray.darkText'}
              >
                {testimonialPage[activeStep].title}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};
export default Testimonials;
