import { makeStyles } from '@material-ui/core';
import StepsBg from '../../../assets/pngs/Company/StepsBg.png';

export default makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(15),
    height: '200vh',
    background: `url(${StepsBg}) no-repeat`,
    [theme.breakpoints.down('md')]: {
      height: '100%',
      background: theme.palette.primary.main,
    },
  },
  header: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
  },
  heading: {
    width: '30%',
    marginTop: theme.spacing(30),
    // fontSize: theme.spacing(5),
    fontWeight: 'bold',
    color: theme.palette.white,
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(10),
      width: '100%',
    },
  },
  grid: {
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(0),
    },
  },
  second: {
    marginTop: theme.spacing(15),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(0),
    },
  },
  third: {
    marginTop: theme.spacing(30),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(0),
    },
  },
  box: {
    padding: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      alignItems: 'center'
    },
  },
  subheading: {
    marginTop: theme.spacing(2),
    // fontSize: theme.spacing(3),
    fontWeight: 'bold',
    color: theme.palette.white
  },
  description: {
    marginTop: theme.spacing(2),
    // fontSize: theme.spacing(2),
    opacity: .8,
    color: theme.palette.white,
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center'
    },
  }
}))