import { makeStyles } from '@material-ui/core';
import BannerBg from '../../../assets/pngs/Company/BannerBg.png';

export default makeStyles((theme) => ({
  root: {
    height: '100vh',
    background: `url(${BannerBg}) no-repeat`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      background: theme.palette.secondary.main,
      paddingTop: theme.spacing(5),

    },
  },
  heading: {
    marginTop: theme.spacing(2),
    width: '60%',
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formContainerTop: {
    marginTop: theme.spacing(5),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    background: theme.palette.white,
    boxShadow: '0px 20px 70px #33333314',
    borderRadius: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(0),
    },
    paddingBottom: theme.spacing(2),
  },
  description: {
    marginTop: theme.spacing(2),
    color: theme.palette.home.textGrayDark,
  },
  descriptionBold: {
    marginTop: theme.spacing(2),
    color: theme.palette.black,
    fontWeight: 'bold',
  },
}));
