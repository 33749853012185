import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(5),
  },
  intro: {
    marginTop: theme.spacing(5),
    color: theme.palette.product.textBlackLight,
    letterSpacing: theme.spacing(0.2),
  },
  heading: {
    marginTop: theme.spacing(5),
    color: theme.palette.home.textBlackDark,
    letterSpacing: theme.spacing(0.2),
    fontWeight: '600',
  },
  linkTag: {
    color: theme.palette.home.textBlackDark,
    letterSpacing: theme.spacing(0.2),
    fontWeight: '600',
  },
  btn: {
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.white,
    fontWeight: 'bold',
  },
}));
