import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    height: '100vh',
    background: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(5),
      background: theme.palette.primary.main,
    },
  },
  heading: {
    marginTop: theme.spacing(25),
    lineHeight: theme.spacing(.15),
    fontWeight: 'bold',
    color: theme.palette.white,
  },
  btn: {
    marginTop: theme.spacing(4),
    color: theme.palette.white,
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
    textTransform: 'capitalize'
  }
}))