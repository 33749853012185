import React from 'react';

const css = `
@import url('https://fonts.googleapis.com/css2?family=Nunito Sans:wght@800&display=swap');
.ria_a{fill:#fff;}.ria_b{clip-path:url(#ria_e);}.ria_c{fill:none;}.ria_d,.ria_e{fill:#06c66c;}.ria_e{font-size:16px;font-family:NunitoSans-SemiBold, Nunito Sans;font-weight:600;}.ria_f{fill:#333;font-size:24px;}.ria_f,.ria_i{font-family:Nunito Sans;font-weight:700;}.ria_g{font-size:20px;font-family:Nunito Sans;font-weight:400;}.ria_h{fill:#264eee;}.ria_i{fill:#f5f9ff;font-size:44px;}.ria_j{filter:url(#ria_f);}.ria_k{filter:url(#ria_c);}.ria_l{filter:url(#ria_a)}
`;

const Ria = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlink='http://www.w3.org/1999/xlink'
      width='100%'
      height='100%'
      viewBox='0 0 632 544.5'
    >
      <defs>
        <style>{css}</style>
        <filter
          id='ria_a'
          x='0'
          y='-30'
          width='632'
          height='574.5'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dy='30' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='20' result='ria_b' />
          <feFlood flood-color='#333' flood-opacity='0.059' />
          <feComposite operator='in' in2='ria_b' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter
          id='ria_c'
          x='0'
          y='52'
          width='632'
          height='492.5'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dy='30' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='20' result='ria_d' />
          <feFlood flood-color='#333' flood-opacity='0.059' />
          <feComposite operator='in' in2='ria_d' />
          <feComposite in='SourceGraphic' />
        </filter>
        <clipPath id='ria_e'>
          <g class='ria_l' transform='matrix(1, 0, 0, 1, 0, 0)'>
            <rect class='ria_a' width='512' height='454.5' rx='4' />
          </g>
        </clipPath>
        <filter
          id='ria_f'
          x='0'
          y='44'
          width='632'
          height='217'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dy='30' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='20' result='ria_g' />
          <feFlood flood-color='#333' flood-opacity='0.059' />
          <feComposite operator='in' in2='ria_g' />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>
      <g transform='translate(-9901 -1008.5)'>
        <g transform='translate(9961 1008.5)'>
          <g class='ria_k' transform='matrix(1, 0, 0, 1, -60, 0)'>
            <rect
              class='ria_a'
              width='512'
              height='372.5'
              rx='10'
              transform='translate(60 82)'
            />
          </g>
          <g class='ria_b'>
            <g transform='translate(-504 770.863)'>
              <g transform='translate(92)'>
                <rect class='ria_c' width='24' />
                <path
                  class='ria_d'
                  d='M8.293,0h0Z'
                  transform='translate(4 -6.5)'
                />
              </g>
              <text class='ria_e' transform='translate(0 5)'>
                <tspan x='0' y='0'>
                  Learn More
                </tspan>
              </text>
            </g>
          </g>
          <g class='ria_j' transform='matrix(1, 0, 0, 1, -60, 0)'>
            <path
              class='ria_d'
              d='M10,0H502a10,10,0,0,1,10,10V97a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z'
              transform='translate(60 74)'
            />
          </g>
        </g>
        <g transform='translate(22.8)'>
          <text class='ria_f' transform='translate(10110.939 1226.349)'>
            <tspan x='0' y='23'>
              Time Efficient
            </tspan>
            <tspan class='ria_g'>
              <tspan x='0' y='55'>
                Result in 15 mins
              </tspan>
            </tspan>
          </text>
          <text class='ria_f' transform='translate(10110.939 1340.302)'>
            <tspan x='0' y='23'>
              Top Quality Result
            </tspan>
            <tspan class='ria_g'>
              <tspan x='0' y='55'>
                Human &amp; Machine Assisted
              </tspan>
            </tspan>
          </text>
          <g transform='translate(9994.739 1201.295)'>
            <path
              class='ria_a'
              d='M104.162,134.732a34.213,34.213,0,1,1,24.154-10.021,34.069,34.069,0,0,1-24.154,10.021Z'
              transform='translate(-48.526 -46.027)'
            />
            <path
              class='ria_h'
              d='M158.055,126.2a28.25,28.25,0,1,1-8.274-19.976A28.162,28.162,0,0,1,158.055,126.2Z'
              transform='translate(-74.169 -71.671)'
            />
            <g transform='translate(52.086 38.725)'>
              <path
                class='ria_d'
                d='M239.351,234.389a3.087,3.087,0,1,1-.9-2.183A3.077,3.077,0,0,1,239.351,234.389Z'
                transform='translate(-233.177 -218.721)'
              />
              <path
                class='ria_d'
                d='M245.453,172.369a39.514,39.514,0,0,1-.367,5.73c-.227,1.466-.54,2.373-.887,2.373s-.66-.907-.887-2.373a39.5,39.5,0,0,1-.367-5.73,27.82,27.82,0,0,1,.627-5.73,22.282,22.282,0,0,1,.627-2.373,22.219,22.219,0,0,1,.627,2.373A27.824,27.824,0,0,1,245.453,172.369Z'
                transform='translate(-241.112 -164.266)'
              />
              <path
                class='ria_d'
                d='M255.72,203.665a25.465,25.465,0,0,1-3.406,3.1c-.969.7-1.694,1.018-1.947.782s.015-.981.653-1.994a25.455,25.455,0,0,1,2.87-3.6,18.072,18.072,0,0,1,3.6-2.922,15.131,15.131,0,0,1,1.757-.959,15.1,15.1,0,0,1-.842,1.816A18.077,18.077,0,0,1,255.72,203.665Z'
                transform='translate(-247.065 -191.726)'
              />
            </g>
            <g transform='translate(33.567 32.632)'>
              <path
                class='ria_a'
                d='M245.939,133.1a1.3,1.3,0,1,1-.381-.921A1.3,1.3,0,0,1,245.939,133.1Z'
                transform='translate(-222.909 -131.798)'
              />
              <path
                class='ria_a'
                d='M245.939,350.78a1.3,1.3,0,1,1-.381-.921A1.3,1.3,0,0,1,245.939,350.78Z'
                transform='translate(-222.909 -308.626)'
              />
              <path
                class='ria_a'
                d='M135.8,240.638a1.3,1.3,0,1,1-.921.381A1.3,1.3,0,0,1,135.8,240.638Z'
                transform='translate(-134.495 -220.212)'
              />
              <path
                class='ria_a'
                d='M353.477,240.638a1.3,1.3,0,1,1-.921.381A1.3,1.3,0,0,1,353.477,240.638Z'
                transform='translate(-311.323 -220.212)'
              />
              <path
                class='ria_d'
                d='M339.856,308.192a11.808,11.808,0,1,1-3.458-8.349A11.77,11.77,0,0,1,339.856,308.192Z'
                transform='translate(-282.133 -265.497)'
              />
            </g>
            <path
              class='ria_a'
              d='M340.217,341.556l-3.608-3.608L333,334.339l1.5-1.495,1.5-1.495,2.113,2.113,2.113,2.113,3.559-3.559,3.559-3.559,1.5,1.495,1.5,1.495-5.054,5.055Z'
              transform='translate(-262.181 -258.918)'
            />
          </g>
          <path
            class='ria_d'
            d='M54.606,25.282a4,4,0,0,0-2.22-6.828L38.871,16.49a1.765,1.765,0,0,1-1.33-.967L31.5,3.278a4,4,0,0,0-7.179,0L18.277,15.524a1.767,1.767,0,0,1-1.331.967L3.43,18.455a4,4,0,0,0-2.22,6.828l9.779,9.532a1.77,1.77,0,0,1,.51,1.565L9.192,49.839a3.919,3.919,0,0,0,.873,3.246A4.045,4.045,0,0,0,15,54.058L27.086,47.7a1.809,1.809,0,0,1,1.645,0l12.088,6.355a3.961,3.961,0,0,0,1.862.466,4.014,4.014,0,0,0,3.071-1.44,3.917,3.917,0,0,0,.873-3.246L44.318,36.38a1.769,1.769,0,0,1,.51-1.565Z'
            transform='translate(10025.201 1343.255)'
          />
        </g>
        <text class='ria_i' transform='translate(10217 1147)'>
          <tspan x='-35.138' y='0'>
            RIA
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default Ria;
