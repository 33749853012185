import React from 'react';

const css = `
@import url('https://fonts.googleapis.com/css2?family=Nunito Sans:wght@800&display=swap');
.manual_a{fill:#fff;}.manual_b{clip-path:url(#manual_e);}.manual_c{fill:none;}.manual_d,.manual_e{fill:#06c66c;}.manual_e{font-size:14px;font-family:NunitoSans-SemiBold, Nunito Sans;font-weight:600;}.manual_f{fill:#c9d2f6;}.manual_g{fill:#333;font-size:20px;}.manual_g,.manual_j{font-family:Nunito Sans;font-weight:700;}.manual_h{font-size:16px;font-family:Nunito Sans;font-weight:400;}.manual_i{fill:#264eee;}.manual_j{fill:#f5f9ff;font-size:32px;}.manual_k{filter:url(#manual_f);}.manual_l{filter:url(#manual_c);}.manual_m{filter:url(#manual_a);}`;

const Manual = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlink='http://www.w3.org/1999/xlink'
      width='100%'
      height='100%'
      viewBox='0 0 570.799 473.8'
    >
      <defs>
        <style>{css}</style>
        <filter
          id='manual_a'
          x='0'
          y='-30'
          width='570.799'
          height='503.8'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dy='30' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='20' result='manual_b' />
          <feFlood flood-color='#333' flood-opacity='0.059' />
          <feComposite operator='in' in2='manual_b' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter
          id='manual_c'
          x='0'
          y='42.198'
          width='570.799'
          height='431.602'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dy='30' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='20' result='manual_d' />
          <feFlood flood-color='#333' flood-opacity='0.059' />
          <feComposite operator='in' in2='manual_d' />
          <feComposite in='SourceGraphic' />
        </filter>
        <clipPath id='manual_e'>
          <g class='manual_m' transform='matrix(1, 0, 0, 1, 0, 0)'>
            <rect class='manual_a' width='450.799' height='383.8' rx='4' />
          </g>
        </clipPath>
        <filter
          id='manual_f'
          x='0'
          y='31.766'
          width='570.799'
          height='205.405'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dy='30' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='20' result='g' />
          <feFlood flood-color='#333' flood-opacity='0.059' />
          <feComposite operator='in' in2='g' />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>
      <g transform='translate(-9901 -1008.5)'>
        <g transform='translate(9961 1008.5)'>
          <g class='manual_l' transform='matrix(1, 0, 0, 1, -60, 0)'>
            <rect
              class='manual_a'
              width='450.799'
              height='311.602'
              rx='10'
              transform='translate(60 72.2)'
            />
          </g>
          <g class='manual_b'>
            <g transform='translate(-443.755 650.951)'>
              <g transform='translate(0 -11)'>
                <g transform='translate(81.003 4.5)'>
                  <rect
                    class='manual_c'
                    width='21.131'
                    transform='translate(0 6.5)'
                  />
                  <path
                    class='manual_d'
                    d='M7.3,0h0Z'
                    transform='translate(3.522 0)'
                  />
                </g>
                <text class='manual_e' transform='translate(0 14)'>
                  <tspan x='0' y='0'>
                    Learn More
                  </tspan>
                </text>
              </g>
            </g>
          </g>
          <g class='manual_k' transform='matrix(1, 0, 0, 1, -60, 0)'>
            <path
              class='manual_f'
              d='M10,0H440.8a10,10,0,0,1,10,10V85.405a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z'
              transform='translate(60 61.77)'
            />
          </g>
        </g>
        <g transform='translate(10044.677 1179.8)'>
          <text class='manual_g' transform='translate(68.415 23.136)'>
            <tspan x='0' y='19'>
              Time Inefficient
            </tspan>
            <tspan class='manual_h'>
              <tspan x='0' y='43'>
                Result in 3 days
              </tspan>
            </tspan>
          </text>
          <text class='manual_g' transform='translate(68.415 104.468)'>
            <tspan x='0' y='19'>
              Ok Quality Result
            </tspan>
            <tspan class='manual_h'>
              <tspan x='0' y='43'>
                Human Assisted
              </tspan>
            </tspan>
          </text>
          <path
            class='manual_f'
            d='M42.891,20.083a3.144,3.144,0,0,0-1.744-5.363L30.532,13.177a1.387,1.387,0,0,1-1.045-.759L24.741,2.8a3.144,3.144,0,0,0-5.639,0l-4.747,9.619a1.388,1.388,0,0,1-1.046.759L2.694,14.72A3.144,3.144,0,0,0,.951,20.083L8.631,27.57a1.39,1.39,0,0,1,.4,1.229L7.22,39.371a3.078,3.078,0,0,0,.686,2.549,3.177,3.177,0,0,0,3.876.765l9.494-4.992a1.421,1.421,0,0,1,1.292,0l9.495,4.992a3.111,3.111,0,0,0,1.463.366,3.153,3.153,0,0,0,2.412-1.131,3.077,3.077,0,0,0,.686-2.549L34.81,28.8a1.389,1.389,0,0,1,.4-1.229Z'
            transform='translate(7.753 106.943)'
          />
          <g transform='translate(0 19)'>
            <path
              class='manual_a'
              d='M95.784,117.977a25.826,25.826,0,1,1,18.233-7.564,25.717,25.717,0,0,1-18.233,7.564Z'
              transform='translate(-69.987 -66.383)'
            />
            <path
              class='manual_i'
              d='M144.2,119.276a21.325,21.325,0,1,1-6.246-15.079A21.258,21.258,0,0,1,144.2,119.276Z'
              transform='translate(-97.082 -93.479)'
            />
            <g transform='translate(23.118 13.867)'>
              <path
                class='manual_d'
                d='M237.837,233.632a2.33,2.33,0,1,1-.683-1.648A2.323,2.323,0,0,1,237.837,233.632Z'
                transform='translate(-233.177 -221.805)'
              />
              <path
                class='manual_d'
                d='M244.838,170.382a29.829,29.829,0,0,1-.277,4.325c-.171,1.107-.408,1.791-.669,1.791s-.5-.685-.669-1.791a29.815,29.815,0,0,1-.277-4.325,21,21,0,0,1,.473-4.325,16.819,16.819,0,0,1,.473-1.792,16.768,16.768,0,0,1,.473,1.792A21,21,0,0,1,244.838,170.382Z'
                transform='translate(-241.561 -164.266)'
              />
              <path
                class='manual_d'
                d='M254.385,202.294a19.223,19.223,0,0,1-2.571,2.34c-.731.53-1.279.769-1.47.59s.011-.741.493-1.505A19.215,19.215,0,0,1,253,201a13.641,13.641,0,0,1,2.714-2.206,11.422,11.422,0,0,1,1.327-.724,11.4,11.4,0,0,1-.636,1.371A13.646,13.646,0,0,1,254.385,202.294Z'
                transform='translate(-247.851 -193.281)'
              />
            </g>
            <g transform='translate(9.139 9.267)'>
              <path
                class='manual_a'
                d='M245.3,132.781a.983.983,0,1,1-.288-.7A.98.98,0,0,1,245.3,132.781Z'
                transform='translate(-227.916 -131.798)'
              />
              <path
                class='manual_a'
                d='M245.3,350.461a.983.983,0,1,1-.288-.7A.981.981,0,0,1,245.3,350.461Z'
                transform='translate(-227.916 -318.64)'
              />
              <path
                class='manual_a'
                d='M135.478,240.638a.983.983,0,1,1-.7.288A.981.981,0,0,1,135.478,240.638Z'
                transform='translate(-134.495 -225.219)'
              />
              <path
                class='manual_a'
                d='M353.158,240.638a.983.983,0,1,1-.7.288A.98.98,0,0,1,353.158,240.638Z'
                transform='translate(-321.337 -225.219)'
              />
            </g>
          </g>
          <g transform='translate(38.744 52.231)'>
            <ellipse
              class='manual_f'
              cx='9.103'
              cy='9.097'
              rx='9.103'
              ry='9.097'
              transform='translate(-7 0.168)'
            />
            <g transform='translate(9.286 9.265) rotate(135)'>
              <rect
                class='manual_a'
                width='1.991'
                height='10.158'
                transform='translate(4.083 0)'
              />
              <rect
                class='manual_a'
                width='10.158'
                height='1.991'
                transform='translate(0 4.082)'
              />
            </g>
          </g>
        </g>
        <text class='manual_j' transform='translate(10186.399 1126.662)'>
          <tspan x='-54.539' y='0'>
            Manual
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default Manual;
