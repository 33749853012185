import React, { useState } from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

// style
import Styles from '../styles/Challenge';

const Challenge = (props) => {
  const classes = Styles();
  const [select, setSelect] = useState('Challenge');

  return (
    <Box className={classes.root}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Box
              display='flex'
              flexDirection='column'
              className={classes.switch}
            >
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                onClick={() => setSelect('Challenge')}
                className={
                  select === 'Challenge' ? classes.selected : classes.unselected
                }
              >
                <Typography
                  variant='subtitle1'
                  className={
                    select === 'Challenge'
                      ? classes.selectedText
                      : classes.unselectedText
                  }
                >
                  Challenge
                </Typography>
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                onClick={() => setSelect('Outcome')}
                className={
                  select === 'Outcome' ? classes.selected : classes.unselected
                }
              >
                <Typography
                  variant='subtitle1'
                  className={
                    select === 'Outcome'
                      ? classes.selectedText
                      : classes.unselectedText
                  }
                >
                  Outcome
                </Typography>
              </Box>
            </Box>
          </Grid>
          {select === 'Challenge' ? (
            <Grid item xs={12} lg={6}>
              <Typography variant='h3' className={classes.heading}>
                Challenge
              </Typography>
              {props.caseStudy &&
                props.caseStudy.challenges.map((item, index) => {
                  return (
                    <Paper elevation={0} className={classes.descriptionBox}>
                      <Box
                        display='flex'
                        justifyContent='flex-start'
                        alignItems='center'
                      >
                        <Typography
                          variant='body1'
                          className={classes.description}
                        >
                          {index + 1}. {item.text}
                        </Typography>
                      </Box>
                    </Paper>
                  );
                })}
            </Grid>
          ) : null}

          {select === 'Outcome' ? (
            <Grid item xs={12} lg={6}>
              <Typography variant='h3' className={classes.heading}>
                Outcomes
              </Typography>
              {props.caseStudy &&
                props.caseStudy.outcomes.map((item, index) => {
                  return (
                    <Paper elevation={0} className={classes.descriptionBox}>
                      <Box
                        display='flex'
                        justifyContent='flex-start'
                        alignItems='center'
                      >
                        <Typography
                          variant='body1'
                          className={classes.description}
                        >
                          {index + 1}. {item.text}
                        </Typography>
                      </Box>
                    </Paper>
                  );
                })}
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </Box>
  );
};

export default Challenge;
