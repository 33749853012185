import actions from './actions';

const reducers = (state, action) => {
  switch (action.type) {
    case actions.SET_ACTIVE_PAGE: {
      return {
        ...state,
        activePage: action.value
      }
    }
    case actions.SET_RESOURCE_CATEGORY: {
      return {
        ...state,
        resourceCategory: action.value
      }
    }
    case actions.TOGGLE_CONTACT_US: {
      return {
        ...state,
        isContactUsOpen: action.value
      }
    }
    default: {
      return state;
    }
  }
}

export default reducers;