import React from "react";

// material ui
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

// style
import Styles from "../styles/Header.styles";

const Header = ({ heading, imgUrl, subtitle = "" }) => {
  const classes = Styles();
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const action = (
    <Box justifyContent="flex-end" alignItems="flex-end" p={1}>
      <Box justifyContent="flex-end" display="flex">
        <IconButton aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box mt={2}>
        <Button
          href="https://varsity.vivpro.ai/recon/register"
          target="_blank"
          variant="outlined"
          color="inherit"
          style={{ color: "white" }}
        >
          Register
        </Button>
      </Box>
    </Box>
  );
  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Typography
          align="center"
          variant="subtitle1"
          className={classes.heading}
        >
          NEWSROOM
        </Typography>

        <Typography variant="h1" className={classes.title}>
          {heading}
        </Typography>
        <Typography variant="subtitle2" className={classes.subtitle}>
          {subtitle}
        </Typography>
        {heading === "Introducing Vivpro Varsity" && (
          <Snackbar
            open={open}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            onClose={handleClose}
            message={
              <Box style={{ textAlign: "left" }} p={3}>
                <Typography variant="h5" sx={{ color: "gold.head" }}>
                  <b>Event on Jan 23-24, 2023 at Baltimore, MD</b>
                </Typography>
                <Typography variant={"body1"} mt={2}>
                  A conference and hands-on strategy workshop on <br />
                  <b>Setting R&D Strategy: Role of Intelligence</b>
                </Typography>
              </Box>
            }
            action={action}
            className={classes.snackbar}
          />
        )}
        <img src={imgUrl} alt="thumbnail" className={classes.img}></img>
      </Container>
    </Box>
  );
};

export default Header;
