import React, { Fragment } from 'react';

// child components
import Banner from './components/Banner';
import ContactForm from './components/ContactForm';
import Subscription from '../../components/Subscription';

const Contacts = () => {
  return (
    <Fragment>
      <Banner />
      <ContactForm />
      <Subscription />
    </Fragment>
  );
};

export default Contacts;
