import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

// assets
import Logo from '../../../assets/svgs/VivproLogoDark';

// style
import Styles from '../styles/JobDescription.styles';

const JobDescription = ({ jobDetails }) => {
  const classes = Styles();

  return (
    <Box className={classes.root}>
      <Container>
        <Box display='flex' justifyContent='space-between'>
          <Logo />
        </Box>
        <Typography variant='h3' className={classes.title}>
          {jobDetails && jobDetails.title}
        </Typography>
        <Typography variant='body1' className={classes.subtext}>
          Remote
        </Typography>
        <Typography variant='subtitle1' className={classes.subtitle}>
          Job Description
        </Typography>
        <Typography variant='body1' className={classes.description}>
          {jobDetails.description}
        </Typography>
        <Typography variant='subtitle1' className={classes.subtitle}>
          Our Culture
        </Typography>
        <Typography variant='body1' className={classes.description}>
          {jobDetails.culture}
        </Typography>
        <Typography variant='subtitle1' className={classes.subtitle}>
          Our Working style
        </Typography>
        <Typography variant='body1' className={classes.description}>
          {jobDetails.work_style}
        </Typography>
        {jobDetails.title === 'Chief of Staff' ||
        jobDetails.title === 'Customer success manager' ? (
          <>
            <Typography variant='subtitle1' className={classes.subtitle}>
              Scope
            </Typography>
            <ul>
              {jobDetails &&
                jobDetails.scope.map((item) => {
                  return (
                    <li>
                      <Typography
                        variant='body1'
                        className={classes.description}
                      >
                        {item.text}
                      </Typography>
                    </li>
                  );
                })}
            </ul>
          </>
        ) : null}
        <Typography variant='subtitle1' className={classes.subtitle}>
          Responsibilities
        </Typography>
        <Typography variant='body1' className={classes.description}>
          {jobDetails.title === 'Customer success manager'
            ? null
            : jobDetails.responsibilities_desc}
        </Typography>
        <ul>
          {jobDetails &&
            jobDetails.responsibilities_points.map((item) => {
              return (
                <li>
                  <Typography variant='body1' className={classes.description}>
                    {item.text}
                  </Typography>
                </li>
              );
            })}
        </ul>
        {jobDetails.title === 'Customer success manager' ? null : (
          <>
            <Typography variant='subtitle1' className={classes.subtitle}>
              Skills{' '}
              {jobDetails.title === 'Chief of Staff'
                ? '& Qualifications'
                : null}
            </Typography>

            <ul>
              {jobDetails &&
                jobDetails.skills.map((item) => {
                  return (
                    <li>
                      <Typography
                        variant='body1'
                        className={classes.description}
                      >
                        {item.text}
                      </Typography>
                    </li>
                  );
                })}
            </ul>
          </>
        )}

        <Typography variant='subtitle1' className={classes.subtitle}>
          {jobDetails.title === 'Chief of Staff'
            ? 'Preferred Qualifications'
            : 'Qualifications'}
        </Typography>
        <ul>
          {jobDetails &&
            jobDetails.qualifications.map((item) => {
              return (
                <li>
                  <Typography variant='body1' className={classes.description}>
                    {item.text}
                  </Typography>
                </li>
              );
            })}
        </ul>
        <Typography
          align='center'
          variant='subtitle2'
          className={classes.subtitle}
        >
          Apply by sending resume to admin@vivpro.ai
        </Typography>
      </Container>
    </Box>
  );
};

export default JobDescription;
