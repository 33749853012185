import React, { Fragment, useContext, useEffect } from 'react';

// child components
import Banner from './components/Banner';
import CardGrid from './components/CardGrid';
import Subscriptions from './components/Subscription';

// store
import Store from '../../store';
import Actions from '../../store/actions';

const Resources = () => {
  const { dispatch } = useContext(Store);

  useEffect(() => {
    async function getData() {
      try {
        await dispatch({ type: Actions.SET_ACTIVE_PAGE, value: 'Resources' });
      } catch (e) {
        // console.log(e);
      }
    }
    getData();
  }, [dispatch]);

  return (
    <Fragment>
      <Banner />
      <CardGrid />
      <Subscriptions />
    </Fragment>
  );
};

export default Resources;
