import React, { Fragment, useState, useEffect } from 'react';

// material ui
import Box from '@material-ui/core/Box';

// data
import CaseStudies from '../../data/CaseStudies.json';

// child components
import Banner from './components/Banner';
import WhyVivpro from './components/WhyVivpro';
import Challenge from './components/Challenge';
// import Solutions from './components/Solutions';

// styles
import Styles from './styles/Banner.styles';

const Services = (props) => {
  const [caseStudy, setCaseStudy] = useState();
  const classes = Styles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        CaseStudies.forEach((item) => {
          if (item.id === parseInt(props.match.params.id)) {
            setCaseStudy(item);
          }
        });
      } catch (e) {
        // console.log(e);
      }
    };
    fetchData();
  }, [props.match.params.id]);

  return (
    <Fragment>
      <Box className={classes.mainRoot}>
        <Banner caseStudy={caseStudy} />
        <WhyVivpro />
        <Challenge caseStudy={caseStudy} />
        {/* <Solutions caseStudy={caseStudy} /> */}
      </Box>
    </Fragment>
  );
};

export default Services;
