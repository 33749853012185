import React from 'react';

const css = `
.regulatory_a{fill:#289d78;}.regulatory_b{fill:#fff;}
`;

const Regulatory = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='54.976'
      height='54.976'
      viewBox='0 0 54.976 54.976'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <g transform='translate(0.256 1.77)'>
        <circle
          class='regulatory_a'
          cx='27.488'
          cy='27.488'
          r='27.488'
          transform='translate(-0.256 -1.77)'
        />
        <g transform='translate(13.744 12.23)'>
          <g transform='translate(0 18.208)'>
            <path
              class='regulatory_b'
              d='M760.411,2190.614h-3.031a3.957,3.957,0,0,0-7.595,0H735.374a1.138,1.138,0,0,0,0,2.276h14.411a3.957,3.957,0,0,0,7.595,0h3.03a1.138,1.138,0,1,0,0-2.276Zm-6.829,2.845a1.707,1.707,0,1,1,1.707-1.707A1.709,1.709,0,0,1,753.582,2193.459Z'
              transform='translate(-734.236 -2187.769)'
            />
          </g>
          <path
            class='regulatory_b'
            d='M760.411,2167.229h-3.031a3.957,3.957,0,0,0-7.595,0H735.374a1.138,1.138,0,0,0,0,2.276h14.411a3.957,3.957,0,0,0,7.595,0h3.031a1.138,1.138,0,0,0,0-2.276Zm-6.829,2.845a1.707,1.707,0,1,1,1.707-1.707A1.709,1.709,0,0,1,753.582,2170.074Z'
            transform='translate(-734.236 -2164.384)'
          />
          <g transform='translate(0 9.104)'>
            <path
              class='regulatory_b'
              d='M760.411,2178.922H746a3.957,3.957,0,0,0-7.6,0h-3.03a1.138,1.138,0,0,0,0,2.276h3.03a3.958,3.958,0,0,0,7.6,0h14.41a1.138,1.138,0,0,0,0-2.276Zm-18.209,2.844a1.707,1.707,0,1,1,1.708-1.707A1.709,1.709,0,0,1,742.2,2181.767Z'
              transform='translate(-734.236 -2176.077)'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Regulatory;
