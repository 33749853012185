import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
  },
  heading: {
    fontWeight: 'bold',
    color: theme.palette.home.textGrayDark
  },
  grid: {
    marginTop: theme.spacing(5),
  },
  card: {
    height: '40vh',
    width: '16vw',
    background: theme.palette.casestudy.background,
    borderRadius: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    "&:hover": {
      // opacity: .8,
      zoom: 1.1,
      // backgroundColor: theme.palette.secondary.main
      background: 'rgb(73,224,166,.8)'
    }
  },
  cardMedia: {
    // "&:hover": {
    //   opacity: .5,
    //   backgroundColor: 'rgb(125,60,152,.4)'
    // },
    height: '20vh',
    width: '16vw',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      // height: '100%',
    },
  },
  cardContent: {
    marginTop: theme.spacing(-5),
    minHeight: theme.spacing(30),
  },
  type: {
    fontSize: '1.5vh',
    marginTop: theme.spacing(1),
    color: theme.palette.home.textGray,
  },
  date: {
    fontSize: '1.5vh',
    marginTop: theme.spacing(2),
    color: theme.palette.home.textGray,
  },
  title: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontWeight: '600',
    color: theme.palette.home.textBlackDark,
    display: ' -webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  caseStudyAvatarPurple: {
    fontWeight: 'bold',
    background: theme.palette.primary.light

  },
  caseStudyAvatarRed: {
    fontWeight: 'bold',
    background: theme.palette.home.red
  },
  description: {
    color: theme.palette.home.textBlackDark
  }
}))