import React from 'react';

const css = `
.smarter_a{fill:#efefef;}.smarter_b{fill:#a4afc1;}.smarter_c{fill:#f3f3f1;}.smarter_d{fill:#2fdf84;}.smarter_e{fill:#00b871;}.smarter_f{fill:#d5dbe1;}
`;

const SmarterTechnology = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='8vh'
      height='8vh'
      viewBox='0 0 86.073 77.812'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <g transform='translate(0 0.171)'>
        <path
          class='smarter_a'
          d='M1352.252,1638.4c0,17.092-8.854,28.83-8.854,37.066s4.735,25.534,42.624,25.534,43.449-23.27,43.449-32.329c0-42.421-77.219-60.541-77.219-30.271Z'
          transform='translate(-1343.398 -1623.354)'
        />
      </g>
      <g transform='translate(76.831 0.437)'>
        <path
          class='smarter_b'
          d='M1425.165,1627.133l3.494-3.495,1.747,1.747-3.494,3.495Z'
          transform='translate(-1425.165 -1623.638)'
        />
      </g>
      <g transform='translate(77.703 10.046)'>
        <path
          class='smarter_b'
          d='M1426.093,1635.611l1.748-1.747,3.494,3.494-1.747,1.747Z'
          transform='translate(-1426.093 -1633.864)'
        />
      </g>
      <g transform='translate(67.656)'>
        <path
          class='smarter_b'
          d='M1415.4,1624.92l1.748-1.747,3.494,3.494-1.747,1.747Z'
          transform='translate(-1415.4 -1623.173)'
        />
      </g>
      <g transform='translate(15.237 11.191)'>
        <circle class='smarter_c' cx='27.8' cy='27.8' r='27.8' />
      </g>
      <g transform='translate(25.121 32.195)'>
        <circle class='smarter_d' cx='6.795' cy='6.795' r='6.795' />
      </g>
      <g transform='translate(47.361 32.195)'>
        <circle class='smarter_d' cx='6.795' cy='6.795' r='6.795' />
      </g>
      <g transform='translate(25.121 32.196)'>
        <path
          class='smarter_e'
          d='M1375.693,1664.233a6.784,6.784,0,0,1,4.015-6.188,6.8,6.8,0,1,0,0,12.375A6.786,6.786,0,0,1,1375.693,1664.233Z'
          transform='translate(-1370.133 -1657.437)'
        />
      </g>
      <g transform='translate(47.361 32.196)'>
        <path
          class='smarter_e'
          d='M1399.361,1664.233a6.785,6.785,0,0,1,4.016-6.188,6.8,6.8,0,1,0,0,12.375A6.787,6.787,0,0,1,1399.361,1664.233Z'
          transform='translate(-1393.801 -1657.437)'
        />
      </g>
      <g transform='translate(15.237 11.191)'>
        <path
          class='smarter_f'
          d='M1365.174,1662.883a27.8,27.8,0,0,1,25.02-27.659c-.914-.091-1.842-.141-2.78-.141a27.8,27.8,0,1,0,2.78,55.458A27.8,27.8,0,0,1,1365.174,1662.883Z'
          transform='translate(-1359.614 -1635.083)'
        />
      </g>
      <g transform='translate(23.267 30.342)'>
        <path
          d='M1376.81,1672.762a8.649,8.649,0,1,1,8.648-8.649A8.659,8.659,0,0,1,1376.81,1672.762Zm0-13.591a4.942,4.942,0,1,0,4.942,4.942A4.947,4.947,0,0,0,1376.81,1659.171Z'
          transform='translate(-1368.16 -1655.464)'
        />
      </g>
      <g transform='translate(45.508 30.342)'>
        <path
          d='M1400.478,1672.762a8.649,8.649,0,1,1,8.649-8.649A8.658,8.658,0,0,1,1400.478,1672.762Zm0-13.591a4.942,4.942,0,1,0,4.942,4.942A4.947,4.947,0,0,0,1400.478,1659.171Z'
          transform='translate(-1391.829 -1655.464)'
        />
      </g>
      <g transform='translate(37.936 34.141)'>
        <path
          d='M1392.074,1664.1a6.252,6.252,0,0,0-6.4,0l-1.9-3.186a9.955,9.955,0,0,1,10.2,0Z'
          transform='translate(-1383.771 -1659.508)'
        />
      </g>
      <g transform='translate(13.383 9.338)'>
        <path
          d='M1387.294,1692.416a29.653,29.653,0,1,1,29.653-29.652A29.686,29.686,0,0,1,1387.294,1692.416Zm0-55.6a25.946,25.946,0,1,0,25.946,25.946A25.974,25.974,0,0,0,1387.294,1636.818Z'
          transform='translate(-1357.641 -1633.111)'
        />
      </g>
    </svg>
  );
};

export default SmarterTechnology;
