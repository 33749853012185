import consultancyStyles from '../consultancy.styles';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import Store from '../../../store';
import Actions from '../../../store/actions';

const MainHeader = () => {
  const { dispatch }: any = useContext(Store);

  return (<Stack
    justifyContent={'center'}
    sx={consultancyStyles.container}>
    <Box>
      <Stack sx={consultancyStyles.bannerSection}>
        <Typography
          sx={{ ...consultancyStyles.title, ...consultancyStyles.blueTitle }}
        >
          Uncovering
        </Typography>
        <Typography
          sx={{ ...consultancyStyles.title, ...consultancyStyles.whiteTitle }}
        >
          Lean Development Path
        </Typography>
        <Typography sx={consultancyStyles.secondaryTitle}>
          In the fast-paced world of pharmaceuticals, finding the most efficient pathway to market is crucial. Our
          strategic consulting services are designed to streamline your drug development process, ensuring a lean,
          cost-effective journey from concept to market.
        </Typography>
        <Box mt="32px">
          <Button sx={consultancyStyles.buttonBanner} onClick={() => {
            dispatch({ type: Actions.TOGGLE_CONTACT_US, value: true });
          }}>
            Start a conversation
          </Button>
        </Box>
      </Stack>
    </Box>

  </Stack>);
};

export default MainHeader;