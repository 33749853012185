import { createTheme, responsiveFontSizes } from '@mui/material/styles';
// color palette
import palette from './palette';
import typography from './typography';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}
let theme = createTheme({
  spacing: 8,
  palette,
  typography,
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 768,
      laptop: 1024,
      desktop: 1200
    }
  }
});

theme = responsiveFontSizes(theme);

export default theme;
